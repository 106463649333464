import Vue from 'vue'
import store from '@/store'
import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	mixins: [itemModel],
	data() {
		return {
			id: null,
			title: '',
			url: '',
			iconId: null,
			date: null,
			categoryId: null,
			order: null,
			dragging: false,
			props: ['title', 'url', 'categoryId', 'order', 'id', 'iconId'],
		}
	},
	computed: {
		iconUrl() {
			const icon = store.teamInfo.activeTeam.teamIcons.items[this.iconId]
			return icon && icon.url
		},
	},
	methods: {
		updateData(app) {
			this.title = app.title
			this.url = app.url
			this.id = app.id
			this.iconId = app.iconId
			this.order = app.order
			this.categoryId = app.categoryId || 'uncategorized'
			this.revertChanges()
		},
		commitChanges() {
			this.props.forEach(prop => (this[prop] = this.ep[prop]))
			this.revertChanges()
		},
	},
})
