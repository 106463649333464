<template>
	<div>
		<tooltip v-if="(prop.type === 'input' || prop.type === 'select') && prop.label && callOrUse('tooltip', prop, selectedItem)" :beside-form-label="true">
			<label
				slot="input"
				:for="prop.name"
			>{{ prop.label }}</label>
			<span slot="tip-text">{{ callOrUse('tooltip', prop, selectedItem) }}</span>
		</tooltip>
		<label
			v-else-if="(prop.type === 'input' || prop.type === 'select') && prop.label"
			:for="prop.name"
		>{{ prop.label }}<span v-if="callOrUse('optionalLabel', prop, selectedItem)" class="optional">Optional</span>
		</label>
		<div v-if="prop.type === 'input'" :class="[inputWrapperClass, prop.wrapperClass]" :data-test="prop.dataTest + '-input-wrapper'" class="input-wrapper">
			<input
				ref="element"
				v-model.trim="editProps[prop.name]"
				:placeholder="prop.placeholder"
				:name="prop.name"
				:disabled="selectedItem.disableEdit || callOrUse('disabled', prop, selectedItem)"
				:class="[ callOrUse('class', prop, selectedItem), prop.warning ? 'error' : '' ]"
				:data-test="prop.dataTest + '-input'"
				type="text"
				inline-modal-focus=""
				@keyup="clearWarnings"
			>
			<div v-if="prop.validate && prop.validate.maxLength" :data-test="prop.dataTest + '-remaining-characters'" class="remaining-characters">{{ remainingCharacters }}</div>
		</div>

		<toggle
			v-else-if="prop.type === 'toggle'"
			:id="itemName"
			ref="element"
			v-model="editProps[prop.name]"
			:name="prop.name"
			:label="prop.label"
			:tip="prop.tip"
			:class="callOrUse('class', prop, selectedItem)"
			:data-test="prop.dataTest + '-input'"
		/>

		<select
			v-else-if="prop.type === 'select'"
			ref="element"
			v-model="editProps[prop.name]"
			:name="prop.name"
			:class="[ callOrUse('class', prop, selectedItem), prop.warning ? 'error' : '' ]"
			:disabled="selectedItem.disableEdit || callOrUse('disabled', prop, selectedItem)"
			:data-test="prop.dataTest + '-input'"
		>
			<option v-for="(option, index) in evaluatedSelectOptions" :key="index" :value="option.value">
				{{ option.label }}
			</option>
		</select>
		<component
			:is="prop.component"
			v-else-if="prop.type === 'custom'"
			ref="element"
			v-model="editProps[prop.name]"
			:highlight-required-component-fields="highlightRequiredComponentFields"
			:disable-input="selectedItem.disableEdit"
			:edit-props="editProps"
			:adding="adding"
			:selected-item="selectedItem"
			:manager="manager"
			:in-onboarding="inOnboarding"
			@input="clearWarnings"
		/>
		<p v-if="prop.subText" class="sub-text" v-html="prop.subText"></p>
	</div>

</template>



<script type="text/babel">
import Toggle from '@/components/common/Toggle'
import Tooltip from './Tooltip'

export default {
	name: 'EditProp',
	components: {
		Tooltip,
		Toggle,
	},
	props: {
		prop: { type: Object, required: true },
		editProps: { type: Object, required: true },
		itemName: { type: String, default: '' },
		selectedItem: { type: Object, required: true },
		highlightRequiredComponentFields: { type: Array, default: () => [] },
		manager: { type: Object, default: () => ({}) },
		adding: { type: Boolean, default: false },
		inOnboarding: { type: Boolean, default: false },
	},
	computed: {
		evaluatedSelectOptions() {
			return this.callOrUse('options', this.prop)
		},
		remainingCharacters() {
			if (
				this.prop.validate &&
				this.prop.validate.maxLength &&
				typeof this.editProps[this.prop.name] === 'string'
			) {
				return this.prop.validate.maxLength - this.editProps[this.prop.name].length
			}
		},
		inputWrapperClass() {
			if (this.prop.validate && this.prop.validate.maxLength) {
				if (this.remainingCharacters < 0) {
					return 'show-count over-limit'
				} else if (this.remainingCharacters < this.prop.validate.maxLength * 0.6) {
					return 'show-count close-to-limit'
				} else if (this.remainingCharacters > this.prop.validate.maxLength * 0.8) {
					return 'hide-count'
				} else {
					return 'show-count'
				}
			}
		},
	},
	watch: {
		'prop.warning'(newVal) {
			if (newVal) {
				let activeElement = document.activeElement
				if (activeElement.classList && activeElement.classList.contains('error')) return
				this.focus()
			}
		},
	},
	methods: {
		focus() {
			// if the element is a component, then you can make a focus method for the component that focuses an input within
			this.$refs.element && this.$refs.element.focus && this.$refs.element.focus()
		},
		blur() {
			this.$refs.element && this.$refs.element.blur && this.$refs.element.blur()
		},
		clearWarnings() {
			this.$emit('clear-warnings')
		},
		callOrUse(attribute, prop, model) {
			// If the prop attribute is a function, call it, or use as is if not.
			return typeof prop[attribute] === 'function' ? prop[attribute](model) : prop[attribute]
		},
	},
}
</script>



<style scoped>
.bottom-margin-small { margin-bottom: var(--form-element-margin-small); }
	.input-wrapper { margin-bottom: var(--form-element-margin); position: relative; }
		.input-wrapper > input { margin-bottom: 0; }
		.input-wrapper.show-count > input { padding-right: 3rem; }

		.input-wrapper .remaining-characters { margin-right: -1px; position: absolute; top: 0; right: var(--form-element-padding); bottom: 0; display: flex; align-items: center; color: var(--color-light); font-size: 0.8125rem; user-select: none; }
		.input-wrapper.hide-count .remaining-characters { display: none; }
		.input-wrapper.close-to-limit .remaining-characters { color: var(--color-form-warning); }
		.input-wrapper.over-limit .remaining-characters { color: var(--color-form-error); }

	.input-wrapper.has-button-below  { margin-bottom: var(--form-element-margin-small); }
	.sub-text { margin-top: -15px; }

</style>
