<template>
	<form data-test="feedback" @submit.prevent>
		<h2>Feedback</h2>

		<p class="message">Thank you for using Momentum Team Beta! We welcome your feedback as we continue to improve upon the product.</p>

		<div class="feelings form-row">
			<ul>
				<li :class="{ 'active': feedback.feeling === feedbackTypes.UNHAPPY }" data-test="unhappy-face" @click="feedback.feeling=feedbackTypes.UNHAPPY"><img src="@/img/icons/angry.svg" class="icon"></li>
				<li :class="{ 'active': feedback.feeling === feedbackTypes.NEUTRAL }" data-test="neutral-face" @click="feedback.feeling=feedbackTypes.NEUTRAL"><img src="@/img/icons/meh.svg" class="icon"></li>
				<li :class="{ 'active': feedback.feeling === feedbackTypes.HAPPY }" data-test="happy-face" @click="feedback.feeling=feedbackTypes.HAPPY"><img src="@/img/icons/happy.svg" class="icon"></li>
			</ul>
		</div>

		<div class="form-row">
			<textarea v-model.trim="feedback.comments" placeholder="Comments" tabindex="1" data-test="textarea"></textarea>
		</div>

		<button :disabled="submitting" class="button" tabindex="2" data-test="submit-button" @click.prevent="submitFeedback">{{ submitting ? 'Submitting…' : 'Submit' }}</button>

		<transition key="error" name="fade">
			<form-message v-if="error" :message="errorMessage" :error="error" data-test="form-message"></form-message>
		</transition>

	</form>
</template>



<script type="text/babel">
import eventBus from '@/eventBus'
import { errorMessages, flashMessages } from '@/constants'
import FormMessage from '@/components/common/FormMessage'

export default {
	name: 'Feedback',
	components: { FormMessage },
	feedbackSource: '7557CF44-DC23-4AD3-B02F-24E28A6B0D10',
	data() {
		return {
			feedback: {
				feeling: null,
				comments: '',
			},
			submitting: false,
			error: false,
			errorMessage: '',
		}
	},
	created() {
		window.addEventListener('keydown', this.handleCmdEnter)
		this.feedbackTypes = {
			HAPPY: '90200464-FD90-4EE5-B414-BB3BFE0F39EF',
			NEUTRAL: '94A4FFAE-8AAF-4046-BA34-B3B32ED75E13',
			UNHAPPY: '4DEF9088-0213-4F8E-B41E-3F45AB8ABAB3',
		}
	},
	destroyed() {
		window.removeEventListener('keydown', this.handleCmdEnter)
	},
	methods: {
		handleCmdEnter(e) {
			if (e && (e.metaKey || e.ctrlKey) && e.keyCode === 13) this.submitFeedback()
		},
		setError(errorMessage) {
			this.error = true
			this.errorMessage = errorMessage
		},
		clearError() {
			this.error = false
			this.errorMessage = ''
		},
		submitFeedback() {
			this.submitting = true
			this.clearError()
			if (!this.feedback.feeling && this.feedback.comments.length === 0) {
				this.submitting = false
				this.setError(errorMessages.FEEDBACK_EMPTY)
				return
			}
			let data = {
				ratingUuid: this.feedback.feeling,
				comment: this.feedback.comments,
				sourceUuid: this.$options.feedbackSource,
			}
			this.$http
				.post('/feedback', data)
				.then(() => {
					this.submitting = false
					this.clearError()
					this.feedback.feeling = null
					this.feedback.comments = ''
					eventBus.$emit('flashMessage', flashMessages.FEEDBACK_SUBMITTED)
					this.$emit('close')
				})
				.catch(() => {
					this.submitting = false
					this.setError(errorMessages.SERVER_ERROR_GENERAL)
				})
		},
	},
}
</script>



<style scoped>

	label { font-size: 1rem; }

	.message { margin: -0.7rem 0 0.1rem; padding: 20px; background: hsl(var(--color-hue), 28%, 92%); border-radius: var(--border-radius); font-size: 0.9375rem; }

	.feelings { margin-bottom: 17px; }
		.feelings ul { width: 100%; display: flex; }
			.feelings li { margin-right: 10px; padding: 10px 0 6px; flex: 1 0 auto; border-radius: var(--border-radius); cursor: pointer; text-align: center; }
				.feelings li:last-child { margin-right: 0; }
				.feelings li:hover { box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1); }

				.feelings li .icon { height: 40px; opacity: 0.7; border-radius: 100%; cursor: pointer; transition: all 0.2s ease; }
					.feelings li:hover .icon { opacity: 0.85; box-shadow: 0 3px 9px hsla(0,0%,0%,0.2); transform: scale(1.05) translateY(-2px); }

				.feelings li.active { background: hsl(0, 2%, 95%); box-shadow: inset 0 0 0 1px rgba(0,0,0,0.2); transform: translateY(-2px); transition: all 0.2s ease; }
					.feelings li.active .icon { opacity: 1; box-shadow: 0 5px 16px hsla(0,0%,0%,0.2); transform: scale(1.05) translateY(-2px); }

	.feedback textarea { font-size: 0.9375rem; }

</style>
