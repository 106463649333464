<template>
	<div>
		<transition name="move-fade" mode="out-in">
			<section v-if="!loaded || !error" key="1">
				<loading-routes></loading-routes>
				<span class="logging-in">{{ $options.copy.LOGGING_IN }}</span>
			</section>
			<section v-else class="modal login-modal login">
				<form-message v-if="error" :message="errorMessage" :error="error" :contact-us="true">{{ errorMessage }}</form-message>
			</section>
		</transition>
	</div>

</template>



<script type="text/babel">
import FormMessage from '@/components/common/FormMessage'
import LoadingRoutes from '@/components/loaders/LoadingRoutes'
import { errorMessages, copy } from '@/constants'

export default {
	components: { FormMessage, LoadingRoutes },
	copy,
	data() {
		return {
			error: false,
			errorMessage: null,
			loaded: false,
		}
	},
	created() {
		const sso_provider = this.$route.params.sso_provider
		this.$http
			.post('sso/' + sso_provider, this.$route.query)
			.then(response => {
				this.loaded = true
				if (response && response.data) {
					let data = response.data
					if (data.success && data.redirectUrl) {
						window.location = data.redirectUrl
					}
				}
				this.error = false
			})
			.catch(() => {
				this.loaded = true
				this.error = true
				this.errorMessage = errorMessages.SSO_UNABLE_TO_REQUEST_TOKEN
			})
	},
}
</script>


<style scoped>
	.section-loading { margin-top: 0; }
	.logging-in { margin-top: 1.25rem; margin-left: -1rem; min-width: 7rem; position: absolute; color: white; }
	.sso-login >>> .form-message-label { margin-top: 0; }
</style>
