import Vue from 'vue'
import paymentManager from '@/managers/paymentManager'
import util from '@/util'

function initialData() {
	return {
		loaded: false,
		error: null,
		bankMessage: null,
		chargeAmount: null,
		refundAmount: null,
		amountDue: null,
		currentSubscription: null,
		lastSubscription: null,
		customer: null,
		hasLifetimePlan: null,
		offeredPlanMonthly: null,
		offeredPlanYearly: null,
		totalCount: null,
		balance: null,
		cards: [],
		subscriptions: [],
		charges: [],
		anonymousSubscription: null,
		upcomingInvoice: null,
	}
}

export default Vue.extend({
	data: function () {
		return initialData()
	},
	computed: {
		isTeamOrCommunity() {
			return (
				this.currentSubscription &&
				(this.currentSubscription.plan.type === 'Momentum Team' ||
					this.currentSubscription.plan.type === 'Momentum Community')
			)
		},
	},

	methods: {
		update(subscriptionInfo) {
			if (!subscriptionInfo) return
			Object.keys(subscriptionInfo).forEach(key => {
				// Only add pre-declared properties to the model. Anything else added to the model will not trigger reactivity
				if (this.hasOwnProperty(key)) this[key] = subscriptionInfo[key]
			})
			paymentManager.paymentInfo.update(subscriptionInfo.cards)
			if (subscriptionInfo.balance && subscriptionInfo.balance.amount && typeof this.balance === 'object') {
				this.balance.amount = '$' + util.formatNumber(Math.abs(subscriptionInfo.balance.amount))
			}
			this.charges.forEach(charge => {
				if (charge.card.brand === 'Visa') {
					charge.card.card_icon = 'fa-cc-visa'
				} else if (charge.card.brand === 'MasterCard') {
					charge.card.card_icon = 'fa-cc-mastercard'
				} else if (charge.card.brand === 'American Express') {
					charge.card.card_icon = 'fa-cc-amex'
				}
				charge.date = util.formatDate(charge.date)
				charge.amount = '$' + util.formatNumber(charge.amount)
			})
		},
		resetSubscriptionData() {
			let initialUserData = initialData()
			Object.keys(initialUserData).forEach(key => this.$set(this, key, initialUserData[key]))
		},
	},
})
