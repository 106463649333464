<template>
	<div
		v-click-outside="hideIfNeeded"
		:class="{ open: open, 'disable-transition': disableTransition, 'profile-active':inProfile }"
		class="user-menu"
	>

		<div ref="personal" class="personal profile-row" @click="open = !open" @click.once="disableTransition = false">
			<span class="profile-row-flex-wrapper">
				<div class="img-wrapper">
					<img :src="personalProfile.img">
				</div>
				<span class="name" data-test="active-name">{{ personalProfile.name }}</span>
			</span>

			<span class="anim-caret">
				<svg class="icon anim-caret-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.16 11.75"><path d="M52.16,26.08A5.87,5.87,0,0,1,46.29,32H5.88A5.88,5.88,0,0,1,0,26.08H0a5.87,5.87,0,0,1,5.88-5.87H46.29a5.87,5.87,0,0,1,5.87,5.87Z" transform="translate(0 -20.21)" /></svg>
				<svg class="icon anim-caret-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.16 11.75"><path d="M52.16,26.08A5.87,5.87,0,0,1,46.29,32H5.88A5.88,5.88,0,0,1,0,26.08H0a5.87,5.87,0,0,1,5.88-5.87H46.29a5.87,5.87,0,0,1,5.87,5.87Z" transform="translate(0 -20.21)" /></svg>
			</span>
		</div>
		<div class="user-menu-links">
			<ul class="user-menu-links-primary">
				<li v-if="userInfo.isTeamMember" class="profile-link"><router-link to="/profile" @click.native="hideIfNeeded">Profile</router-link></li>
				<!--				<li><a href="" @click.prevent="showFeedback">Feedback</a></li>-->
				<li><router-link to="/logout" @click.native="capture('log out', { feature: 'login' })">{{ $t('copy.LOG_OUT') }}</router-link></li>
			</ul>

			<ul class="user-menu-links-secondary">
				<!-- <li><a target="_blank" href="https://momentumdash.com/about">About</a></li> -->
				<li><a target="_blank" href="https://momentumdash.help">{{ $t('copy.HELP') }}</a></li>
				<!-- <li><a target="_blank" href="https://momentumdash.com/contact">Contact</a></li> -->
				<!-- <li><a target="_blank" href="https://momentumdash.com/privacy">Privacy</a></li> -->
			</ul>

			<div class="close" @click="open = false"></div>
		</div>

	</div>
</template>



<script>
import eventBus from '@/eventBus'
import Analytics from '../../Analytics'

export default {
	name: 'UserMenu',
	store: ['userInfo', 'interfaceState'],
	data() {
		return {
			disableTransition: true,
		}
	},
	computed: {
		open: {
			get() {
				return this.interfaceState.userMenuIsOpen
			},
			set(boolean) {
				this.$set(this.interfaceState, 'userMenuIsOpen', boolean)
			},
		},
		inProfile() {
			return this.$route.meta && this.$route.meta.isProfile
		},
		personalProfile() {
			return { name: this.userInfo.friendlyName || '', img: this.userInfo.img }
		},
	},
	watch: {
		'userInfo.display_name'() {
			this.$nextTick(this.setPersonalRowHeightVar)
		},
		'interfaceState.mobileViewActive'() {
			this.$nextTick(this.setPersonalRowHeightVar)
		},
	},
	created() {
		this.open = false
	},
	mounted() {
		this.setPersonalRowHeightVar()
	},
	methods: {
		setPersonalRowHeightVar() {
			let personal = this.$refs.personal
			if (personal) this.$set(this.interfaceState, 'userMenuPersonalHeight', personal.clientHeight)
		},
		hide() {
			this.open = false
			if (this.interfaceState.mobileViewActive) this.$set(this.interfaceState, 'mobileMenuIsOpen', false)
		},
		hideIfNeeded() {
			if (!this.inProfile || this.interfaceState.mobileViewActive || !this.userInfo.isAdmin) this.hide()
			if (this.interfaceState.mobileViewActive) this.$set(this.interfaceState, 'mobileMenuIsOpen', false)
		},
		showFeedback() {
			eventBus.$emit('showFeedback')
		},
		capture: Analytics.capture,
	},
}
</script>



<style>

	.user-menu { --transition-curve: ease-out; --transition-duration: 0.25s; --nav-bg: hsla(var(--color-hue), 30%, 55%, 0.9); width: 100%; position: absolute; bottom: 0; color: white; font-size: 15px; text-align: left; z-index: 10; transform: translateY(100%); transition: background-color var(--transition-duration) var(--transition-curve), transform var(--transition-duration) var(--transition-curve), opacity 0.3s ease; }
		.user-menu.open { background-color: var(--nav-bg); transform: translateY(0); }
		.user-menu.disable-transition { transition: opacity 0.3s ease; } /* keep opacity transition for mobile menu opening */
		.user-menu.profile-active .profile-link { background: linear-gradient(to right, hsla(var(--color-hue), 20%, 80%, 0.4), hsla(var(--color-hue), 20%, 80%, 0.1)) !important; letter-spacing: -0.3px; font-weight: 600; text-shadow: 0 0 20px rgba(255,255,255,0.5) !important;}

		.user-menu ul { margin: 0; }

			.profile-row { padding: 0; position: relative; display: flex; justify-content: space-between; align-items: center; box-shadow: inset 0px -1px rgba(255,255,255,0.15); cursor: pointer; transition: background-color var(--transition-medium) ease; }
				.profile-row:hover { background: hsla(var(--color-hue), 50%, 80%, 0.15); }

				.profile-row-flex-wrapper { min-width: 0; display: flex; align-items: center; flex: 1 1 auto; }
					.profile-row .img-wrapper { height: 26px; margin-right: 10px; display: flex; align-items: center; opacity: 0.8; transition: opacity var(--transition-medium) ease; }
						.profile-row:hover .img-wrapper { opacity: 1; }
						.profile-row img { height: auto; width: 26px; display: block; border-radius: 100%; }

					.profile-row .name { min-width: 0; opacity: 0.8; transition: opacity var(--transition-medium) ease; overflow-wrap: break-word; word-wrap: break-word; hyphens: auto; }
						.profile-row:hover .name { opacity: 1; }

				.user-menu a, .personal { width: var(--sidebar-width); display: block; color: #fff; font-size: 0.9375rem; }
					.profile-row a, .personal { padding: 15px var(--sidebar-padding); }

				.personal { position: absolute; bottom: 100%; display: flex; justify-content: space-between; align-items: center; -webkit-backface-visibility: hidden; }
					.open .personal:before { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: -1; background-color: var(--nav-bg); content: ''; }

					.personal .anim-caret { height: 10px; width: 10px; display: block; position: relative; flex: 0 0 auto; opacity: 0; transition: opacity var(--transition-medium) ease; }
						.user-menu:hover .anim-caret { opacity: 0.8; }
							.anim-caret-right, .anim-caret-left { height: 8px; width: 8px; display: inline-block; position: absolute; top: 3px; fill: #fff; transition: transform var(--transition-duration) var(--transition-curve); }
								.anim-caret-right { transform: rotate(45deg); top: 0; left: 12px; }
								.anim-caret-left { transform: rotate(-45deg); top: 0; left: 7px; }
								.open .anim-caret-right { transform: rotate(135deg); }
								.open .anim-caret-left { transform: rotate(-135deg); }

		.user-menu-links { padding-top: 10px; }
			.user-menu-links li { opacity: 0.8; transition: opacity var(--transition-medium) ease; }
				.user-menu-links li:hover { opacity: 1; }
				.user-menu-links a { padding: 4px var(--sidebar-padding); }

		.user-menu-links-secondary { display: flex; justify-content: flex-start; }
			.user-menu-links-secondary li { opacity: 0.6;}
				.user-menu-links-secondary a { padding: 6px 7px; font-size: 0.8125rem; }
				.user-menu-links-secondary li:first-child a { padding-left: var(--sidebar-padding); }
				.user-menu-links-secondary a:hover { opacity: 1; }

			.close { height: 10px; position: relative; z-index: 10; }

@media handheld and (max-width: 740px), screen and (max-device-width: 740px), screen and (max-width: 740px)  {
	.user-menu { opacity: 0; pointer-events: none; }
		.main-nav-active .user-menu { opacity: 1; pointer-events: all; }

		.profile-row-flex-wrapper { flex: 0 1 auto; }
			.profile-row-flex-wrapper .img-wrapper { height: 40px; margin-right: 15px; }
				.profile-row-flex-wrapper img { width: 40px; }
				.profile-row-flex-wrapper .name { font-size: 20px; }

		.profile-row a, .personal { width: 100vw; /* width: 100% does not work and causes some very strange behaviour */ padding: 18px var(--sidebar-padding); justify-content: flex-start; }
			.profile-row.personal { background: none; justify-content: flex-start; }
				.personal .anim-caret { height: 10px; width: 10px; margin-left: 20px; opacity: 1; }
				.personal .anim-caret-left, .personal .anim-caret-right { height: 10px; width: 10px; top: 1px; }
				.personal .anim-caret-left { left: -5px; }
				.personal .anim-caret-right { left: 1px; }

		.user-menu a, .personal { width: 100vw; }

		.user-menu-links-primary a { font-size: 1rem; padding: 8px var(--sidebar-padding); }
		.user-menu-links-secondary a { font-size: 0.8rem; padding: 8px var(--sidebar-padding); }
}
</style>
