import util from '@/util'

export default {
	data() {
		return {
			editProps: {},
			/*
			When editing/creating an item, all relevant properties are copied to the editProps object, so that we can
			keep track of changes and revert back to the previous version on edit cancellation. All edits are made on the
			editProps object and then copied over to the base model properties after a successful response. There are a couple
			exceptions to this, where the edits are copied optimistically e.g. when reordering.

			This is similar to a memento pattern https://en.wikipedia.org/wiki/Memento_pattern
			except that the new state is kept in a separate object instead of the old state. This is to prevent Vue from
			"over" reacting e.g. switching a countdown into the expired list mid edit because it no longer matches the active filter.
			*/
		}
	},
	computed: {
		ep() {
			return this.editProps
		},
	},
	methods: {
		updateData(item) {
			this.props.forEach(prop => (this[prop] = item[prop]))
			this.revertChanges()
		},
		revertChanges() {
			this.editProps = {}
		},
		getChanges() {
			// Does not compare arrays, or objects. Overwrite this method if you need to.
			let changes = []
			if (this.editProps) {
				this.props.forEach(prop => {
					if (Array.isArray(this[prop]) || Array.isArray(this.editProps[prop])) {
						if (!util.arraysAreEqual(this[prop], this.editProps[prop])) changes.push(prop)
					} else if (this[prop] !== this.editProps[prop]) {
						changes.push(prop)
					}
				})
			}
			return changes
		},
		commitChanges() {
			this.beforeCommitChanges && this.beforeCommitChanges()
			this.props.forEach(prop => {
				if (typeof this.editProps[prop] !== 'undefined') this[prop] = this.editProps[prop]
			})
			this.revertChanges()
		},
		copyProperties() {
			for (let prop of this.props) {
				if (Array.isArray(this[prop])) {
					this.$set(this.editProps, prop, [...this[prop]])
				} else if (typeof this[prop] === 'object' && this[prop] !== null) {
					// null is an object btw! Thanks Javascript!
					this.$set(this.editProps, prop, { ...this[prop] })
				} else {
					this.$set(this.editProps, prop, this[prop])
				}
			}
		},
	},
}
