import Vue from 'vue'
import CountdownModel from '@/models/CountdownModel'
import DateTimePicker from '@/components/team/countdowns/DateTimePicker'
import util from '@/util'
import teamCountdownDataService from '../services/teamCountdownDataService'
import { baseManager } from './mixins/itemManagerMixins'

export default new (Vue.extend({
	Model: CountdownModel,
	dataService: teamCountdownDataService,
	mixins: [baseManager],
	data() {
		return {
			addInfo: {
				id: 'countdowns',
				title: 'Countdowns',
				singularItemTitle: 'Countdown',
				addTitle: 'What countdown would you like to add?',
				description: 'Count down to important deadlines and dates',
				iconName: 'countdown',
				props: [
					{
						name: 'id',
						type: 'hidden',
					},
					{
						name: 'name',
						placeholder: 'What are you counting down to?',
						default: '',
						class: 'item-body',
						type: 'input',
						label: 'Name',
						required: true,
						warning: false,
						dataTest: 'name',
					},
					{
						name: 'dateEdit',
						type: 'custom',
						component: DateTimePicker,
					},
					{
						name: 'pinned',
						default: false,
						type: 'toggle',
						label: 'Pinned',
						dataTest: 'pinned',
					},
				],
			},
		}
	},
	methods: {
		getAddRequestBody(countdown) {
			if (!countdown.editProps.dateEdit.utcDate) countdown.editProps.dateEdit.utcDate = countdown.setDefaultDate()
			return {
				name: util.capitalize(countdown.editProps.name),
				utcDate: countdown.editProps.dateEdit.utcDate,
				hasHours: countdown.editProps.dateEdit.hasHours,
				pinned: countdown.editProps.pinned,
			}
		},
	},
}))()
