<template>
	<div class="toggle">
		<tooltip v-if="tipText">
			<div slot="input" :class="active ? 'on' : '' " class="switch-wrapper">
				<div :data-test="dataTest" class="switch-click" @click="toggle">
					<div :class="{ 'on': active }" class="switch"></div>
					<span v-if="label" class="switch-label">{{ label }}</span>
				</div>
			</div>
			<div slot="tip-text" class="tip switch-tip">{{ tipText }}</div>
		</tooltip>
		<div v-else :class="active ? 'on' : '' " class="switch-wrapper">
			<div :data-test="dataTest" class="switch-click" @click="toggle">
				<div :class="{ 'on': active }" class="switch"></div>
				<span v-if="label" class="switch-label">{{ label }}</span>
			</div>
		</div>
	</div>
</template>



<script>
import Tooltip from './Tooltip'

export default {
	name: 'Toggle',
	components: { Tooltip },
	props: {
		id: { type: String, default: '' },
		name: { type: String, default: '' },
		value: { type: Boolean, default: false },
		label: { type: String, default: '' },
		tip: { type: String, default: '' },
		onEnter: { type: Function, default: () => {} },
		onUpdate: { type: Function, default: () => {} },
		onBlur: { type: Function, default: () => {} },
		dataTest: { type: String, required: true },
	},
	data() {
		return {
			active: false,
		}
	},
	computed: {
		tipText() {
			if (this.tip) return this.tip

			if (this.name == 'pinned') {
				return 'Show this ' + (this.id && this.id.toLowerCase()) + ' directly on the dashboard.'
			}
			// if (this.name == "readOnly") return "Show on dashboards but only allow editing by admins.";
		},
	},
	watch: {
		value(newVal) {
			this.active = newVal
		},
	},
	mounted() {
		this.active = this.value
	},
	methods: {
		toggle(e) {
			e.preventDefault()
			this.active = !this.active
			this.$emit('input', this.active)
		},
	},
}
</script>



<style scoped>
	.switch-wrapper { }

		.switch-click { display: flex; align-items: center; cursor: pointer; }
			.switch-click:hover { opacity: 0.8; transition-duration: 0s; }

			.switch { height: 20px; width: 33px; position: relative; display: inline-block; background: rgba(0,0,0,0.25); border-radius: 50px; box-shadow: inset 0 0 0 1px rgba(0,0,0,0.0); transition: all 0.1s ease; }
				.switch:before { height: 14px; width: 14px; margin: 2px 1px; position: absolute; top: 1px; left: 2px; z-index: 2; background: rgba(255,255,255,1); border-radius: 100%; box-shadow: 0 0 0 1px rgba(0,0,0,0.0); content: ''; transition: all 0.2s ease; }

				.switch.on { background: var(--color-success); }
					.switch.on:before { background: white; transform: translateX(13px); }

				.switch-label { margin-left: 9px; display: inline-block; margin-bottom: 1px; color: var(--color-label); font-size: 0.9375rem; font-weight: 400; }
</style>
