import Vue from 'vue'

export default new (Vue.extend({
	name: 'ScrollManager',
	body: document.querySelector('body'),
	data() {
		return {
			elements: {},
		}
	},
	methods: {
		addElement(id, el) {
			this.elements[id] = el
		},
		removeElement(id) {
			delete this.elements[id]
		},
		addListener() {
			window.addEventListener('wheel', this.handleWheel, { passive: false })
			// { passive: false } is added to prevent error message, however it may decrease mobile scrolling performance
			// see: https://www.chromestatus.com/features/6662647093133312
		},
		removeListener() {
			window.removeEventListener('wheel', this.handleWheel)
		},
		handleWheel(e) {
			let allowNativeScroll = Object.keys(this.elements).some(key => {
				let el = this.elements[key]
				if (el.contains(e.srcElement)) {
					if (e.deltaY < 0 && el.scrollTop <= 0) return false
					if (e.deltaY > 0 && el.scrollTop >= el.scrollHeight - el.offsetHeight) return false
					return true
				}
			})
			if (!allowNativeScroll) {
				e.preventDefault()
				this.$options.body.scrollBy({ top: e.deltaY })
			}
		},
	},
}))()
