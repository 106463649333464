<template>
	<div
		ref="wrapper"
		:style="{ '--duration': duration + 'ms', height: wrapperHeight }"
		:class="{ 'hide-overflow': hideOverflow && transitionActive }"
		:data-smooth-height-id="id"
		class="resize-wrapper"
	>
		<div v-resize-sensor="handleResize">
			<transition name="smooth-height">
				<slot></slot>
			</transition>
		</div>
	</div>
</template>



<script>
import eventBus from '../../eventBus'

export default {
	name: 'SmoothHeight',
	props: {
		duration: { type: Number, default: 500 },
		nested: { type: Boolean, default: false }, // If this is within another smooth-height component, this must be true
		hideOverflow: { type: Boolean, default: false },
	},
	data() {
		return {
			id: Math.random().toString(36).substring(7),
			wrapperHeightPx: '',
			auto: true, // height: auto;
			transitionActive: false,
			ancestors: [],
		}
	},
	computed: {
		wrapperHeight() {
			return this.auto || !this.wrapperHeightPx ? 'auto' : this.wrapperHeightPx
		},
	},
	created() {
		eventBus.$on('pauseSmoothHeight:' + this.id, this.pauseSmoothHeight)
		eventBus.$on('resumeSmoothHeight:' + this.id, this.resumeSmoothHeight)
	},
	mounted() {
		if (this.nested) {
			this.$nextTick(this.findSmoothHeightAncestors)
			setTimeout(() => {
				/* No transitions until .5s after the component has been mounted.
				Prevents janky unintentional transitions caused by loading images, etc.
				Exaggerated when nesting smooth height components. Ideally we should replace the tiemout with a
				callback that runs when all images have loaded */
				this.auto = false
				this.setExplicitHeight()
			}, 500)
		} else {
			this.auto = false
			this.setExplicitHeight()
		}
	},
	destroyed() {
		eventBus.$off('pauseSmoothHeight:' + this.id, this.pauseSmoothHeight)
		eventBus.$off('resumeSmoothHeight:' + this.id, this.resumeSmoothHeight)
	},
	methods: {
		handleResize(e) {
			if (this.auto) return
			this.transitionActive = true
			clearTimeout(this.timeout)
			if (this.nested && this.ancestors.length) {
				this.ancestors.forEach(id => eventBus.$emit('pauseSmoothHeight:' + id))
				this.$nextTick(() => {
					this.wrapperHeightPx = e.height + 'px'
					this.timeout = setTimeout(() => {
						this.ancestors.forEach(id => eventBus.$emit('resumeSmoothHeight:' + id))
						this.transitionActive = false
					}, this.duration)
				})
			} else {
				this.wrapperHeightPx = e.height + 'px'
				this.timeout = setTimeout(() => (this.transitionActive = false), this.duration)
			}
		},
		findSmoothHeightAncestors() {
			let ancestorsRemaining = true,
				nextAncestor
			while (ancestorsRemaining) {
				nextAncestor = ((nextAncestor && nextAncestor.parentElement) || this.$el.parentElement).closest(
					'[data-smooth-height-id]'
				)
				if (nextAncestor) this.ancestors.push(nextAncestor.getAttribute('data-smooth-height-id'))
				else ancestorsRemaining = false
			}
		},
		pauseSmoothHeight() {
			this.auto = true
		},
		resumeSmoothHeight() {
			this.auto = false
			this.setExplicitHeight()
		},
		setExplicitHeight() {
			this.wrapperHeightPx = this.$refs.wrapper.clientHeight + 'px'
		},
	},
}
</script>



<style scoped>
.resize-wrapper { transition: height var(--duration) ease; }
	.resize-wrapper.hide-overflow { overflow: hidden; }

.smooth-height-enter-active { transition: opacity var(--duration) cubic-bezier(.85,-0.18,.66,.66); }
.smooth-height-leave-active { transition: opacity var(--duration) cubic-bezier(.33,.13,.33,1.26) }
.smooth-height-leave-active { position: absolute; top: 0; right: 0; left: 0; }
.smooth-height-enter, .smooth-height-leave-to { opacity: 0; }
</style>
