<template>
	<typeahead
		:value="inputValue"
		:results="results"
		:loading="loading"
		:no-results="noResults"
		:type="type"
		placeholder="Start typing a city, province, or state"
		@input="fetchResults"
		@select="onSelect"
	/>
</template>



<script>
import Typeahead from '../../common/Typeahead'
import Axios from 'axios'
export default {
	name: 'LocationTypeahead',
	components: { Typeahead },
	props: {
		value: { type: Object, default: () => ({}) },
		type: { type: String, default: 'location' },
		beforeFetchResults: { type: Function, default: null },
	},
	data() {
		// noResults cannot be computed because after selecting,
		// input has a value, results are empty, and loading is false,
		// but we don't want to show the dropdown
		return {
			results: [],
			inputValue: '',
			loading: false,
			noResults: false,
		}
	},
	created() {
		if (this.value.timeZoneName) this.inputValue = this.value.timeZoneName
	},
	methods: {
		async fetchResults(query) {
			// Not using v-model / watch for this because if we do, then when onSelect is called and the inputValue is set from here,
			// It would trigger this to run. We only want to run this on user input.
			this.inputValue = query
			if (query.trim().length > 2) {
				this.noResults = false
				const beforeFetchResults = this.beforeFetchResults?.(query)
				if (beforeFetchResults) {
					this.results = beforeFetchResults
				} else {
					this.loading = true
					if (this.cancelTokenSource) this.cancelTokenSource.cancel()
					this.cancelTokenSource = Axios.CancelToken.source()
					try {
						const { data: results } = await this.$http.get('/locations:search?input=' + query, {
							cancelToken: this.cancelTokenSource.token,
						})
						this.loading = false
						this.results = results
						if (!this.results.length) this.noResults = true
					} catch (e) {
						if (Axios.isCancel(e)) return
						console.error(e)
						// Adding loading = false in finally does not work because it catches a tick after the loading = true
						// set by the query that triggered the cancellation, causing the loading state to clear when it should persist.
						this.loading = false
					}
				}
			} else {
				if (this.cancelTokenSource) this.cancelTokenSource.cancel()
				this.results = []
				this.noResults = false
				this.loading = false
			}
		},
		onSelect(location) {
			this.results = []
			this.noResults = false
			this.$emit('input', location)
			this.inputValue = location.name
		},
	},
}
</script>
