import { errorMessages, dateRolloverHour, datetime, browsers } from './constants'
import store from './store'

Storage.prototype.setObject = function (key, value) {
	this.setItem(key, JSON.stringify(value))
}

Storage.prototype.getObject = function (key) {
	const value = this.getItem(key)
	return value && JSON.parse(value)
}

export default {
	getPlanAnalyticsProperties(currentSubscription) {
		return {
			plan_id: currentSubscription.plan.id,
			plan_interval: currentSubscription.plan.interval,
		}
	},
	getCurrentSupportedBrowserInfo() {
		switch (true) {
			case store.browser.isChrome:
				return browsers.chrome
			case store.browser.isFirefox:
				return browsers.firefox
			case store.browser.isEdge:
				return browsers.edge
			case store.browser.isSafari:
				return browsers.safari
			default:
				return null
		}
	},
	formatDate(date, { year = 'numeric', month = 'short', day = 'numeric' } = {}) {
		if (!date) return ''
		let options = {}
		if (year) options.year = year
		if (month) options.month = month
		if (day) options.day = day
		return new Date(date).toLocaleString('en-us', options)
	},
	daysRemaining(dateString) {
		if (!dateString) return null
		let timeDiff = new Date(dateString) - new Date()
		return Math.floor(timeDiff / datetime.MS_PER_DAY)
	},
	//35632.8163736 => 35,632.82
	formatNumber(number) {
		if (!number) return 0
		number = number.toFixed(2)
		let parts = number.toString().split('.')
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		return parts.join('.')
	},
	isEmail(email) {
		return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test(
			email
		)
	},

	ensureUrlScheme(url) {
		if (url) url = url.trim()
		if (
			!/^(((f|ht)tps?)|chrome|chrome-extension|chrome-search):\/\//i.test(url) &&
			!/^(file:\\|file:\/\/)+/i.test(url)
		) {
			url = 'http://' + url
		}
		return url
	},

	ensurePeriodEnd(text) {
		text = text.trim()
		if (!text.endsWith('.') && !text.endsWith('?') && !text.endsWith('!')) text = text + '.'
		return text
	},

	stripQuotationMarks(text) {
		if (text) return text.trim().replace(/^['"‘“](.+)['"’”]$/, '$1')
	},

	capitalize(text) {
		if (!text) return text
		text = text.trim()
		text.charAt(0).toUpperCase() + text.slice(1)
		return text
	},

	ensureSecureUrlScheme(url) {
		url = url.trim()
		if (!/^(https|chrome|chrome-extension|chrome-search):\/\//i.test(url)) {
			url = 'https://' + url
		}
		return url
	},

	getRelativeUrl(url) {
		// returns everything after the first /
		if (!url) return ''
		try {
			let urlObj = new URL(url)
			return (urlObj.pathname + urlObj.search).split('/')[1]
		} catch (e) {
			// Internet Explorer does not support URL objects
			return url.replace(/^(?:\/\/|[^/]+)*\//, '')
		}
	},

	uuidv4() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			let r = (Math.random() * 16) | 0,
				v = c == 'x' ? r : (r & 0x3) | 0x8
			return v.toString(16)
		})
	},

	arraysAreEqual(arr1, arr2) {
		return JSON.stringify(arr1) === JSON.stringify(arr2)
	},

	approximateTime(date) {
		let hour = date.getHours()
		if (hour >= 4 && hour < 12) {
			return 'morning'
		} else if (hour >= 12 && hour < 17) {
			return 'afternoon'
		} else {
			return 'evening'
		}
	},

	activeLocalDateTimeStringForDate(val) {
		let date = new Date(val)
		return (
			date.getFullYear().toString() +
			'-' +
			this.twoDigit(date.getMonth() + 1) +
			'-' +
			this.twoDigit(date.getDate()) +
			'T' +
			this.twoDigit(date.getHours()) +
			':' +
			this.twoDigit(date.getMinutes()) +
			':' +
			this.twoDigit(date.getSeconds())
		)
	},

	activeDateString() {
		// If it's before the rollover hour, treat it as the previous day
		let date = new Date()
		if (date.getHours() < dateRolloverHour) {
			date = new Date(date.getTime() - 1000 * 60 * 60 * 24) //ms per day
		}
		return (
			date.getFullYear().toString() +
			'-' +
			this.twoDigit(date.getMonth() + 1) +
			'-' +
			this.twoDigit(date.getDate())
		)
	},

	dateAdd(date, interval, units) {
		/*
			Adds time to a date. Modelled after MySQL DATE_ADD function.
			Example: dateAdd(m.date(), 'minute', 30)  // Returns 30 minutes from now.
			https://stackoverflow.com/a/1214753/18511

			@param date  		Date to start with
			@param interval  One of: year, quarter, month, week, day, hour, minute, second
			@param units  		Number of units of the given interval to add.
		*/
		let ret = new Date(date) // Don't change original date
		let checkRollover = function () {
			if (ret.getDate() !== date.getDate()) ret.setDate(0)
		}

		switch (interval.toLowerCase()) {
			case 'year':
				ret.setFullYear(ret.getFullYear() + units)
				checkRollover()
				break
			case 'quarter':
				ret.setMonth(ret.getMonth() + 3 * units)
				checkRollover()
				break
			case 'month':
				ret.setMonth(ret.getMonth() + units)
				checkRollover()
				break
			case 'week':
				ret.setDate(ret.getDate() + 7 * units)
				break
			case 'day':
				ret.setDate(ret.getDate() + units)
				break
			case 'hour':
				ret.setTime(ret.getTime() + units * 3600000)
				break
			case 'minute':
				ret.setTime(ret.getTime() + units * 60000)
				break
			case 'second':
				ret.setTime(ret.getTime() + units * 1000)
				break
			default:
				ret = undefined
				break
		}
		return ret
	},

	twoDigit(number) {
		let numAsInteger = parseInt(number)
		let twodigit = numAsInteger >= 10 ? numAsInteger : '0' + numAsInteger.toString()
		return twodigit.toString()
	},

	handleError(error) {
		if (typeof error === 'string') {
			return error
		} else if (error.message === 'Network Error') {
			return errorMessages.SERVER_ERROR_NETWORK
		} else if (error.response && error.response.status === 500) {
			return errorMessages.SERVER_ERROR_GENERAL
		} else {
			return error.message
		}
	},

	isVowel(character, includeY) {
		if (!character) return false
		let vowels = ['a', 'e', 'i', 'o', 'u']
		if (includeY) vowels.push('y')
		return vowels.indexOf(character.toLowerCase()) !== -1
	},

	pluralize(quantity, stringTemplate) {
		/*
			stringTemplate = 'There {is:are} {#} app{:s} to categorize'

			quantity === 1 => 'There is 1 app to categorize'
			quantity === 2 => 'There are 2 apps to categorize'
			quantity === 0 => 'There are 0 apps to categorize'

			Remember, because this uses RegExp, if the string is really long it could be more efficient to pass this function
			a smaller section of the string. e.g. `Deleting this category will decategorize ${util.pluralize(count, '{#} app{:s}')}.`

			There is no way of escaping brackets or colons yet, but if/when we need that, I'll build it! - Dace
		*/
		const re = new RegExp(/\{([^}]+)\}/g)
		return stringTemplate.replace(re, match => {
			const bracketlessMatch = match.substring(1, match.length - 1)
			if (bracketlessMatch === '#') {
				return quantity
			} else {
				let choices = bracketlessMatch.split(':')
				return quantity === 1 ? choices[0] : choices[1]
			}
		})
	},

	possessiveForm(str) {
		/*
			Return the possessive form of `str`. Examples:
				str = `Scott`, possessive = `Scott’s`
				str = `Linds`, possessive = `Linds’`
			Avoids cases like `Linds’s`.
		*/
		return str.endsWith('s') ? `${str}’` : `${str}’s`
	},

	getCardLabel(card) {
		return `${card.brand} ending in ${card.last4}`
	},

	isDev() {
		return process.env.NODE_ENV === 'development'
	},

	loadDevPanel() {
		return process.env.VUE_APP_DEV_PANEL === 'true'
	},
	joinArrayItems(arr) {
		// join array items with ',' and 'and'
		switch (arr.length) {
			case 0:
				return null
			case 1:
				return arr[0]
			case 2:
				return arr.join(' and ')
			default:
				return `${arr.slice(0, -1).join(', ')}, and ${arr.slice(-1)}`
		}
	},

	camelize(string) {
		// to make any string camel case
		return string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
			if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
			return index === 0 ? match.toLowerCase() : match.toUpperCase()
		})
	},
	async delay(ms) {
		return new Promise(resolve => setTimeout(resolve, ms))
	},
}

export const addTeamMembersUtil = {
	getUserCluster(amount = 3) {
		return Array.from(Array(amount), () => ({ email: '', fullName: '', admin: null, roleUuid: null, error: false }))
	},
}

export const TeamTypes = { Team: 1, Community: 2 }
