import Vue from 'vue'
import teamDataService from '@/services/teamDataService'
import TeamModel from '@/models/TeamModel'
import store from '@/store'

export default Vue.extend({
	data: function () {
		return {
			activeTeam: new TeamModel(),
			teams: {},
		}
	},
	methods: {
		refreshTeams() {
			if (!store.userInfo.isTeamMember && !store.userInfo.isCommunityMember) {
				return Promise.reject('User is not a team or community member')
			}
			return teamDataService.getTeamsForUser(true).then(teams =>
				teams.forEach(team => {
					let groups = team.groups
					delete team.groups
					this.activeTeam.updateTeam(team)
					this.activeTeam.loadGroups(groups)
					this.$set(this.teams, team.teamUuid, team)
					// set lastReceived to be the active team
					this.activeId = team.teamUuid
				})
			)
		},
	},
})
