/* global FileReader */
import Vue from 'vue'
import eventBus from '@/eventBus'
import { flashMessages } from '@/constants'

export default new (Vue.extend({
	data: function () {
		return {
			count: 0,
			inProgress: false,
		}
	},
	methods: {
		initialize: function (options) {
			options = options || {}
			this.maxBlockSize = options.maxBlockSize == null ? 256 * 1024 : options.maxBlockSize
			this.blockIdPrefix = 'block-'
			this.totalBytes = 0
			this.totalBytesUploaded = 0
		},
		uploadFile: function (submission) {
			submission.uploading = true
			let self = this,
				file = submission.file,
				reader = new FileReader(),
				uploadUri = submission.file_upload_uri,
				maxBlockSize = this.maxBlockSize,
				currentFilePointer = 0,
				totalBytesRemaining = 0,
				blockIds = [],
				bytesUploaded = 0,
				fileSize = submission.file.size

			this.totalBytes += fileSize

			if (fileSize < maxBlockSize) {
				maxBlockSize = fileSize
			}
			totalBytesRemaining = fileSize

			function uploadFileInBlocks() {
				if (totalBytesRemaining > 0) {
					let fileContent = file.slice(currentFilePointer, currentFilePointer + maxBlockSize)
					let blockId = self.blockIdPrefix + self.pad(blockIds.length, 6)
					blockIds.push(window.btoa(blockId))
					reader.readAsArrayBuffer(fileContent)
					currentFilePointer += maxBlockSize

					totalBytesRemaining -= maxBlockSize

					if (totalBytesRemaining < maxBlockSize) {
						maxBlockSize = totalBytesRemaining
					}
				} else {
					commitBlockList()
				}
			}
			function commitBlockList() {
				let uri = uploadUri + '&comp=blocklist',
					requestBody = "<?xml version='1.0' encoding='utf-8'?><BlockList>",
					i
				for (i = 0; i < blockIds.length; i++) {
					requestBody += '<Latest>' + blockIds[i] + '</Latest>'
				}
				requestBody += '</BlockList>'
				self.$http
					.put(uri, requestBody, {
						headers: {
							'x-ms-blob-content-type': submission.file.type,
							'Content-Type': 'application/xml',
						},
					})
					.then(function () {
						submission.upload_completed = true
						self.inProgress = false

						self.$http
							.patch('files/' + submission.file_uuid, { upload_completed: true })
							.then(function () {
								submission.uploading = false
								eventBus.$emit('flashMessage', flashMessages.NEW_LOGO_UPLOADED)
							})
							.catch(function () {
								submission.uploading = false
								// TODO: maybe set a flag that the file has been uploaded, but the completion hasn't been flagged
							})
					})
					.catch(function () {
						self.inProgress = false
						submission.upload_failed = true
					})
			}
			reader.onloadend = function (e) {
				if (e.target.readyState === FileReader.DONE) {
					let uri = uploadUri + '&comp=block&blockid=' + blockIds[blockIds.length - 1],
						requestData = new Uint8Array(e.target.result),
						currentBytes = requestData.length

					self.$http
						.put(uri, requestData, {
							headers: {
								'x-ms-blob-type': 'BlockBlob',
								'x-ms-blob-content-type': file.type,
							},
						})
						.then(function () {
							bytesUploaded += currentBytes
							submission.fileprogress = parseInt((bytesUploaded / fileSize) * 100)
							uploadFileInBlocks()
						})
						.catch(function () {
							self.inProgress = false
							submission.upload_failed = true
						})
				}
			}
			uploadFileInBlocks()
		},
		pad: function (number, length) {
			let str = '' + number
			while (str.length < length) {
				str = '0' + str
			}
			return str
		},
	},
}))()
