import Vue from 'vue'
import store from '@/store'
import util from '@/util'
import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	mixins: [itemModel],
	data: function () {
		return {
			id: null,
			title: '',
			iconId: null,
			date: null,
			items: {},
			loaded: true, // the real loading state will be on the category level, but if this exists, it will be loaded
			order: null,
			dragging: false,
			props: ['title', 'id', 'iconId', 'order'],
		}
	},
	computed: {
		deleteWarning() {
			const count = Object.keys(this.items).length
			if (count) return `Deleting this category will delete ${util.pluralize(count, '{#} topic{:s}')}.`
		},
		iconUrl() {
			let icon = store.teamInfo.activeTeam.teamIcons.items[this.iconId]
			return icon && icon.url
		},
	},
})
