import Vue from 'vue'
import util from '@/util'

// TODO: Refactor this once we can actually access it
export default new (Vue.extend({
	methods: {
		getWebhooks() {
			return new Promise((resolve, reject) => {
				this.$http
					.get('team/api/webhooks')
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		addWebhook(webhook) {
			let toSend = {}
			toSend.description = webhook.editProps.description
			toSend.webhookTypeId = webhook.editProps.type
			toSend.url = util.ensureSecureUrlScheme(webhook.url.trim())
			return new Promise((resolve, reject) => {
				this.$http
					.post('team/api/webhooks/subscriptions', toSend)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		updateWebhook(webhook) {
			let updateInfo = {}
			webhook.getChanges().forEach(prop => {
				updateInfo[prop] = webhook.editProps[prop]
			})
			return new Promise((resolve, reject) => {
				this.$http
					.patch('team/api/webhooks/' + webhook.id, updateInfo)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		deleteWebhook(webhookId) {
			return this.$http.delete('team/api/webhooks/' + webhookId)
		},
	},
}))()
