<template>
	<form :id="id" ref="form" autocomplete="on" class="login-form" @submit.prevent>
		<slot></slot>
		<transition name="fade">
			<form-message
				v-if="message"
				:error="error"
				:success="success"
				:message="message"
				:test-attribute="formTestAttribute"
			></form-message>
		</transition>
	</form>
</template>



<script type="text/babel">
import smoothReflow from 'vue-smooth-reflow'
import FormMessage from '@/components/common/FormMessage'

export default {
	name: 'AuthForm',
	components: {
		FormMessage,
	},
	mixins: [smoothReflow],
	props: {
		id: { type: String, default: '' },
		formTestAttribute: { type: String, default: '' },
		success: { type: Boolean, default: false },
	},
	store: ['authInfo'],
	computed: {
		message() {
			return this.authInfo.errorMessage || this.authInfo.statusMessage
		},
		error() {
			return this.authInfo.errorMessage && typeof this.authInfo.errorMessage !== 'undefined'
		},
	},
	created() {
		this.authInfo.resetState()
	},
	mounted() {
		this.$smoothReflow({
			el: this.$refs.form,
		})
	},
}
</script>
