import Vue from 'vue'

function initialData() {
	return {
		loaded: false,
		error: null,
		coupons: [],
	}
}

export default Vue.extend({
	data: function () {
		return initialData()
	},
	methods: {
		resetGiftData() {
			let initialUserData = initialData()
			Object.keys(initialUserData).forEach(key => this.$set(this, key, initialUserData[key]))
		},
	},
})
