import Vue from 'vue'
import linkService from '@/services/teamLinkDataService'
import LinkModel from '@/models/LinkModel'
import Sublinks from '@/components/team/links/Sublinks'
import util from '@/util'
import { linkTypes } from '@/constants'
import { baseManager, reorder } from '@/managers/mixins/itemManagerMixins'

export default new (Vue.extend({
	mixins: [baseManager, reorder],
	Model: LinkModel,
	dataService: linkService,
	data: function () {
		return {
			addInfo: {
				id: 'links',
				title: 'Links',
				singularItemTitle: 'Link',
				addTitle: 'What link would you like to add?',
				description: 'Essential links available in the top left corner of your team’s dashboard',
				iconName: 'links',
				allowReorder: true,
				sort: (a, b) => {
					if (a.pinned && !b.pinned) return -1
					if (!a.pinned && b.pinned) return 1
					return a.order - b.order
				},
				props: [
					{
						name: 'id',
						type: 'hidden',
						hiddenInList: true,
					},
					{
						name: 'title',
						default: '',
						class: 'item-name',
						type: 'input',
						label: 'Name',
						required: model => model.type !== linkTypes.SUBLINK,
						warning: false,
						dataTest: 'name',
					},
					{
						name: 'url',
						default: '',
						class: 'item-detail',
						wrapperClass: 'has-button-below',
						type: 'input',
						label: 'URL',
						placeholder: 'example.com',
						required: model =>
							!model.sublinks || !model.sublinks.items || Object.keys(model.sublinks.items).length === 0,
						hiddenInList: model => model.type === linkTypes.GROUP,
						hiddenInForm: model => model.type === linkTypes.GROUP,
						warning: false,
						dataTest: 'url',
					},
					{
						name: 'groupSummary',
						class: 'item-detail',
						hiddenInList: model => model.type !== linkTypes.GROUP,
						hiddenInForm: true,
					},
					{
						name: 'sublinks',
						type: 'custom',
						manager: this,
						label: 'Sublinks',
						warning: false,
						hiddenInList: true,
						component: Sublinks,
						required: true,
						requiredComponentFields: editProps => {
							if (
								!Object.values(editProps.sublinks.items).some(sublink => sublink.url) &&
								!editProps.url
							) {
								return ['sublinks']
							}
						},
					},
					{
						name: 'pinned',
						default: false,
						type: 'toggle',
						label: 'Pin to Dashboard',
						hiddenInList: true,
						dataTest: 'pinned',
					},
					// {
					// 	name: 'readOnly',
					// 	default: false,
					// 	class: 'readonly',
					// 	type: 'toggle',
					// 	hiddenInList: true,
					// 	label: 'Prevent Edit'
					// }
				],
			},
		}
	},
	computed: {
		data() {
			if (!this.collection.loaded) {
				this.refresh()
				return this.collection
			}
			const items = {}
			Object.values(this.collection.items).forEach(link => {
				if (!link.parentLinkId && !link.isRoot) items[link.id] = link
			})
			return {
				items,
				loaded: this.collection.loaded,
			}
		},
	},
	methods: {
		delete(item) {
			return new Promise((resolve, reject) => {
				this.$options.dataService
					.delete(item.id, { path: this.$options.pathOverride })
					.then(response => {
						this.$delete(this.collection.items, item.id)
						// ^ delete from the collection rather than data because collection includes everything. e.g. sublinks
						this.onDeleteSuccess && this.onDeleteSuccess(item, response)
						resolve(response)
					})
					.catch(error => {
						this.onDeleteFail && this.onDeleteFail(item, error)
						console.log(error)
						reject(error)
					})
			})
		},
		getAddRequestBody(link) {
			let filteredLinks,
				requestBody = {
					// readOnly: link.editProps.readOnly,
					type: link.editProps.type,
					parentLinkId: link.editProps.parentLinkId,
					pinned: link.editProps.pinned,
					linksOrder: link.editProps.linksOrder,
					title: util.capitalize(link.editProps.title),
				}
			if (link.editProps.type === linkTypes.GROUP) {
				requestBody.url = null
				filteredLinks = Object.values(link.editProps.sublinks.items).filter(sublink => {
					return !!sublink.url.trim()
				})
				filteredLinks.forEach(sublink => {
					sublink.url = util.ensureUrlScheme(sublink.url.trim())
					sublink.csid = sublink.id
					delete sublink.id
				})
				requestBody.sublinks = filteredLinks
			} else {
				requestBody.url = util.ensureUrlScheme(link.editProps.url)
			}
			return requestBody
		},
		getUpdateRequestBody(link) {
			let requestBody = {},
				sublinkChanges
			link.getChanges().forEach(prop => {
				let changedProp = link.ep[prop]
				if (prop === 'url') changedProp = util.ensureUrlScheme(changedProp)
				requestBody[prop] = changedProp
			})
			if (link.type === linkTypes.GROUP || link.editProps.type === linkTypes.GROUP) {
				sublinkChanges = link.getSublinkChanges()
				if (sublinkChanges.length) requestBody.sublinks = sublinkChanges
			}
			return requestBody
		},
	},
}))()
