import Vue from 'vue'
import clockService from '@/services/teamClockDataService'
import ClockModel from '@/models/ClockModel'
import TimezoneTypeahead from '@/components/team/clocks/TimezoneTypeahead'
import { baseManager } from './mixins/itemManagerMixins'

export default new (Vue.extend({
	Model: ClockModel,
	dataService: clockService,
	mixins: [baseManager],
	data() {
		return {
			addInfo: {
				id: 'clocks',
				title: 'Clocks',
				singularItemTitle: 'Clock',
				addTitle: 'What clock would you like to add?',
				description: 'Keep track of the time anywhere on Earth',
				iconName: 'clock',
				props: [
					{
						name: 'id',
						type: 'hidden',
					},
					{
						name: 'timezone',
						type: 'custom',
						component: TimezoneTypeahead,
						label: 'Timezone',
						required: true,
						requiredComponentFields: ['timeZoneId'],
						warning: false,
					},
					{
						name: 'name',
						type: 'input',
						label: 'Name',
						required: true,
						default: '',
						warning: true,
						dataTest: 'name',
					},
					{
						name: 'pinned',
						default: false,
						type: 'toggle',
						label: 'Pin to Dashboard',
						dataTest: 'pinned',
					},
				],
			},
		}
	},
	methods: {
		getAddRequestBody({ editProps }) {
			return {
				pinned: editProps.pinned,
				name: editProps.name,
				timeZoneId: editProps.timezone.timeZoneId,
				timeZoneName: editProps.timezone.timeZoneName,
			}
		},
		getUpdateRequestBody(item) {
			let requestBody = {}
			item.getChanges().forEach(prop => {
				if (prop === 'timezone') {
					requestBody.timeZoneId = item.ep.timezone.timeZoneId
					requestBody.timeZoneName = item.ep.timezone.timeZoneName
				} else {
					requestBody[prop] = item.ep[prop]
				}
			})
			return requestBody
		},
	},
}))()
