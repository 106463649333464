import Vue from 'vue'
import teamDataService from '@/services/teamDataService'
import TeamMemberModel from '@/models/TeamMemberModel'
import eventBus from '@/eventBus'

export default Vue.extend({
	data: function () {
		return {
			teamUuid: null,
			groupUuid: null,
			name: null,
			users: null,
			groupRoles: null,
			internalSubGroups: null,
			groupLoaded: true,
		}
	},
	computed: {
		subGroups: function () {
			if (!this.internalSubGroups) {
				this.internalSubGroups = []
				this.refreshSubGroups()
			}
			return this.internalSubGroups
		},

		groupUsers: function () {
			if (!this.users) {
				this.groupLoaded = false
				this.users = {}
				this.refreshTeamMembers()
			}
			return this.users
		},
	},

	methods: {
		getSubGroupMembership: function (user) {
			if (!this.internalSubGroups) return null
			return (
				this.internalSubGroups.find(subGroup => {
					if (!subGroup.users || subGroup.users.length == 0) return false
					return !!subGroup.users.find(subGroupUser => {
						return subGroupUser.userUuid === user.id
					})
				}) ||
				this.internalSubGroups.find(subGroup => {
					return subGroup.id == this.groupUuid
				})
			)
		},

		getGroupRole: function (user) {
			if (!this.groupUsers.hasOwnProperty(user.id)) return null
			const groupUser = this.groupUsers[user.id]
			return groupUser.roleUuid
		},

		setGroupRole: function (user, roleUuid) {
			teamDataService.changeUserGroupRole(user.id, this.groupUuid, roleUuid)
		},

		updateData: function (group) {
			this.groupUuid = group.groupUuid
			this.groupName = group.groupName
			this.groupRoles = group.groupRoles
		},

		refreshSubGroups: function () {
			// this.internalSubGroups = [
			// 	{ id: this.groupUuid, name: "Member", users: [] },
			// 	{
			// 		id: "app",
			// 		name: "Approver",
			// 		users: ["12a9a811-729a-4dd7-a1fe-710e2262178a"]
			// 	},
			// 	{
			// 		id: "adm",
			// 		name: "Admin",
			// 		users: ["79d6074e-3fa9-41ca-8c89-ab49b50572f7"]
			// 	}
			// ];
			let output = [{ id: this.groupUuid, name: 'Member', users: [] }]
			teamDataService.getSubGroups(this.groupUuid).then(groups => {
				groups.forEach(function (group) {
					let item = {}
					item.id = group.groupUuid
					item.name = group.groupName
					item.users = group.groupUsers || []
					output.push(item)
				})

				this.internalSubGroups = output
			})
		},

		refreshTeamMembers: function () {
			teamDataService.getGroupUsers(this.groupUuid).then(users => {
				this.loadUsers(users)
			})
		},
		loadUsers: function (users) {
			if (!this.users) this.users = {}
			if (users.length < Object.keys(this.users).length) {
				Vue.set(this, 'users', {})
				// TODO: What is the best way to handle deletions so they are reactive
			}
			users.forEach(user => {
				const userUuid = user.id
				if (this.users && this.users.hasOwnProperty(userUuid)) {
					this.users[userUuid].updateData(user)
				} else {
					const model = new TeamMemberModel({ data: user })
					Vue.set(this.users, userUuid, model)
				}
			})
			this.groupLoaded = true
		},
		addUser: function (e, user) {
			if (!user) return
			teamDataService.addGroupUser(user.id, this.groupUuid).then(() => {
				this.refreshTeamMembers(true)
				eventBus.$emit('flashMessage', {
					message: `${user.name} has been added to ${this.groupName}.`,
					icon: 'success',
				})
			})
		},
		removeUser(e, user) {
			if (!user) return
			teamDataService.removeGroupUser(user.id, this.groupUuid).then(() => {
				this.refreshTeamMembers(true)
				eventBus.$emit('flashMessage', {
					message: `${user.name} has been removed from ${this.groupName}.`,
				})
			})
		},
	},
})
