<template>
	<div class="modal login-modal login">
		<nav class="login-nav">
			<span :class="{ active: emailLogin }" class="login-nav-item" @click="changeAction('emailLogin')">Login by email </span><!--
			--><span :class="{ active: resetPassword }" class="login-nav-item" @click="changeAction('resetPassword')">Reset password</span>
		</nav>
		<div ref="modalcontents" class="smooth-height">
			<div v-if="emailLogin" class="description">Enter your email and we’ll send you a login link.</div>
			<div v-if="resetPassword" class="description">Enter your email and new password and we’ll send you a verification email.</div>

			<auth-form
				v-if="emailLogin"
				id="email-link-form"
				:success="!error"
			>
				<input
					id="email"
					key="resetByEmail"
					v-model.trim="info.email"
					v-focus
					type="text"
					name="email"
					placeholder="Email"
				>
				<button
					:disabled="buttonLabel !== $options.onetimeButtonMsg"
					class="button button-primary"
					@click="sendEmailLink($event)"
				><span class="button-label">{{ buttonLabel }}</span>
				</button>
			</auth-form>

			<auth-form
				v-else-if="resetPassword"
				id="login-form"
				:success="!error"
			>
				<input
					id="email"
					key="resetByPw"
					v-model.trim="info.email"
					v-focus
					type="text"
					name="email"
					placeholder="Email"
				>
				<div class="password-input-wrapper">
					<input
						id="password"
						v-model="info.password"
						class="password-input"
						type="password"
						name="password"
						placeholder="Password"
						@keyup="detectFocusedElement($event)"
					>
					<base-svg v-if="capslockOn && focusedElement === 'password'" :icon-name="'capslock'"></base-svg>
				</div>
				<div class="password-input-wrapper">
					<input
						id="verify"
						v-model="info.verify"
						class="password-input"
						type="password"
						name="verify"
						placeholder="Password (to verify)"
						@keyup="detectFocusedElement($event)"
					>
					<base-svg v-if="capslockOn && focusedElement === 'verify'" :icon-name="'capslock'"></base-svg>
				</div>
				<button
					:disabled="buttonLabel !== $options.passwordButtonMsg"
					class="button button-primary"
					@click="checkPassword($event)"
				><span class="button-label">{{ buttonLabel }}</span>
				</button>
			</auth-form>
		</div>

		<div class="below">
			<router-link to="login" class="login-link"><svg class="icon icon-back" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492"><path d="M198.608 246.104L382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z" /></svg> Back to Login</router-link>
		</div>
	</div>
</template>



<script type="text/babel">
import Auth from '@/managers/authManager'
import AuthForm from '@/components/common/AuthForm'
import { copy } from '@/constants'
import capslockState from '@/mixins/capslockMixin'
import BaseSvg from '@/components/svgs/BaseSvg'
import Analytics from '../../Analytics'
const analytics = new Analytics({ feature: 'login' })

export default {
	name: 'Recover',
	passwordButtonMsg: copy.RESET_PASSWORD_BUTTON_LABEL_INITIAL,
	onetimeButtonMsg: copy.ONETIME_LOGIN_BUTTON_LABEL_INITIAL,
	components: {
		AuthForm,
		BaseSvg,
	},
	mixins: [capslockState],
	data() {
		return {
			info: {
				name: '',
				email: '',
				password: '',
				verify: '',
				redirect: '/',
				medium: 'account',
			},
			action: 'emailLogin',
			processing: false,
			focusedElement: '',
		}
	},
	store: ['authInfo'],
	computed: {
		emailLogin() {
			return this.action === 'emailLogin'
		},
		resetPassword() {
			return this.action === 'resetPassword'
		},
		buttonLabel() {
			return this.processing
				? this.resetPassword
					? copy.SETTING
					: copy.SENDING
				: this.resetPassword
					? this.$options.passwordButtonMsg
					: this.$options.onetimeButtonMsg
		},
		error() {
			return this.authInfo.errorMessage
		},
	},
	created() {
		this.info.email = this.$route.query.email
		this.authInfo.resetState()
	},
	destroyed() {
		this.authInfo.resetState()
	},
	methods: {
		changeAction(action) {
			this.authInfo.resetState()
			this.action = action
		},
		checkPassword(e) {
			e.preventDefault()
			this.processing = true
			if (Auth.checkEmailAndPassword(this.info)) {
				Auth.setPassword(this.info).finally(() => {
					this.processing = false
					analytics.capture('password-reset form succeed')
				})
			} else {
				this.processing = false
			}
		},
		sendEmailLink(e) {
			e.preventDefault()
			this.processing = true
			if (Auth.checkEmail(this.info)) {
				Auth.sendEmailLink(this.info).finally(() => {
					this.processing = false
					analytics.capture('login-by-email link send')
				})
			} else {
				this.processing = false
			}
		},
		detectFocusedElement(e) {
			this.detectCapslock(e)
			this.focusedElement = document.activeElement.id
		},
	},
}
</script>
