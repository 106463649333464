import Vue from 'vue'
import { baseDataService } from './mixins/itemDataServiceMixins'

export default new (Vue.extend({
	defaultPath: '/team/users',
	mixins: [baseDataService],
	methods: {
		resendInvite(teamMemberUuid) {
			return new Promise((resolve, reject) => {
				this.$http
					.post(`/team/users/${teamMemberUuid}/resend-invite`)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},
}))()
