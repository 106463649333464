<template>
	<div :class="['form-message', { warning: error }, { success: success }]" :data-test="testAttribute || 'form-message'">
		<span class="form-message-label">
			<span class="form-message-icon">
				<img v-if="error" src="@/img/icons/warning.svg">
				<img v-else-if="success" src="@/img/icons/checked-circle.svg">
			</span>
			<span class="form-message-text">
				<slot>{{ message }}</slot>
				<span v-if="contactUs"> Please try again or <a href="https://momentumdash.com/contact">contact us</a>.</span>
			</span>
		</span>
	</div>
</template>



<script type="text/babel">
export default {
	name: 'FormMessage',
	props: {
		error: { type: Boolean, default: false },
		success: { type: Boolean, default: false },
		message: { type: String, default: '' },
		testAttribute: { type: String, default: '' },
		contactUs: { type: Boolean, default: false },
	},
}
</script>



<style scoped>
	.form-message { width: 100%; margin-top: var(--form-element-margin); position: relative; z-index: 1; display: block; font-size: 0.9375rem; line-height: 1.4; user-select: text; }
		.form-message.success { color: var(--color-form-success); }
		.form-message.warning { color: var(--color-form-warning); }

		.form-message .form-message-label { display: flex; user-select: text;}
			.form-message .form-message-label img { width: 16px; margin-bottom: -2px; margin-right: 7px; }

</style>
