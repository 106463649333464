import Vue from 'vue'
import store from '@/store'

export default Vue.extend({
	data: function () {
		return {
			loaded: false,
			error: null,
			creditCards: [],
		}
	},
	created() {
		this.loaded = store.subscriptionInfo.loaded
	},
	methods: {
		update: function (cards) {
			Vue.set(this, 'creditCards', cards)
			Vue.set(this, 'error', null)
			Vue.set(this, 'loaded', true)
		},
		errorOccured: function (error) {
			Vue.set(this, 'error', error)
			Vue.set(this, 'loaded', false)
		},
	},
})
