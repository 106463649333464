import Vue from 'vue'
import { copy, errorMessages } from '@/constants'
import currentUserDataService from '@/services/currentUserDataService'
import eventBus from '@/eventBus'
import util from '@/util'
import store from '@/store'
import Analytics from '../Analytics'
const analytics = new Analytics({ feature: 'profile' })

export default new (Vue.extend({
	methods: {
		refreshProfile() {
			this.$set(store.userInfo, 'refreshing', true)
			currentUserDataService
				.getUserDetails()
				.then(response => {
					//# Prevent userInfo from being written if user logged out.
					// this to prevent the case where the user lands on upgrade with a different email in the
					// query string than the user currently logged in. If this happens we log them out and set the email
					// to the one in the query. This request to update the profile is in flight and we don't want it to
					// overwrite the new email when it resolves.
					if (store.authInfo.loggedIn) {
						store.userInfo.update(response)
						this.$set(store.userInfo, 'refreshing', false)
					}
				})
				.catch(error => {
					console.log(error)
					this.$set(store.userInfo, 'refreshing', false)
				})
		},

		getProfile() {
			return new Promise((resolve, reject) => {
				this.$set(store.userInfo, 'error', false)
				currentUserDataService
					.getUserDetails()
					.then(response => {
						//# Prevent userInfo from being written if user logged out.
						if (store.authInfo.loggedIn) {
							store.userInfo.update(response)
							analytics.identify(store.userInfo.uuid)
							resolve()
						}
					})
					.catch(() => {
						this.$set(store.userInfo, 'error', true)
						reject()
					})
			})
		},

		updateProfile() {
			return new Promise((resolve, reject) => {
				this.$set(store.userInfo, 'saving', true)
				this.$set(store.userInfo, 'saveError', false)
				currentUserDataService
					.updateProfile(store.userInfo.props())
					.then(response => {
						this.$set(store.userInfo, 'saving', false)
						resolve(response)
					})
					.catch(error => {
						this.$set(store.userInfo, 'saving', false)
						this.$set(store.userInfo, 'saveError', true)
						console.log(error)
						reject(error)
					})
			})
		},
		deleteProfile() {
			return new Promise((resolve, reject) => {
				currentUserDataService
					.deleteProfile()
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						console.log(error)
						reject(error)
					})
			})
		},
		addEmail(newEmail) {
			this.$set(store.userInfo, 'saving', true)
			let checkEmail = util.isEmail(newEmail)
			if (!checkEmail) {
				this.$set(store.userInfo, 'addEmailError', errorMessages.EMAIL_INVALID)
				this.$set(store.userInfo, 'saving', false)
				return
			}
			currentUserDataService
				.addEmail(newEmail)
				.then(() => {
					this.$set(store.userInfo, 'saving', false)
					eventBus.$emit('flashMessage', {
						message: `${newEmail} added.`,
						icon: 'success',
					})
					eventBus.$emit('closeModal', 'addEmailModal')
					this.$set(store.userInfo, 'addEmailError', null)
					analytics.capture('email info change', { action: 'add' })
					this.refreshProfile()
				})
				.catch(error => {
					this.$set(store.userInfo, 'saving', false)
					this.$set(
						store.userInfo,
						'addEmailError',
						error.response && error.response.status === 409
							? errorMessages.EMAIL_ALREADY_REGISTERED
							: errorMessages.SERVER_ERROR_GENERAL
					)
				})
		},
		sendVerification(email) {
			this.$set(email, 'status', copy.SENDING)
			this.$set(email, 'error', null)
			currentUserDataService
				.sendVerification(email.id)
				.then(() => {
					this.$set(email, 'status', null)
					eventBus.$emit('flashMessage', {
						message: `Verification email sent to ${email.email}.`,
					})
					analytics.capture('email info change', { action: 'resend verification' })
				})
				.catch(() => {
					this.$set(email, 'status', null)
					this.$set(email, 'error', errorMessages.SERVER_ERROR_GENERAL)
				})
		},
		setEmailAsDefault(email) {
			this.$set(email, 'status', copy.LOADING)
			this.$set(email, 'error', null)
			currentUserDataService
				.setEmailAsDefault(email.id)
				.then(() => {
					this.$set(email, 'status', null)
					store.userInfo.emails.forEach(em => {
						this.$set(em, 'is_default', false)
					})
					this.$set(email, 'is_default', true)
					eventBus.$emit('flashMessage', {
						message: `${email.email} has been set as your primary email.`,
						icon: 'success',
					})
					analytics.capture('email info change', { action: 'make primary' })
				})
				.catch(() => {
					this.$set(email, 'status', null)
					console.log('set error')
					this.$set(email, 'error', errorMessages.SERVER_ERROR_GENERAL)
				})
		},
		deleteEmail(email) {
			this.$set(email, 'status', 'Removing…')
			this.$set(email, 'error', null)
			currentUserDataService
				.deleteEmail(email.id)
				.then(() => {
					this.$set(email, 'status', null)
					eventBus.$emit('flashMessage', { message: `${email.email} has been removed.` })
					store.userInfo.emails.splice(store.userInfo.emails.indexOf(email), 1)
					analytics.capture('email info change', { action: 'remove' })
				})
				.catch(() => {
					this.$set(email, 'status', null)
					this.$set(email, 'error', errorMessages.SERVER_ERROR_GENERAL)
				})
		},
		hardDelete() {
			return currentUserDataService.hardDelete()
		},
	},
}))()
