<template>
	<iframe ref="scrollbar-watcher" :class="{ debug }" class="scrollbar-watcher"></iframe>
</template>

<script>
import eventBus from '@/eventBus'

export default {
	name: 'ScrollbarWatcher',
	props: {
		watcherId: { type: String, default: '' }, // Required if isOnBody is false and you are listening to the change event through eventBus
		isOnBody: { type: Boolean, default: false }, // Will watch the scrollbar on the body element if true
		parentToWatchId: { type: String, default: '' }, // Required if isOnBody is false
		childToWatchId: { type: String, default: '' }, // Required if isOnBody is false
		debug: { type: Boolean, default: false }, // makes scrollbar watcher visible and enables logging
	},
	data() {
		return {
			scrollbarWidth: 0,
		}
	},
	store: ['interfaceState'],
	computed: {
		id() {
			return this.isOnBody ? 'body' : this.watcherId
		},
		parentToWatch() {
			const element = document.getElementById(this.parentToWatchId)
			if (!element) console.log('No element could be found matching the parentToWatchId')
			return element
		},
		childToWatch() {
			const element = document.getElementById(this.childToWatchId)
			if (!element) console.log('No element could be found matching the childToWatchId')
			return element
		},
	},
	watch: {
		scrollbarWidth(newVal) {
			this.$emit('scrollbarChanged', newVal)
			eventBus.$emit(`scrollbarChanged:${this.id}`, newVal)
			if (this.isOnBody) this.$set(this.interfaceState, 'scrollbarWidth', newVal)
			if (this.debug) console.log('scrollbarWidthChanged', this.id, this.scrollbarWidth)
		},
	},
	created() {
		if (!this.isOnBody && !this.parentToWatchId) {
			throw 'Warning: missing prop "parentToWatchId" the scrollbar watcher reqires the id of the element that contains the scrollbar.'
		}
		if (!this.isOnBody && !this.childToWatchId) {
			throw 'Warning: missing prop "childToWatchId" the scrollbar watcher reqires the id of the element that changes width when the scrollbar appears.'
		}
	},
	mounted() {
		this.$refs['scrollbar-watcher'].contentWindow.addEventListener('resize', this.handleIFrameResize)
		this.handleIFrameResize()
	},
	methods: {
		handleIFrameResize() {
			if (this.isOnBody) {
				this.scrollbarWidth = window.innerWidth - document.body.clientWidth
			} else {
				this.scrollbarWidth = this.parentToWatch.offsetWidth - this.childToWatch.clientWidth
			}
		},
	},
}
</script>

<style scoped>
	.scrollbar-watcher { height: 0; width: 100%; position: absolute; top: 0; border: none; }
		.scrollbar-watcher.debug { height: 2px; background-color: red; }
</style>
