import axios from 'axios'
import i18n from '@/i18n'
import store from '@/store'

// supportedLanguages array below should be updated once a new language is added
const supportedLanguages = ['en', 'zh']

function setI18nLanguage(lang) {
	i18n.locale = lang
	store.langauge = lang
	window.localStorage.setItem('language', lang)
	axios.defaults.headers.common['Accept-Language'] = lang
	document.querySelector('html').setAttribute('lang', lang)
	return lang
}

// function extractInputLanguage(lang) {
// 	if (typeof lang === 'string') {
// 		return lang.split('-')[0]
// 	} else {
// 		// get an array of unique language codes: e.g. if lang = ["en", "en-CA", "zh"], the output will be ["en", "zh"]
// 		const uniqueLangCodes = [...new Set(lang.map(l => l.split('-')[0]))]
// 		if (uniqueLangCodes.length === 1) return uniqueLangCodes[0]
// 		else return supportedLanguages.find(l => ~uniqueLangCodes.indexOf(l))
// 	}
// }

export default {
	loadLanguageAsync: function (/*lang*/) {
		// if (store.userInfo.isTeamMember) {
		return Promise.resolve(setI18nLanguage(i18n.fallbackLocale))
		// }
		// let extractedLang = extractInputLanguage(lang)
		// // if the same language as current locale or is one of the supported languages
		// if (extractedLang && (i18n.locale === extractedLang || supportedLanguages.includes(extractedLang))) return Promise.resolve(setI18nLanguage(extractedLang))
		// // if the language isn't supported yet, reject
		// return Promise.reject(new Error(`Client browser's language is not yet supported by Momentum. Falling back to default language: English.`))
	},
	supportedLanguages: supportedLanguages,
}
