<template>
	<div :class="{ 'beside-form-label': besideFormLabel, 'u--no-margin-bottom': noMarginBottom, 'align-baseline': alignAtBaseline }" class="flex-wrapper">
		<slot name="input"><!-- Slot for button, input, select, toggle, etc. That you would like to tooltip to appear beside --></slot>
		<div v-if="$slots['tip-text']" ref="tooltip-wrapper" class="tooltip-wrapper">
			<svg class="tooltip-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" @mouseenter="mouseEnter" @mouseleave="mouseLeave"><path d="M22 0C9.8 0 0 9.8 0 22s9.8 22 22 22 22-9.8 22-22S34.2 0 22 0zm2 34c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v2zm2.7-8.9c-1.4 1.2-2.4 2-2.7 3.1-.1.5-.5.8-1 .8h-2c-.6 0-1.1-.5-1-1.1.4-2.9 2.5-4.5 4.2-5.9 1.8-1.4 2.8-2.3 2.8-4 0-2.8-2.2-5-5-5s-5 2.2-5 5v.6c.1.5-.2 1-.7 1.1l-1.9.6c-.6.2-1.2-.2-1.3-.8-.1-.5-.1-1-.1-1.5 0-5 4-9 9-9s9 4 9 9c0 3.7-2.4 5.6-4.3 7.1z" /></svg>
			<transition name="fade" duration="75">
				<div v-if="open" :style="{'max-width': tooltipWidth + 'px' }" :class="[computedPosition, { 'open-to-left': openToLeft }]" class="tooltip">
					<slot name="tip-text"></slot>
					<div class="notch"></div>
				</div>
			</transition>
		</div>
	</div>
</template>


<script>
import { positionEnum } from '@/constants'

export default {
	name: 'Tooltip',
	hoverDurationToActivate: 200,
	defaultMaxWidth: 300,
	minWidth: 150,
	props: {
		besideFormLabel: { type: Boolean, default: false }, // Makes the tooltip icon take up less space. (good for beside form labels)
		position: { type: String, default: positionEnum.BOTTOM },
		noMarginBottom: { type: Boolean, default: false }, // Define no margin bottom for tool tip "input"
		alignAtBaseline: { type: Boolean, default: false },
	},
	data() {
		return {
			open: false,
			tooltipWidth: this.$options.defaultMaxWidth,
			positionOverride: null,
			openToLeft: false,
		}
	},
	computed: {
		computedPosition() {
			return this.positionOverride || this.position
		},
	},
	methods: {
		mouseEnter() {
			this.hoverTimeout = setTimeout(() => {
				/*
				 * If an element outside this component is given a .tooltip-container class, the tooltip will try not to overflow that element
				 *
				 * Tooltips will always render to the right side by default unless position is positionEnum.LEFT.
				 * ( positionEnum.TOP and positionEnum.BOTTOM will display on the top or bottom, but the text will flow to the right by default )
				 * if there is not enough space to fit the tooltip's defaultMaxWidth on the preferred side, the tooltip's width will be constrained.
				 * if there is not enough space to fit the tooltip's minWidth on the preferred side, and there is more room on the other side,
				 * the tooltip will be displayed on the opposite side.
				 */

				const wrapper = this.$refs['tooltip-wrapper'],
					container = wrapper.closest('.tooltip-container')
				if (container) {
					const wrapperRect = wrapper.getBoundingClientRect(),
						containerRect = container.getBoundingClientRect(),
						wrapperOffsetLeftFromContainer = wrapperRect.left - containerRect.left,
						wrapperOffsetRightFromContainer = containerRect.right - wrapperRect.right,
						preferredSideOffset =
							this.position === positionEnum.LEFT
								? wrapperOffsetLeftFromContainer
								: wrapperOffsetRightFromContainer,
						unPreferredSideOffset =
							this.position === positionEnum.LEFT
								? wrapperOffsetRightFromContainer
								: wrapperOffsetLeftFromContainer,
						shouldConstrainWidth = this.$options.defaultMaxWidth > preferredSideOffset,
						shouldFlipSides =
							this.$options.minWidth > preferredSideOffset && unPreferredSideOffset > preferredSideOffset

					if (shouldConstrainWidth && !shouldFlipSides) {
						this.tooltipWidth = preferredSideOffset
					} else if (shouldFlipSides) {
						this.tooltipWidth = Math.min(unPreferredSideOffset, this.$options.defaultMaxWidth)
						if (this.position === positionEnum.RIGHT) {
							this.positionOverride = positionEnum.LEFT
						} else {
							this.openToLeft = true
						}
					}
				}
				this.open = true
			}, this.$options.hoverDurationToActivate)
		},
		mouseLeave() {
			if (this.hoverTimeout) clearTimeout(this.hoverTimeout)
			this.open = false
			this.positionOverride = null
			this.openToLeft = false
			this.tooltipWidth = this.$options.defaultMaxWidth
		},
	},
}
</script>


<style scoped>
	.flex-wrapper { display: flex; align-items: center; margin-bottom: var(--form-element-margin-small); }
		.flex-wrapper.align-baseline { align-items: baseline; }
		.flex-wrapper.beside-form-label { margin-bottom: var(--form-label-bottom-margin); }
			.flex-wrapper.beside-form-label > label { margin-bottom: 0; }

	.tooltip-wrapper { --notch-width: 10px; --notch-position: 14px; --tooltip-bg: hsl(var(--color-hue), 12%, 55%); position: relative; }

		.tooltip-icon { height: 16px; margin: -10px auto -10px 0; padding: 10px; display: block; opacity: 0.35; box-sizing: content-box; fill: var(--color-label); cursor: default; }

		.tooltip { width: max-content; padding: 10px 14px; position: absolute; top: calc(100% + 12px); left: 0; z-index: 10; background-color: var(--tooltip-bg); border-radius: var(--border-radius); box-shadow: 0 3px 9px rgba(0,0,0,0.14); color: #fff; font-size: 0.8125rem; line-height: 1.3; pointer-events: none; }
			.tooltip .notch { height: var(--notch-width); width: var(--notch-width); position: absolute; top: -3px; left: var(--notch-position); display: inline-block; transform: rotate(45deg); background: var(--tooltip-bg); content: ''; }

			.tooltip.open-to-left { left: auto; }

			.tooltip.position-right { top: -50%; left: 100%; }
				.tooltip.position-right .notch { top: 11px; left: -4px; }
			.tooltip.position-left { top: -50%; right: 100%; left: auto; }
				.tooltip.position-left .notch { top: 11px; right: -4px; left: auto; }
			.tooltip.position-top { top: auto; bottom: calc(100% + 12px); }
				.tooltip.position-top .notch { top: auto; bottom: -3px; left: var(--notch-position); }

		.open-to-left { right: 0; left: auto; }
			.open-to-left .notch { right: var(--notch-position); left: auto; }

</style>
