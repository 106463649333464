<template>
	<div class="hovernote-wrapper">
		<div @mouseenter="mouseEnter" @mouseleave="mouseLeave">
			<slot name="default"></slot>
		</div>

		<transition name="fade" duration="75">
			<div v-if="open" :class="$props.position" class="hovernote">
				<div class="note">
					<slot name="note"></slot>
				</div>
				<div :style="{ '--notch-offset': $props.notchOffset + 'px' }" class="notch"></div>
			</div>
		</transition>
	</div>
</template>


<script>
export default {
	name: 'HoverNote',
	props: {
		position: { type: String, default: '' },
		notchOffset: { type: Number, default: 14 },
	},
	data() {
		return {
			open: false,
		}
	},
	methods: {
		mouseEnter() {
			this.open = true
		},
		mouseLeave() {
			this.open = false
		},
	},
}
</script>


<style scoped>
.hovernote-wrapper { position: relative; }

	.hovernote { --bgcolor: hsl(var(--color-hue), 12%, 55%); width: max-content; max-width: 400px; padding: 8px 10px; position: absolute; top: calc(100% + 6px); left: 0; z-index: 10; background-color: var(--bgcolor); border-radius: var(--border-radius); color: #fff; font-size: 0.8125rem; }

		.hovernote, >>> .note p { line-height: 1.3; text-align: left; }

		>>> .note p { margin: 0.5em 0; }
			>>> .note p:first-child { margin-top: 0; }
			>>> .note p:last-child { margin-bottom: 0; }

		.notch { --width: 10px; height: var(--width); width: var(--width); position: absolute; top: -3px; left: var(--notch-offset); display: inline-block; transform: rotate(45deg); background: var(--bgcolor); content: ''; }

		.hovernote.above { top: auto; bottom: calc(100% + 6px); }
			.hovernote.above .notch { top: auto; bottom: -3px; }
</style>
