<template>
	<div class="content section-loading">
		<i class="loading-icon"></i>
	</div>
</template>

<script>
export default {
	name: 'RouteLoader',
}
</script>

<style>
</style>


