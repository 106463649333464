<template>
	<div ref="flair" class="flair">
		<hover-note :position="$props.notePosition" :notch-offset="107">
			<div class="stripe">
				Powered by <svg class="stripe-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468 222.5"><path d="M414 113.4c0-25.6-12.4-45.8-36.1-45.8-23.8 0-38.2 20.2-38.2 45.6 0 30.1 17 45.3 41.4 45.3 11.9 0 20.9-2.7 27.7-6.5v-20c-6.8 3.4-14.6 5.5-24.5 5.5-9.7 0-18.3-3.4-19.4-15.2h48.9c0-1.3.2-6.5.2-8.9zm-49.4-9.5c0-11.3 6.9-16 13.2-16 6.1 0 12.6 4.7 12.6 16h-25.8zM301.1 67.6c-9.8 0-16.1 4.6-19.6 7.8l-1.3-6.2h-22v116.6l25-5.3.1-28.3c3.6 2.6 8.9 6.3 17.7 6.3 17.9 0 34.2-14.4 34.2-46.1-.1-29-16.6-44.8-34.1-44.8zm-6 68.9c-5.9 0-9.4-2.1-11.8-4.7l-.1-37.1c2.6-2.9 6.2-4.9 11.9-4.9 9.1 0 15.4 10.2 15.4 23.3 0 13.4-6.2 23.4-15.4 23.4zM223.8 61.7l25.1-5.4V36l-25.1 5.3zM223.8 69.3h25.1v87.5h-25.1zM196.9 76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7 15.9-6.3 19-5.2v-23c-3.2-1.2-14.9-3.4-20.8 7.4zM146.9 47.6l-24.4 5.2-.1 80.1c0 14.8 11.1 25.7 25.9 25.7 8.2 0 14.2-1.5 17.5-3.3V135c-3.2 1.3-19 5.9-19-8.9V90.6h19V69.3h-19l.1-21.7zM79.3 94.7c0-3.9 3.2-5.4 8.5-5.4 7.6 0 17.2 2.3 24.8 6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6C67.5 67.6 54 78.2 54 95.9c0 27.6 38 23.2 38 35.1 0 4.6-4 6.1-9.6 6.1-8.3 0-18.9-3.4-27.3-8v23.8c9.3 4 18.7 5.7 27.3 5.7 20.8 0 35.1-10.3 35.1-28.2-.1-29.8-38.2-24.5-38.2-35.7z" /></svg>

				<svg class="stripe-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"><path d="M165 0C74.019 0 0 74.02 0 165.001 0 255.982 74.019 330 165 330s165-74.018 165-164.999S255.981 0 165 0zm0 300c-74.44 0-135-60.56-135-134.999S90.56 30 165 30s135 60.562 135 135.001C300 239.44 239.439 300 165 300z" /><path d="M164.998 70c-11.026 0-19.996 8.976-19.996 20.009 0 11.023 8.97 19.991 19.996 19.991 11.026 0 19.996-8.968 19.996-19.991 0-11.033-8.97-20.009-19.996-20.009zM165 140c-8.284 0-15 6.716-15 15v90c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15v-90c0-8.284-6.716-15-15-15z" /></svg>
			</div>

			<template #note>
				<p>We use Stripe for payment processing. Stripe exceeds the most stringent industry standards for security.</p>
				<p>We do not store your credit card information. All connections to our servers are secured with a 256 bit GeoTrust SSL certificate. Your security is of utmost importance to us.</p>
			</template>
		</hover-note>

		<div class="recaptcha-disclaimer">Protected by Recaptcha <a href="https://policies.google.com/privacy" target="_blank">Privacy</a><a href="https://policies.google.com/terms" target="_blank">Terms</a></div>
	</div>
</template>


<script>
import HoverNote from '@/components/common/HoverNote'

export default {
	name: 'UpgradeFlair',
	components: { HoverNote },
	props: {
		notePosition: { type: String, default: '' },
	},
}
</script>


<style scoped>
.flair { --color: #777; padding-top: 1em; display: flex; align-items: center; justify-content: space-between; position: relative; color: var(--color); font-size: 0.6875rem; }

	.flair a { padding: 4px 2px; opacity: 0.8; color: var(--color); }

	.stripe { margin: -4px; padding: 4px; position: relative; display: flex; align-items: center; }
		.stripe-logo { height: 21px; margin-right: 1px; position: relative; top: 1px; fill: var(--color); }
		.stripe-info { height: 13px; position: relative; top: 0px; opacity: 0.75; fill: var(--color); }
			.stripe:hover .stripe-info { opacity: 1; }

	.recaptcha-disclaimer { text-align: right; }
		.recaptcha-disclaimer a:last-of-type { margin-right: -2px; }
</style>
