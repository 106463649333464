<template>
	<div class="content section-loading">
		<div>We weren't able to load the page.</div>
		<span class="button active" @click="reloadPage()">Retry</span>
	</div>
</template>

<script>
import eventBus from '@/eventBus'

export default {
	name: 'Reconnect',
	methods: {
		reloadPage() {
			eventBus.$emit('reloadPage')
		},
	},
}
</script>

<style>
</style>
