import Vue from 'vue'
// TODO: Refactor this once we can actually access it

export default new (Vue.extend({
	methods: {
		getApiKeys() {
			return new Promise((resolve, reject) => {
				this.$http
					.get('team/api/identities')
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		addApiKey(apiKey) {
			let toSend = {}
			toSend.description = apiKey.editProps.description
			return new Promise((resolve, reject) => {
				this.$http
					.post('team/api/identities', toSend)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		updateApiKey(apiKey) {
			let updateInfo = {}
			apiKey.getChanges().forEach(prop => {
				updateInfo[prop] = apiKey.editProps[prop]
			})
			return new Promise((resolve, reject) => {
				this.$http
					.patch('team/api/identities/' + apiKey.id, updateInfo)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		deleteApiKey(apiKeyId) {
			return this.$http.delete('team/api/identities/' + apiKeyId)
		},
	},
}))()
