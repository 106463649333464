class StorageHelper {
	constructor(query) {
		this.query = query
		this.processedParams = []
	}

	storeInSessionStorage(queryParamName, sessionStorageItemName) {
		this.storeQuery(window.sessionStorage, queryParamName, sessionStorageItemName)
		return this
	}

	storeInLocalStorage(queryParamName, localStorageItemName) {
		this.storeQuery(window.localStorage, queryParamName, localStorageItemName)
		return this
	}

	storeQuery(storage, queryParamName, storageItemName) {
		if (this.query.hasOwnProperty(queryParamName)) {
			storageItemName = storageItemName || queryParamName
			storage.setItem(storageItemName, this.query[queryParamName])
			this.processedParams.push(queryParamName)
		}
	}

	updatedQueryParams() {
		const newQuery = Object.assign({}, this.query)
		if (this.processedParams.every(param => typeof newQuery[param] === 'undefined')) return null
		// ^ if running this results in no change to the query params, vue will throw a warning,
		// so don't do anything if there is nothing to clear
		this.processedParams.forEach(param => delete newQuery[param])
		return newQuery
	}
}

export default StorageHelper
