import Vue from 'vue'
import store from '@/store'
import PaymentInfoModel from '@/models/PaymentInfoModel'
import paymentDataService from '@/services/paymentDataService'

export default new (Vue.extend({
	created() {
		store.paymentInfo = this.paymentInfo = new PaymentInfoModel()
	},
	methods: {
		getCards(success, failure) {
			paymentDataService
				.getCards()
				.then(cards => {
					this.paymentInfo.update(cards)
					if (success) success()
				})
				.catch(() => {
					Vue.set(store.paymentInfo, 'error', true)
					this.paymentInfo.errorOccured(true)
					if (failure) failure()
				})
		},
		addCard(tokenRequestResult, recaptchaToken, chargeImmediately) {
			return new Promise((resolve, reject) => {
				if (!tokenRequestResult || tokenRequestResult.error) {
					reject()
					return
				}
				paymentDataService
					.addCard(tokenRequestResult, recaptchaToken, chargeImmediately)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		setCardAsDefault(cardId) {
			return new Promise((resolve, reject) => {
				paymentDataService
					.setCardAsDefault(cardId)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		removeCard(cardId) {
			return new Promise((resolve, reject) => {
				paymentDataService
					.removeCard(cardId)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},
}))()
