import Vue from 'vue'

import { Collection } from '@/models/CollectionModel'
import eventBus from '@/eventBus'
import store from '../store'

import GroupModel from '@/models/GroupModel'
import TodoIntegrationModel from '@/models/TodoIntegrationModel'
import ApiKeyModel from '@/models/ApiKeyModel'
import WebhookModel from '@/models/WebhookModel'

import teamDataService from '@/services/teamDataService'
import todoDataService from '@/services/teamTodoDataService'
import apiKeyDataService from '@/services/teamApiKeyDataService'
import webhookDataService from '@/services/teamWebhookDataService'

import webhookManager from '@/managers/teamWebhookManager'
import teamMemberManager from '@/managers/teamMemberManager'
import quoteManager from '@/managers/teamQuoteManager'
import teamAppCategoryManager from '@/managers/teamAppCategoryManager'
import teamTopicCategoryManager from '@/managers/teamTopicCategoryManager'
import teamLinkManager from '@/managers/teamLinkManager'
import teamCountdownManager from '@/managers/teamCountdownManager'
import teamClockManager from '@/managers/teamClockManager'
import teamMetricManager from '@/managers/teamMetricManager'
import teamFocusManager from '@/managers/teamFocusManager'
import teamAnnouncementManager from '@/managers/teamAnnouncementManager'
import subscriptionManager from '../managers/subscriptionManager'
import util from '../util'
import { TeamTypes } from '../util'

function initialData() {
	return {
		features: [],
		subscriptionSummary: {
			pastDue: null,
			active: null,
			error: null,
			trialEnd: null,
			subscriptionEnd: null,
			hasPaymentMethod: null,
			trialing: null,
		},
		paymentWaived: null,
		teamLogoUrl: null,
		teamName: null,
		teamRoles: null,
		teamUuid: null,
		type: 1,
		groups: null,
		apiKeys: new Collection(),
		webhooks: new Collection(),
		icons: new Collection(),
		todoIntegrations: new Collection(),
		availableTodoIntegrations: new Collection(),
		error: false,
		onboardingComplete: false,
	}
}

export default Vue.extend({
	teamProperties: [
		'subscriptionSummary',
		'teamLogoUrl',
		'teamName',
		'teamRoles',
		'teamUuid',
		'subscriptionSummary',
		'paymentWaived',
		'type',
	],
	data: function () {
		return initialData()
	},
	computed: {
		trialActive() {
			return this.subscriptionSummary.trialing
		},
		trialExpired() {
			// false if the team has or had a subscription
			let sub = this.subscriptionSummary
			return !sub.active && !sub.trialing && !sub.hasPaymentMethod
		},
		trialDaysRemaining() {
			return util.daysRemaining(this.subscriptionSummary.trialEnd)
		},
		subscriptionActive() {
			// Distinct from the trial period. Only true for paying teams or teams with payment waived
			let sub = this.subscriptionSummary
			return sub.active && !sub.trialing
		},
		subscriptionExpired() {
			// Distinct from trial expired. Only true if the team has cancelled or stripe has cancelled the subscription due to lack of payments
			let sub = this.subscriptionSummary
			return !sub.active && sub.subscriptionEnd && new Date(sub.subscriptionEnd) < new Date()
		},
		subscriptionDaysRemaining() {
			return util.daysRemaining(this.subscriptionSummary.subscriptionEnd)
		},
		pastDue() {
			return this.subscriptionSummary.pastDue
		},
		disableFeatures() {
			// Covers all potential cases where we block functionality, even though past due is technically not expired.
			return this.trialExpired || this.subscriptionExpired || this.pastDue
		},
		teamGroups() {
			if (!this.groups) {
				this.groups = {}
				this.refreshGroups()
			}
			return this.groups
		},
		teamMembers() {
			return teamMemberManager.data
		},
		quotes() {
			return quoteManager.data
		},
		topics() {
			return teamTopicCategoryManager.data
		},
		apps() {
			return teamAppCategoryManager.data
		},
		links() {
			return teamLinkManager.data
		},
		allLinks() {
			// Includes sublinks and top level links
			return teamLinkManager.collection
		},
		countdowns() {
			return teamCountdownManager.data
		},
		clocks() {
			return teamClockManager.data
		},
		focuses() {
			return teamFocusManager.data
		},
		announcments() {
			return teamAnnouncementManager.data
		},
		teamApiKeys() {
			if (!this.apiKeys.loaded) {
				this.refreshApiKeys()
			}
			return this.apiKeys
		},
		teamWebhooks() {
			if (!this.webhooks.loaded) {
				this.refreshWebhooks()
			}
			return this.webhooks
		},
		teamMetrics() {
			return teamMetricManager.data
		},
		teamIcons() {
			if (!this.icons.loaded) {
				this.refreshIcons()
			}
			return this.icons
		},
		teamTodoIntegrations() {
			if (!this.todoIntegrations.loaded) {
				this.refreshTodoIntegrations()
			}
			return this.todoIntegrations
		},
		teamAvailableTodoIntegrations() {
			if (!this.availableTodoIntegrations.loaded) {
				this.refreshAvailableTodoIntegrations()
			}
			return this.availableTodoIntegrations
		},
		teamLoaded() {
			let self = this
			return (
				this.groups &&
				Object.keys(self.groups).every(key => {
					return self.groups[key].groupLoaded
				})
			)
		},
		teamNotifications() {
			if (!this.notifications.loaded) {
				this.refreshNotifications()
			}
			return this.notifications
		},
		isCommunity() {
			return this.type === TeamTypes.Community
		},
		teamType() {
			return this.type === TeamTypes.Community ? 'Community' : 'Team'
		},
	},
	watch: {
		subscriptionExpired(value) {
			// Pre loads subscriptions so that expired modal can load instantly
			if (value && !store.subscriptionInfo.loaded) subscriptionManager.getSubscriptions()
		},
		pastDue(value) {
			// Pre loads subscriptions so that expired modal can load instantly
			if (value && !store.subscriptionInfo.loaded) subscriptionManager.getSubscriptions()
		},
	},
	created() {
		this.updateTeamFromCache()
	},
	methods: {
		refreshGroups() {
			teamDataService
				.getTeamGroups(true)
				.then(groups => {
					this.loadGroups(groups)
				})
				.catch(error => {
					console.log(error)
					Vue.set(this, 'error', true)
				})
		},
		loadGroups: function (groups) {
			if (!groups) return
			if (!this.groups) this.groups = {}
			if (groups.length < Object.keys(this.groups).length) {
				let groupIds = []
				groups.forEach(group => {
					groupIds.push(group.groupUuid)
				})
				Object.keys(this.groups).forEach(key => {
					if (groupIds.indexOf(key) === -1) Vue.delete(this.groups, key)
				}) // Handles Deletion
			}
			groups
				.sort((a, b) => {
					if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) return 1
					if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) return -1
					return 0
				})
				.forEach(group => {
					const groupUuid = group.groupUuid
					if (this.groups.hasOwnProperty(groupUuid)) {
						this.groups[groupUuid].updateData(group)
					} else {
						const users = group.groupUsers
						delete group.groupUsers
						const model = new GroupModel({ data: group })
						model.loadUsers(users)
						model.teamUuid = this.teamUuid
						Vue.set(this.groups, groupUuid, model)
					}
				})
		},
		useIcons(data) {
			data.forEach(icon => {
				icon.uppercaseLabel = (icon.label && icon.label.toUpperCase()) || ''
				this.$set(this.icons.items, icon.id, icon)
			})
		},
		refreshIcons() {
			let iconData = localStorage.getObject('team-icons')
			if (iconData) this.useIcons(iconData)
			teamDataService.getTeamIcons().then(data => {
				if (!data) return
				localStorage.setObject('team-icons', data)
				this.useIcons(data)
			})
		},
		refreshApiKeys() {
			apiKeyDataService.getApiKeys().then(apiKeys => {
				apiKeys.forEach(apiKey => {
					const id = apiKey.id
					let model = null
					if (this.apiKeys.hasOwnProperty(id)) {
						model = this.apiKeys[id]
					} else {
						model = new ApiKeyModel()
						model.teamUuid = this.teamUuid
						Vue.set(this.apiKeys.items, id, model)
					}
					model.updateData(apiKey)
				})
				if (this.apiKeys.items.hasOwnProperty('placeholder')) {
					delete this.apiKeys.items.placeholder
				}
				Vue.set(this.apiKeys, 'loaded', true)
			})
		},
		refreshWebhooks() {
			webhookDataService.getWebhooks().then(response => {
				let typeMap = {}
				response.types.forEach(type => {
					typeMap[type.id] = type
				})
				response.subscriptions.forEach(webhook => {
					const webhookId = webhook.id
					let model = null
					webhook.type = typeMap[webhook.webhookTypeId]
					if (this.webhooks.hasOwnProperty(webhookId)) {
						model = this.webhooks[webhookId]
					} else {
						model = new WebhookModel()
						model.teamUuid = this.teamUuid
						Vue.set(this.webhooks.items, webhookId, model)
					}
					model.updateData(webhook)
				})
				if (this.webhooks.items.hasOwnProperty('placeholder')) {
					delete this.webhooks.items.placeholder
				}
				webhookManager.webhookTypes = response.types.map(type => ({
					label: type.label,
					value: type.id,
				}))
				this.$set(this.webhooks, 'loaded', true)
			})
		},
		refreshTodoIntegrations() {
			todoDataService.getConnectedTodoIntegrations().then(integrations => {
				integrations.forEach(integration => {
					const todoIntegrationId = integration.id
					let model = null
					if (this.todoIntegrations.items.hasOwnProperty(todoIntegrationId)) {
						model = this.todoIntegrations.items[todoIntegrationId]
					} else {
						model = new TodoIntegrationModel()
						model.teamUuid = this.teamUuid
						Vue.set(this.todoIntegrations.items, todoIntegrationId, model)
					}
					model.updateData(integration)
				})
				Vue.set(this.todoIntegrations, 'loaded', true)
			})
		},
		refreshAvailableTodoIntegrations() {
			todoDataService.getAvailableTodoIntegrations().then(integrations => {
				integrations.forEach(integration => {
					let todoIntegrationId = integration.id
					let model = null
					if (this.availableTodoIntegrations.items.hasOwnProperty(todoIntegrationId)) {
						model = this.availableTodoIntegrations.items[todoIntegrationId]
					} else {
						model = new TodoIntegrationModel()
						model.teamUuid = this.teamUuid
						Vue.set(this.availableTodoIntegrations.items, todoIntegrationId, model)
					}
					model.updateData(integration)
				})
			})
		},
		addGroup(groupName, parentGroupUuid) {
			teamDataService.addGroup(groupName, parentGroupUuid).then(() => {
				this.refreshGroups(true)
				eventBus.$emit('flashMessage', {
					message: `${groupName} has been created.`,
					icon: 'success',
				})
			})
		},
		removeGroup(group) {
			teamDataService.removeGroup(group.groupUuid).then(() => {
				this.refreshGroups(true)
				eventBus.$emit('flashMessage', { message: `${group.groupName} has been deleted.` })
			})
		},
		updateTeamFromCache() {
			if (!store.userInfo.isTeamMember) {
				// Case where user has been detached from a team, clear cached team data
				localStorage.removeItem('activeTeam')
			} else {
				const data = localStorage.getObject('activeTeam')
				if (data) this.updateTeam(data, false)
			}
		},
		updateTeam(data, cacheData = true) {
			if (cacheData) localStorage.setObject('activeTeam', data)
			this.$options.teamProperties.forEach(prop => (this[prop] = data[prop]))
			if (data.features) this.features = JSON.parse(atob(data.features)) // if (data.features) Prevents error when populating the team info from onboarding (where features are not present)
		},
		hasFeature: function (feature) {
			if (!this.features) return false
			return this.features.indexOf(feature) >= 0
		},
		resetTeamData() {
			let initialTeamData = initialData()
			Object.keys(initialTeamData).forEach(key => this.$set(this, key, initialTeamData[key]))
			eventBus.$emit('resetCollections')
			localStorage.removeItem('activeTeam')
		},
	},
})
