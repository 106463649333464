<template>
	<div class="v-typeahead" :class="{ 'searching': resultsVisible }">
		<input
			:inline-modal-focus="true"
			:value="value"
			type="text"
			:placeholder="placeholder"
			spellcheck="false"
			autocomplete="off"
			:data-test="type + '-input'"
			@input="$emit('input', $event.target.value)"
			@focus="$event.target.select()"
		>
		<ul v-if="resultsVisible" data-test="typeahead-dropdown">
			<li v-show="loading" class="searching">Searching...</li>
			<li
				v-for="(result, index) in results"
				:key="index"
				:data-test="type + '-result'"
				@click="$emit('select', result)"
			>
				<span class="name">{{ result.name }}</span>
			</li>
			<li v-show="noResults" data-test="not-found" class="no-results">No {{ type }}s found.</li>
		</ul>
	</div>
</template>



<script>
export default {
	name: 'Typeahead',
	props: {
		value: { type: String, default: '' },
		type: { type: String, default: '' },
		results: { type: Array, default: () => [] },
		loading: { type: Boolean, default: false },
		placeholder: { type: String, required: true },
		noResults: { type: Boolean, default: false },
	},
	computed: {
		resultsVisible() {
			return this.results.length || this.loading || this.noResults
		},
	},
}
</script>


<style scoped>
.v-typeahead { background-color: transparent; }
	.searching input { border: 1px solid hsl(0, 0%, 50%); border-bottom: 0; border-bottom-left-radius: 0; border-bottom-right-radius: 0; box-shadow: none; }
	.v-typeahead ul { padding: 6px 0; margin: auto; max-height: 300px; padding: 2px 0 11px; background: var(--input-bg-color-focus); border: 1px solid hsl(0, 0%, 50%); border-top: none; border-bottom-left-radius: var(--border-radius); border-bottom-right-radius: var(--border-radius); box-shadow: 0 10px 25px rgba(0,0,0,0.1); overflow: auto; }
		.v-typeahead li, .v-typeahead .no-results { max-width: none; padding: 4px 9px; opacity: 0.8; cursor: pointer; font-size: 1rem; }
			.v-typeahead li:hover { background: hsl(var(--color-hue), 6%, 93%); }
			.v-typeahead .no-results { opacity: 0.6; }
</style>
