import Vue from 'vue'
import { errorMessages } from '@/constants'
import eventBus from '@/eventBus'

export default new (Vue.extend({
	methods: {
		getTeamsForUser(complete) {
			return new Promise(resolve => {
				this.$http
					.get('/team' + (complete ? '?complete=true' : ''))
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						eventBus.$emit('flashMessage', {
							message: errorMessages.SERVER_ERROR_GENERAL,
							icon: 'error',
						})
						console.error(error)
						resolve([])
					})
			})
		},
		getBasicTeamInfo(shortName) {
			return new Promise((resolve, reject) => {
				this.$http
					.get('/team/basicInfo/' + shortName)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						eventBus.$emit('flashMessage', {
							message: error.response.status
								? errorMessages.COMMUNITY_NOT_FOUND
								: errorMessages.SERVER_ERROR_GENERAL,
							icon: 'error',
						})
						reject(error)
					})
			})
		},

		getTeamGroups(includeUsers) {
			return new Promise((resolve, reject) => {
				this.$http
					.get('/team/groups' + (includeUsers ? '?users=true' : ''))
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},

		getSubGroups(parentGroupUuid) {
			let options = { params: { parent: parentGroupUuid, users: true } }
			return new Promise((resolve, reject) => {
				this.$http
					.get('/team/groups', options)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},

		addGroupUser(userUuid, groupUuid) {
			return new Promise((resolve, reject) => {
				let data = {
					userUuid: userUuid,
				}
				this.$http
					.post('/team/groups/' + groupUuid + '/users', data)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},

		getGroupUsers(groupId) {
			return new Promise((resolve, reject) => {
				this.$http
					.get('/team/groups/' + groupId + '/users')
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},

		addGroup(groupName, parentGroupUuid) {
			return new Promise((resolve, reject) => {
				let data = {
					groupName: groupName,
					parentGroupUuid: parentGroupUuid,
				}
				this.$http
					.post('/team/groups', data)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},

		editTeam(teamUuid, updateInfo) {
			return new Promise((resolve, reject) => {
				this.$http
					.patch('team/' + teamUuid, updateInfo)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		editGroup(groupName, groupUuid) {
			return new Promise((resolve, reject) => {
				let data = {
					groupName: groupName,
				}
				this.$http
					.patch('/team/groups/' + groupUuid, data)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},

		removeGroup(groupUuid) {
			return new Promise((resolve, reject) => {
				this.$http
					.delete('/team/groups/' + groupUuid)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},

		removeGroupUser(userUuid, groupUuid) {
			return new Promise((resolve, reject) => {
				this.$http
					.delete('/team/groups/' + groupUuid + '/users/' + userUuid)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},

		changeUserGroupRole(userUuid, groupUuid, roleUuid) {
			let data = {
				roleUuid: roleUuid,
			}
			return new Promise((resolve, reject) => {
				this.$http
					.patch('/team/groups/' + groupUuid + '/users/' + userUuid, data)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		getTeamIcons() {
			return new Promise((resolve, reject) => {
				this.$http
					.get('/team/icons')
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
	},
}))()
