import Vue from 'vue'
import { errorMessages, flashMessages, teamMemberCountThreshold, teamRoles } from '@/constants'
import store from '@/store'
import TeamMemberModel from '@/models/TeamMemberModel'
import teamMemberDataService from '@/services/teamMemberDataService'
import AddTeamMembers from '@/components/team/teamSettings/users/AddTeamMembers'
import ManageMemberInvite from '@/components/team/teamSettings/users/ManageMemberInvite'
import util from '@/util'
import eventBus from '@/eventBus'
import router from '@/router'
import currentUserManager from '@/managers/currentUserManager'
import { baseManager } from './mixins/itemManagerMixins'

export default new (Vue.extend({
	Model: TeamMemberModel,
	dataService: teamMemberDataService,
	serverIdProperty: 'userUuid', // TODO: remove serverIdProperty when ids are consistent
	mixins: [baseManager],
	data() {
		return {
			addInfo: {
				id: 'members',
				title: 'Team Members',
				singularItemTitle: 'Team Member',
				addTitle: 'Invite Team Members',
				addLabelOverride: '+ Invite Members',
				addButtonText: 'Invite',
				description: 'Manage your team and invite new people to join',
				addButtonSavingText: 'Inviting…',
				iconName: 'members',
				suppressFlashMessages: true,
				sort: (a, b) => {
					function getNames(name) {
						const names = name.toLowerCase().split(' ')
						return [names[0], names[names.length - 1]]
					}
					let aNames = getNames(a.name),
						bNames = getNames(b.name)
					if (a.roleUuid === teamRoles.ADMIN && b.roleUuid !== teamRoles.ADMIN) return -1 // Compare Role
					if (a.roleUuid !== teamRoles.ADMIN && b.roleUuid === teamRoles.ADMIN) return 1
					if (aNames[1] > bNames[1]) return 1 // Compare Last Name
					if (aNames[1] < bNames[1]) return -1
					if (aNames[0] > bNames[0]) return 1 // Compare First Name
					if (aNames[0] < bNames[0]) return -1
					return 0
				},
				props: [
					/* List */
					{
						name: 'img',
						hiddenInForm: true,
						class: model => (model.isCurrentUser ? 'current-user ' : ' ') + 'item-list-icon user-avatar',
						type: 'img',
						alwaysShowInList: true,
					},
					{
						name: 'name',
						hiddenInForm: true,
						class: 'item-grow ellipsis',
					},
					{
						hiddenInForm: true,
						hiddenInList: model => !model.invitePending,
						type: 'custom',
						class: 'status-invite',
						component: ManageMemberInvite,
					},

					{
						name: 'filteredRole',
						hiddenInForm: true,
						class: 'status status-role',
					},

					/* Edit */
					{
						name: 'displayName',
						type: 'input',
						label: 'Display Name',
						hiddenInList: true,
						editingOnly: true,
						optionalLabel: model => model.invitePending,
						required: model => !!model.invitePending,
						warning: false,
					},

					{
						name: 'fullName',
						type: 'input',
						label: 'Full Name',
						hiddenInList: true,
						editingOnly: true,
						optionalLabel: model => !model.fullName,
						required: (editProps, model) => model.fullName,
						warning: false,
					},

					{
						name: 'friendlyEmail',
						default: '',
						editingOnly: true,
						class: 'email',
						type: 'input',
						label: 'Email',
						tooltip: errorMessages.EMAIL_READ_ONLY,
						disabled: true,
						hiddenInList: true,
						warning: false,
					},

					{
						name: 'roleUuid',
						label: 'Role',
						default: '',
						type: 'select',
						options: () => this.teamRoles,
						warning: false,
						confirmChange: model => {
							if (model.isCurrentUser && model.editProps.roleUuid === teamRoles.MEMBER) {
								return {
									heading: 'Are you sure you want to revoke your admin status?',
									subHeading:
										'You cannot undo this action by yourself as you will no longer have admin privileges.',
								}
							}
						},
						disabled: model => model.disableDeleteOrAdminRevocation,
						tooltip: model => {
							if (model.disableDeleteOrAdminRevocation) {
								return `Your team must have at least one other admin before revoking this team member’s admin status.`
							}
						},
						hiddenInList: true,
						editingOnly: true,
					},

					/* Add */
					{
						name: 'newUserList',
						component: AddTeamMembers,
						type: 'custom',
						hiddenInList: true,
						addingOnly: true,
					},
				],
			},
		}
	},
	computed: {
		teamRoles() {
			let team = store.teamInfo.activeTeam
			return team && team.teamRoles && team.teamRoles.map(role => ({ label: role.name, value: role.roleUuid }))
		},
	},
	methods: {
		add(user) {
			// TODO: Break this out into smaller, purer functions so they can be unit tested
			return new Promise((resolve, reject) => {
				let newUserList = user.editProps.newUserList,
					usersToAdd = []

				newUserList.forEach(userInfo => {
					userInfo.error = false
				})

				if (this.allFieldsEmpty(newUserList)) {
					if (this.inOnboarding) {
						this.$emit('nextStep')
					} else {
						reject(errorMessages.EMAIL_BLANK)
					}
					return
				}
				// Validation -- If there is anything in either field, ensure the email is valid
				if (
					newUserList.every(userInfo => {
						let validEmail
						if (userInfo.email || userInfo.fullName) validEmail = util.isEmail(userInfo.email)
						userInfo.error = !this.isTruthyOrUndefined(validEmail)
						if (validEmail) {
							this.$set(userInfo, 'roleUuid', userInfo.admin ? teamRoles.ADMIN : teamRoles.MEMBER)
							usersToAdd.push(userInfo)
						} else if (validEmail === false) {
							this.$set(userInfo, 'error', true)
							reject(errorMessages.EMAIL_INVALID)
							return
						}

						// Check that the email isn't already added
						if (
							Object.values(this.data.items).some(teamMember => {
								let existing = teamMember.friendlyEmail === userInfo.email
								if (existing) this.$set(userInfo, 'error', true)
								return existing
							})
						) {
							reject(errorMessages.EMAIL_ALREADY_ON_TEAM)
							return
						}

						// Check that an email isn't in the invite list twice
						if (
							newUserList.some(user => {
								const match = user.email && userInfo.email === user.email && userInfo !== user
								if (match) {
									this.$set(user, 'error', true)
									return true
								}
							})
						) {
							this.$set(userInfo, 'error', true)
							reject(errorMessages.DUPLICATE_EMAIL)
							return
						}
						return this.isTruthyOrUndefined(validEmail)
					})
				) {
					usersToAdd.forEach(user => {
						let placeholderModel = new this.$options.Model()
						placeholderModel.updateData(user)
						this.$set(this.data.items, `placeholder:${user.email}`, placeholderModel)
					})
					if (usersToAdd.length > teamMemberCountThreshold) {
						eventBus.$emit('flashMessage', flashMessages.WAIT_WHILE_INVITING_MEMBERS)
					}
					teamMemberDataService
						.add({
							users: usersToAdd.map(user =>
								(({ fullName, email, roleUuid }) => ({
									fullName,
									email,
									roleUuid,
								}))(user)
							), // Only copy relevant properties with ES6 Magic
						})
						.then(response => {
							this.refresh().then(() => {
								Object.keys(this.data.items).forEach(key => {
									if (key.includes('placeholder')) this.$delete(this.data.items, key)
								})
							})
							let usersArray = this.getUserIdentifiersArray(usersToAdd)
							eventBus.$emit('flashMessage', {
								message: `You have invited ${this.getUserIdentifiersString(usersArray)} to ${
									store.teamInfo.activeTeam.teamName
								}.`,
								icon: 'success',
							})
							if (!this.inOnboarding) this.$parent && this.$parent.close && this.$parent.close()
							resolve(response)
						})
						.catch(error => {
							this.refresh()
							this.errorMessage = util.handleError(error)
							reject(error)
						})
				}
			})
		},
		getUpdateRequestBody(teamMember) {
			return {
				displayName: teamMember.editProps.displayName,
				fullName: teamMember.editProps.fullName,
				roleUuid: teamMember.editProps.roleUuid,
			}
		},
		onUpdateSuccess(teamMember) {
			if (teamMember.isCurrentUser && teamMember.roleUuid === teamRoles.MEMBER) {
				store.userInfo.isAdmin = false
				router.push('/profile')
				eventBus.$emit('flashMessage', flashMessages.TEAM_MEMBER_REMOVED_OWN_ADMIN)
			} else {
				eventBus.$emit('flashMessage', {
					message: `${util.possessiveForm(
						teamMember.fullName || teamMember.friendlyEmail
					)} profile has been updated.`,
					icon: 'success',
				})
			}
			if (teamMember.isCurrentUser) currentUserManager.refreshProfile()
		},
		onDeleteSuccess(teamMember) {
			if (teamMember.isCurrentUser) {
				router.push('/logout')
				eventBus.$emit('flashMessage', flashMessages.TEAM_MEMBER_DELETED_SELF)
			} else {
				eventBus.$emit('flashMessage', {
					message: `${teamMember.name} has been removed from the team.`,
					icon: 'success',
				})
			}
			this.$delete(this.data.items, teamMember.id)
		},
		resendInvite(teamMember) {
			return new Promise((resolve, reject) => {
				teamMemberDataService
					.resendInvite(teamMember.id)
					.then(response => {
						eventBus.$emit('flashMessage', {
							message: `Invite resent to ${teamMember.name}.`,
							icon: 'success',
						})
						resolve(response)
					})
					.catch(error => {
						eventBus.$emit('flashMessage', {
							message: errorMessages.SERVER_ERROR_GENERAL,
							icon: 'error',
						})
						reject(error)
					})
			})
		},
		getUserIdentifiersArray(newUserList) {
			let userIdentifiers = []
			newUserList.map(userInfo => {
				userIdentifiers.push(userInfo.fullName || userInfo.email)
			})
			return userIdentifiers
		},
		getUserIdentifiersString(usersArray) {
			let result
			if (usersArray.length === 2) {
				result = usersArray.join(' and ')
			} else if (usersArray.length > teamMemberCountThreshold) {
				result = `${usersArray.length} team members`
			} else {
				result = usersArray.join(', ')
			}
			return result
		},
		allFieldsEmpty(newUserList) {
			return newUserList.every(userInfo => {
				return !userInfo.email && !userInfo.fullName
			})
		},
		isTruthyOrUndefined(value) {
			return value || typeof value === 'undefined'
		},
	},
}))()
