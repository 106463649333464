<template>
	<div>
		<div class="form-row">
			<label>Location</label>
			<location-typeahead class="typeahead"
				data-test="timezone-typeahead"
				:class="{ error: highlightRequiredComponentFields.indexOf('timeZoneId') !== -1}"
				type="timezone"
				:value="value"
				@input="getTimezone"
			/>
		</div>
	</div>
</template>



<script type="text/babel">
import LocationTypeahead from './LocationTypeahead'

export default {
	name: 'Timezone',
	components: { LocationTypeahead },
	props: {
		selectedItem: { type: Object, required: true },
		value: { type: Object, default: null },
		highlightRequiredComponentFields: { type: Array, default: () => [] },
	},
	data: function () {
		return {
			loading: false,
			name: '',
		}
	},
	computed: {
		editProps: {
			get() {
				return this.selectedItem.editProps
			},
			set(newVal) {
				this.selectedItem.editProps = newVal
			},
		},
	},
	methods: {
		async getTimezone({ shortName, name: timeZoneName, latitude, longitude, offset = 0, timeZoneId = '' } = {}) {
			// Even if result is not pre-populated UTC offset, emit early so that name can be populated
			this.editProps = {
				name: shortName,
				pinned: this.selectedItem.pinned,
				timezone: { timeZoneName, offset, timeZoneId },
			}
			// If result is not a pre-populated UTC offset, we need to get the timezone offset from the server
			if (typeof arguments[0].timeZoneId === 'undefined') {
				try {
					const {
						data: { name: timeZoneId, offset },
					} = await this.$http.get(`/timezones:search?latitude=${latitude}&longitude=${longitude}`)
					this.editProps = {
						name: shortName,
						pinned: this.selectedItem.pinned,
						timezone: { timeZoneName, offset, timeZoneId },
					}
				} catch (e) {
					console.error(e)
				}
			}
		},
	},
}
</script>



<style scoped>
</style>
