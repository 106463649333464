import Vue from 'vue'
import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	mixins: [itemModel],
	data: function () {
		return {
			id: null,
			label: 'Team Goal',
			title: '',
			active: false,
			props: ['label', 'title', 'active', 'id'],
		}
	},
})
