import Vue from 'vue'

export default Vue.extend({
	data: function () {
		return {
			errorMessage: null,
			loginLabelOverride: null,
			statusMessage: null,
			inProgress: false,
			loggedIn: false,
		}
	},
	methods: {
		resetState() {
			this.errorMessage = null
			this.loginLabelOverride = null
			this.statusMessage = null
			this.inProgress = false
		},
		setLoginState(state) {
			this.loggedIn = state
		},
	},
})
