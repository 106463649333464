<template>
	<div class="modal login-modal login">
		<auth-form form-test-attribute="login-form-message">
			<input
				id="username"
				ref="email"
				v-model.trim="credentials.username"
				v-focus
				:class="{ 'error': highlightEmailField }"
				type="text"
				data-test="login-email"
				placeholder="Email"
				@keyup="checkEmail($event)"
				@blur="checkEmail()"
				@keyup.enter="login()"
			>
			<div v-if="needPassword" class="password-input-wrapper">
				<input
					id="password"
					ref="password"
					v-model="credentials.password"
					:class="{ error: highlightPasswordField }"
					class="password-input"
					type="password"
					placeholder="Password"
					data-test="login-password"
					@keyup="detectCapslock($event)"
					@keyup.enter="login()"
				>
				<base-svg v-if="capslockOn" :icon-name="'capslock'"></base-svg>
			</div>
			<button :disabled="authInfo.inProgress" class="button button-primary" data-test="login-submit" type="button" @click="login()">
				<span class="button-label">{{ loginLabel }}</span>
			</button>
		</auth-form>

		<div class="below">
			<span class="login-link" @click="register()">Sign up</span><!--
			--><span class="login-link" @click="recover()">Recover Account</span>
		</div>
	</div>
</template>



<script type="text/babel">
import Auth from '@/managers/authManager'
import AuthForm from '@/components/common/AuthForm'
import { copy, errorMessages } from '@/constants'
import util from '@/util'
import capslockState from '@/mixins/capslockMixin'
import BaseSvg from '@/components/svgs/BaseSvg'

export default {
	components: {
		AuthForm,
		BaseSvg,
	},
	mixins: [capslockState],
	props: {
		forTeam: { type: Boolean, default: true },
	},
	data() {
		return {
			credentials: { username: '', password: '' },
			mainLoginLabel: copy.LOG_IN,
			inProgressButtonLabel: copy.LOGGING_IN,
			buttonTestAttribute: 'login-submit',
			needPassword: true,
			brandedLogin: null,
			highlightEmailField: false,
			highlightPasswordField: false,
		}
	},
	store: ['authInfo'],
	computed: {
		loginLabel() {
			return (
				this.authInfo.loginLabelOverride ||
				(this.brandedLogin
					? this.brandedLogin
					: this.authInfo.inProgress
						? this.inProgressButtonLabel
						: this.mainLoginLabel)
			)
		},
	},
	created() {
		this.redirect = this.$route.query.redirect
		if (this.forTeam) this.needPassword = false
	},
	destroyed() {
		this.authInfo.inProgress = false
	},
	methods: {
		checkEmail(e) {
			this.resetErrorStates()
			if ((this.forTeam && !this.needPassword) || (e && e.keyCode === 13)) return
			let email = this.credentials.username
			if (util.isEmail(email) && email.indexOf('.') !== -1) this.checkForThirdParty()
		},
		checkForThirdParty(startLogin) {
			return new Promise((resolve, reject) => {
				Auth.checkForThirdParty(this.credentials.username, startLogin)
					.then(response => {
						this.brandedLogin = response.brandedLogin
						if (response.needPassword !== null) this.needPassword = response.needPassword
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		register() {
			this.$router.push('register')
		},
		recover() {
			this.$router.push('recover')
		},
		resetErrorStates() {
			this.authInfo.resetState()
			this.highlightEmailField = false
			this.highlightPasswordField = false
		},
		login() {
			this.resetErrorStates()
			if (!this.credentials.username) {
				this.$set(this.authInfo, 'errorMessage', errorMessages.EMAIL_BLANK)
				this.highlightEmailField = true
				this.$refs.email.focus()
			} else if (!this.credentials.password && this.needPassword) {
				this.checkForThirdParty(true).then(response => {
					if (response && response.needPassword) {
						this.$set(this.authInfo, 'errorMessage', errorMessages.PASSWORD_BLANK)
						this.highlightPasswordField = true
						this.$refs.password.focus()
					}
				})
			} else if (!this.needPassword) {
				this.checkForThirdParty(true)
			} else {
				Auth.login(this.credentials, this.redirect)
			}
		},
	},
}
</script>