import Vue from 'vue'
import itemModel from '@/models/mixins/itemModelMixin'
import util from '@/util'

export default Vue.extend({
	mixins: [itemModel],
	data() {
		return {
			id: null,
			title: '',
			messageText: '', // description
			sentDate: null,
			actionEdit: { ctaUrl: '', ctaText: '', hasAction: false, archived: false, draft: true },
			archived: false,
			draft: true,
			props: ['id', 'title', 'messageText', 'actionEdit', 'draft', 'archived'],
		}
	},
	computed: {
		disableEdit() {
			return (!this.archived && !this.draft) || this.archived
		},
		canSaveAsDraft() {
			return this.draft
		},
		formattedDate() {
			let formattedDate = ''
			if (this.sentDate) {
				formattedDate = util.formatDate(this.sentDate, !this.archived ? { year: null } : undefined)
			}
			return formattedDate
		},
	},
	methods: {
		updateData(announcement) {
			this.actionEdit.ctaUrl = announcement.ctaUrl
			this.actionEdit.ctaText = announcement.ctaText
			this.actionEdit.hasAction = !!(announcement.ctaUrl && announcement.ctaText)
			this.archived = this.actionEdit.archived = Date.parse(announcement.expiry) < new Date().getTime()
			this.draft = this.actionEdit.draft = !announcement.isSent
			this.sentDate = announcement.isSent
			this.id = announcement.id
			this.title = announcement.title
			this.messageText = announcement.messageText
			this.revertChanges()
		},
		getChanges() {
			let changes = []
			if (this.editProps) {
				this.props.forEach(prop => {
					if (typeof this.editProps[prop] === 'object' && this.editProps[prop] !== null) {
						// null is an object btw! Thanks Javascript!
						// handles 'actionEdit' property, breaking each key into separate updated properties
						for (let key in this.editProps[prop]) {
							if (this[prop][key] !== this.editProps[prop][key]) {
								changes.push(key)
								this.editProps[key] = this.editProps[prop][key]
							}
						}
					} else if (this[prop] !== this.editProps[prop]) {
						changes.push(prop)
					}
				})
			}
			return changes
		},
	},
})
