import Vue from 'vue'
import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	mixins: [itemModel],
	data() {
		return {
			id: null,
			dateEdit: { date: null, hasHours: false, utcDate: null },
			name: '',
			pinned: false,
			// readOnly: false,
			props: ['pinned', /*'readOnly',*/ 'name', 'dateEdit'],
		}
	},
	watch: {
		'editProps.dateEdit.date'(newVal) {
			if (this.editProps.dateEdit && newVal && newVal.getTime) {
				this.editProps.dateEdit.utcDate = newVal.getTime()
			}
		},
		'dateEdit.date'(newVal) {
			if (this.dateEdit && newVal && newVal.getTime) {
				this.dateEdit.utcDate = newVal.getTime()
			}
		},
	},
	methods: {
		updateData: function (countdown) {
			this.dateEdit.utcDate = Number.parseInt(countdown.utcDate)
			this.dateEdit.hasHours = countdown.hasHours
			this.dateEdit.date = new Date(this.dateEdit.utcDate)
			this.name = countdown.name
			this.pinned = countdown.pinned
			this.id = countdown.id
			// this.readOnly = countdown.readOnly
			this.revertChanges()
		},
		setDefaultDate: function () {
			if (!this.dateEdit.utcDate) {
				let date = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
				this.dateEdit.utcDate = date.getTime()
			}
			return this.dateEdit.utcDate
		},
		getChanges() {
			let changes = []
			if (this.editProps) {
				this.props.forEach(prop => {
					if (typeof this.editProps[prop] === 'object' && this.editProps[prop] !== null) {
						// null is an object btw! Thanks Javascript!
						// handles 'dateEdit' property, breaking each key into separate updated properties
						for (let key in this.editProps[prop]) {
							let changed,
								originalProp = this[prop][key],
								updatedProp = this.editProps[prop][key]

							if (originalProp && originalProp.getTime && updatedProp && updatedProp.getTime) {
								changed = originalProp.getTime() !== updatedProp.getTime()
							} else {
								changed = originalProp !== updatedProp
							}
							if (changed) {
								changes.push(key)
								this.editProps[key] = updatedProp
							}
						}
					} else if (this[prop] !== this.editProps[prop]) {
						changes.push(prop)
					}
				})
			}
			return changes
		},
	},
})
