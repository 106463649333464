<template>
	<div>
		<div class="">
			<label>Date</label>

			<div class="select-wrapper">
				<select
					id="month"
					v-model="month"
					name="month"
					class="month"
					data-test="month"
					@change="update"
				>
					<option v-for="(month, index) in months" :key="index" :value="index">{{ month }}</option>
				</select>
			</div>

			<div class="select-wrapper">
				<select
					id="day" 
					v-model="day"
					name="day"
					class="day"
					data-test="day"
					@change="update"
				>
					<option v-for="n in days" :key="n" :value="n">{{ n }}</option>
				</select>
			</div>

			<div class="select-wrapper countdown-year-group">
				<select
					id="year"
					v-model="selectedYear"
					name="year"
					class="year"
					data-test="year"
				>
					<option v-for="n in 5" :key="n" :value="currentYear + n - 1">{{ currentYear + n - 1 }}</option>
					<option value="other">Other</option>
				</select>

				<input
					v-if="otherYearActive"
					id="countdown-year-other"
					ref="otherYear"
					v-model="otherYear"
					type="text"
					name="countdown-year-other"
					placeholder="yyyy"
					maxlength="4"
					class="countdown-year-other"
					data-test="other-year"
					@blur="updateOtherYear"
					@keypress="isNumberKey($event)"
					@keydown.enter.stop.prevent="updateOtherYear"
				>
			</div>
		</div>

		<button v-if="!customTime" type="button" data-test="custom-time-button" class="button button-neutral button-smaller add-extra" @click="toggleTime">+ End at a specific time</button>
		<div v-else class="option-wrapper form-row">
			<label>Time</label>
			<div class="option-form add-time-wrapper">
				<div class="add-time-group">
					<div class="select-wrapper select-hour">
						<select id="hour" v-model="hours" name="hour" data-test="hour" @change="update">
							<option v-for="n in 12" :key="n" :value="n">{{ n }}</option>
						</select>
					</div>
					<span class="hour-punctuation">:</span>

					<div class="select-wrapper">
						<select id="minute" v-model="minutes" name="minute" data-test="minute" @change="update">
							<!-- eslint-disable-next-line -->
							<option v-for="n in 60" :key="n" :value="n-1">{{ (n < 11 ? '0' : '') + (n - 1) }}</option>
						</select>
					</div>

					<div class="select-wrapper period">
						<select id="period" v-model="period" name="period" data-test="period" @change="update">
							<option value="AM">AM</option>
							<option value="PM">PM</option>
						</select>
					</div>

					<button class="button button-neutral button-smaller clear-time button-clear" type="button" @click="toggleTime">Clear</button>
					<button class="button button-neutral button-smaller clear-time x-clear" type="button" @click="toggleTime"><img class="icon icon-delete" src="@/img/icons/close-dark.svg"></button>
				</div>
			</div>
		</div>

	</div>
</template>



<script>
import eventBus from '@/eventBus'

export default {
	name: 'DateTime',
	props: {
		value: { type: Object, default: null },
		onEnter: { type: Function, default() {} },
		onUpdate: { type: Function, default() {} },
		onBlur: { type: Function, default() {} },
	},
	data() {
		let date = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000) // 7 days later
		return {
			defaultDate: date,
			year: date.getFullYear(),
			month: date.getMonth(),
			day: date.getDate(),
			hours: 9,
			minutes: 0,
			period: 'AM',
			otherYearActive: false,
			customTime: false,
			otherYear: new Date().getFullYear(),
			selectedYear: new Date().getFullYear(),
			currentYear: new Date().getFullYear(),
			months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		}
	},
	computed: {
		days() {
			let date = this.value && this.value.date ? this.value.date : this.defaultDate
			return getDaysInMonth(date.getFullYear(), date.getMonth())
			// `month` passed in must be 0-11
			function getDaysInMonth(year, month) {
				let dt = new Date(year, parseInt(month) + 1, 0) // Add 1 to month and use zeroth day to get last day of month
				return dt.getDate()
			}
		},
	},
	watch: {
		value: {
			handler(newVal) {
				if (newVal) {
					if (newVal.date) this.init(newVal)
					else this.init({ date: this.defaultDate, hasHours: false })
				}
			},
			deep: true,
			immediate: true,
		},
		selectedYear(newVal) {
			if (newVal === 'other') {
				this.otherYearActive = true
				setTimeout(() => {
					this.$refs.otherYear.focus()
				}, 10)
			} else {
				this.year = newVal
				this.update()
			}
		},
	},
	mounted() {
		this.loadingSearch = 0
		eventBus.$on('modalClosed', this.onModalClosed)
	},
	destroyed() {
		eventBus.$off('modalClosed', this.onModalClosed)
	},
	methods: {
		init(newVal) {
			this.selectedYear = this.otherYear = this.year = newVal.date.getFullYear()
			this.otherYearActive = this.year < this.currentYear || this.year > this.currentYear + 4
			this.customTime = newVal.hasHours
			this.month = newVal.date.getMonth()
			this.day = newVal.date.getDate()
			if (newVal.hasHours) {
				this.hours = newVal.date.getHours()
				this.minutes = newVal.date.getMinutes()
			}
			if (this.hours >= 12) {
				this.period = 'PM'
				if (this.hours > 12) this.hours = this.hours - 12
			}

			if (this.hours === 0) this.hours = 12
		},
		toggleTime() {
			this.customTime = !this.customTime
			if (!this.customTime) {
				this.period = 'AM'
				this.hours = 9
				this.minutes = 0
				this.update()
			}
		},
		update() {
			const hours =
				this.period === 'AM' && this.hours === 12
					? 0
					: this.period === 'PM' && this.hours !== 12
						? this.hours + 12
						: this.hours
			let date = new Date(this.year, this.month, this.day, hours, this.minutes)
			date.setDate(this.day) // not sure why this is needed, but it is!
			this.$emit('input', { date: date, hasHours: this.customTime })
		},
		updateOtherYear() {
			this.year = this.otherYear
			this.$refs.otherYear.blur()
			this.update()
		},

		// From: https://stackoverflow.com/questions/469357/html-text-input-allows-only-numeric-input
		isNumberKey(e) {
			let key
			// Handle paste
			if (e.type === 'paste') {
				key = event.clipboardData.getData('text/plain')
			} else {
				// Handle key press
				key = e.keyCode || e.which
				key = String.fromCharCode(key)
			}
			let regex = /[0-9]|\./
			if (!regex.test(key)) {
				e.returnValue = false
				if (e.preventDefault) e.preventDefault()
			}
		},
		onModalClosed() {
			if (this.value) this.customTime = this.value.hasHours
		},
	},
}
</script>



<style scoped>

.countdown-year-group { position: relative }
	.countdown-year-group .year { margin-right: 0; }

.countdown-year-other { width: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 2; }
	.add-time-group { display: flex; align-items: center; }
		.add-time-wrapper { padding-top: 0; position: relative; }
			.hour-punctuation { margin-right: 5px; }
			.select-wrapper.period { margin-right: 10px; }

.x-clear { width: 30px; }

/* Responsive */
@media handheld and (max-width: 740px), screen and (max-device-width: 740px), screen and (max-width: 740px) {
	.select-wrapper.period { margin-right: 5px; }

	.button-clear { display: none; }
	.x-clear { display: block; }

}

</style>
