import Vue from 'vue'

const TeamTodoDataService = Vue.extend({
	data: function () {
		return {}
	},
	computed: {},
	methods: {
		getAvailableTodoIntegrations() {
			return new Promise((resolve, reject) => {
				this.$http
					.get('/team/todo-integrations/available')
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		getConnectedTodoIntegrations() {
			return new Promise((resolve, reject) => {
				this.$http
					.get('/team/todo-integrations/connected')
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		addTodoIntegration(integrationId) {
			return new Promise((resolve, reject) => {
				let data = {
					integrationId: integrationId,
				}
				this.$http
					.post('/team/todo-integrations', data)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		deleteTodoIntegration(integrationId) {
			return new Promise((resolve, reject) => {
				this.$http
					.delete('/team/todo-integrations/' + integrationId)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},
})
export default new TeamTodoDataService()
