export const baseDataService = {
	defaultPath: '', // Override this!
	// To use a different path, e.g. for categories, pass in a path to the methods below

	methods: {
		get({ path = this.$options.defaultPath, queryParams = {} } = {}) {
			path = this.buildPath(path, null, queryParams)
			return new Promise((resolve, reject) => {
				this.$http
					.get(path)
					.then(res => resolve(res.data))
					.catch(err => reject(err))
			})
		},
		add(data, { path = this.$options.defaultPath, queryParams = {} } = {}) {
			path = this.buildPath(path, null, queryParams)
			return new Promise((resolve, reject) => {
				this.$http
					.post(path, data)
					.then(res => resolve(res.data))
					.catch(err => reject(err))
			})
		},
		update(id, data, { path = this.$options.defaultPath, queryParams = {} } = {}) {
			path = this.buildPath(path, id, queryParams)
			return new Promise((resolve, reject) => {
				this.$http
					.patch(path, data)
					.then(res => resolve(res.data))
					.catch(err => reject(err))
			})
		},
		delete(id, { path = this.$options.defaultPath, queryParams = {} } = {}) {
			path = this.buildPath(path, id, queryParams)
			return new Promise((resolve, reject) => {
				this.$http
					.delete(path)
					.then(res => resolve(res.data))
					.catch(err => reject(err))
			})
		},

		// HELPERS
		buildPath(path, id, queryParams) {
			if (!id && !queryParams) return path
			if (id) return (path.endsWith('/') ? path + id : path + '/' + id) + this.getQueryString(queryParams)
			return path + this.getQueryString(queryParams)
		},
		getQueryString(queryParams) {
			let string = '?',
				keys = Object.keys(queryParams)
			if (!keys.length) return ''
			keys.forEach((key, index) => {
				string += `${key}=${queryParams[key]}`
				if (index < keys.length - 1) string += '&'
			})
			return string
		},
	},
}

export const reorder = {
	methods: {
		reorder(reorderInfo, { path = this.$options.defaultPath } = {}) {
			return new Promise((resolve, reject) => {
				this.$http
					.patch(path, reorderInfo)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},
}
