import Vue from 'vue'
import util from '@/util'
import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	mixins: [itemModel],
	data: function () {
		return {
			id: null,
			title: '',
			iconUrl: null,
			date: null,
			items: {},
			dragging: false,
			order: null,
			loaded: true, // the real loading state will be on the category level, but if this exists, it will be loaded
			props: ['title', 'id', 'order', 'iconUrl'],
		}
	},
	computed: {
		deleteWarning() {
			const count = Object.keys(this.items).length
			if (count) return `Deleting this category will decategorize ${util.pluralize(count, '{#} app{:s}')}.`
		},
	},
})
