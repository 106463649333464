import store from '@/store'

export default {
	data() {
		return {
			capslockOn: false,
		}
	},
	created() {
		window.addEventListener('keyup', this.detectCapslock)
		window.addEventListener('keydown', this.detectCapslock)
	},
	destroyed() {
		window.removeEventListener('keyup', this.detectCapslock)
		window.removeEventListener('keydown', this.detectCapslock)
	},
	methods: {
		detectCapslock(e) {
			if (!store.browser.isSafari && e.getModifierState) this.capslockOn = e.getModifierState('CapsLock')
		},
	},
}
