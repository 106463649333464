import Vue from 'vue'
import { linkTypes } from '@/constants'
import store from '@/store'
import util from '@/util'
import itemModel from '@/models/mixins/itemModelMixin'
import { Collection } from './CollectionModel'

export default Vue.extend({
	mixins: [itemModel],
	data: function () {
		return {
			id: null,
			isRoot: false,
			type: linkTypes.GROUP,
			title: '',
			url: '',
			linkEdit: [],
			// readOnly: false,
			pinned: false,
			date: null,
			parentLinkId: null,
			dragging: false,
			order: null,
			linksOrder: [],
			props: [
				'title',
				'url',
				/*'readOnly',*/ 'pinned',
				'sublinks',
				'order',
				'type',
				'parentLinkId',
				'id',
				'linksOrder',
			],
		}
	},
	computed: {
		groupSummary() {
			return Object.values(this.sublinks.items)
				.map(sublink => {
					let domain = sublink.url && sublink.url.split('//')[1].split('/')[0] // take everything between '//' and the next '/'
					return domain
				})
				.filter(domain => !!domain)
				.join(', ')
		},
		sublinks: {
			get() {
				let sublinks = new Collection()
				if (this.type !== linkTypes.GROUP || !this.linksOrder) return sublinks
				this.linksOrder.forEach(linkId => {
					let sublink = this.allLinks.items[linkId]
					if (sublink) sublinks.items[linkId] = sublink
				})
				sublinks.loaded = true
				return sublinks
			},
			set() {}, // Empty setter prevents vue warning.
		},
		allLinks() {
			return store.teamInfo.activeTeam.allLinks
		},
	},
	methods: {
		updateData(link) {
			if (typeof link.type != 'undefined') this.type = link.type
			this.title = link.title
			this.url = this.type === linkTypes.GROUP ? null : link.url
			this.id = link.id
			this.isRoot = link.isRoot
			this.pinned = link.pinned
			this.parentLinkId = link.parentLinkId
			this.linksOrder = link.linksOrder
			// this.readOnly = link.readOnly
			this.order = link.order
			this.revertChanges()
		},
		isEmptyLinkGroup() {
			return (
				this.type === linkTypes.GROUP &&
				!this.url &&
				!Object.keys(this.sublinks.items).length &&
				Object.values(this.editProps.sublinks.items).every(sublink => !sublink.url)
			)
		},
		getChanges() {
			let changes = []
			if (this.editProps) {
				this.props.forEach(prop => {
					if (prop === 'sublinks') return
					if (Array.isArray(this[prop]) || Array.isArray(this.editProps[prop])) {
						if (!util.arraysAreEqual(this[prop], this.editProps[prop])) changes.push(prop)
					} else if (this[prop] !== this.editProps[prop]) {
						changes.push(prop)
					}
				})
			}
			/** Ignore linksOrder if empty link group, see {@link #getSublinkChanges} **/
			if (changes.length === 1 && changes[0] === 'linksOrder' && this.isEmptyLinkGroup()) return []
			return changes
		},
		getSublinkChanges() {
			let changes = []
			if (Object.keys(this.editProps).length) {
				// Since the link starts with no sublinks but gets one immediately once adding
				// This will be detected as a change but we don't want it to. So if this is a new link group with no
				// sublinks but only empty editProps sublinks, treat it as no changes.
				if (this.isEmptyLinkGroup()) return changes
				Object.values(this.editProps.sublinks.items).forEach(sublink => {
					if (sublink.copyOf) {
						let originalSublink = sublink.copyOf
						if (sublink.deleted) {
							changes.push({ id: sublink.id, deleted: true })
						} else if (originalSublink.url !== sublink.url) {
							if (sublink.url.trim()) {
								changes.push({
									id: sublink.id,
									url: util.ensureUrlScheme(sublink.url.trim()),
								})
							} else {
								changes.push({ id: sublink.id, deleted: true })
							}
						}
					} else if (sublink.url.trim()) {
						changes.push({
							csid: sublink.csid,
							url: util.ensureUrlScheme(sublink.url.trim()),
							/*readOnly: this.readOnly,*/ categoryId: this.categoryId,
						})
					}
				})
			}
			return changes
		},
		commitChanges() {
			this.props.forEach(prop => {
				this[prop] = this.editProps[prop]
			})
			this.getSublinkChanges().forEach(sublink => {
				if (sublink.deleted) {
					this.allLinks.hasOwnProperty(sublink.id) && this.$delete(this.allLinks, sublink.id)
				}
			})
			this.revertChanges()
		},
		copyProperties() {
			for (let prop of this.props) {
				if (prop === 'sublinks') {
					let items = {}
					Object.values(this.sublinks.items).forEach(sublink => {
						items[sublink.id] = {
							id: sublink.id,
							url: sublink.url,
							copyOf: sublink,
							deleted: false,
							dragging: false,
						}
					})
					this.$set(this.editProps, 'sublinks', { items, loaded: this.sublinks.loaded })
				} else if (Array.isArray(this[prop])) {
					this.$set(this.editProps, prop, [...this[prop]])
				} else {
					this.$set(this.editProps, prop, this[prop])
				}
			}
		},
		addEmptySublink() {
			if (!this.editProps.type || this.editProps.type === linkTypes.DEPRECATED) {
				this.switchToGroupType()
				this.appendSublink('')
			} else {
				this.appendSublink()
			}
		},
		deleteSublink(id) {
			if (this.editProps.sublinks.items[id].hasOwnProperty('deleted')) {
				this.$set(this.editProps.sublinks.items[id], 'deleted', true)
			} else {
				this.$delete(this.editProps.sublinks.items, id)
			}
			const index = this.editProps.linksOrder.indexOf(id)
			if (index !== -1) this.editProps.linksOrder.splice(index, 1)
		},
		switchToGroupType() {
			this.$set(this.editProps, 'type', linkTypes.GROUP)
			this.editProps.linksOrder = []
			this.appendSublink(this.editProps.url)
			this.$set(this.editProps, 'url', '')
		},
		appendSublink(url) {
			const csid = 'csid:' + util.uuidv4()
			this.editProps.sublinks.items[csid] = {
				url: url || '',
				id: csid,
				csid,
				deleted: false,
				dragging: false,
			}
			this.editProps.linksOrder.push(csid)
		},
	},
})
