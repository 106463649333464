import Vue from 'vue'
import { teamRoles, warningMessages } from '@/constants'
import store from '@/store'
import itemModel from '@/models/mixins/itemModelMixin'
import { addTeamMembersUtil } from '@/util'

export default Vue.extend({
	mixins: [itemModel],
	data() {
		return {
			id: null,
			displayName: null,
			fullName: null,
			img: null,
			roleUuid: null,
			email: null,
			editProps: {},
			invitePending: false,
			props: ['id', 'displayName', 'fullName', 'img', 'roleUuid', 'friendlyEmail', 'newUserList'],
			newUserList: addTeamMembersUtil.getUserCluster(), // HACK: Only used for inviting users! It is done this way so it can be compatible with the 'Items' component
		}
	},
	computed: {
		name() {
			return this.fullName || this.friendlyEmail
		},
		team() {
			return store.teamInfo.activeTeam
		},
		role() {
			return (
				this.team.teamRoles &&
				this.team.teamRoles[this.team.teamRoles.findIndex(role => role.roleUuid === this.roleUuid)]
			)
		},
		filteredRole() {
			// HACK: only display the role if it is not 'Member'.
			// Perhaps we should look into a way of doing this without a string comparison.
			return this.role && this.role.roleUuid !== teamRoles.MEMBER ? this.role.name : ''
		},
		friendlyEmail: {
			get() {
				/* When a user that already has a momentum account is invited to a team, until they accept, their email will look like this:
					7d0cb64b79aa46219b106d3f1eac0e7d@dace+test@momentumdash.com
					If that's the case, this computed property will return everything after the first @ sign, otherwise the normal email */
				let segments = this.email && this.email.split('@')
				if (segments && segments.length > 2) return `${segments[1]}@${segments[2]}`
				return this.email
			},
			set() {}, // Prevents vue warning. When commitChanges runs, it will try to set this property. We don't want that to do anythung.
		},
		isCurrentUser() {
			return this.id === store.userInfo.uuid
		},
		deleteWarning() {
			if (this.disableDeleteOrAdminRevocation) return warningMessages.CANNOT_DELETE_ONLY_ADMIN
			else if (this.isCurrentUser) return warningMessages.DELETE_YOURSELF
		},
		disableDeleteOrAdminRevocation() {
			// Disable delete and revoking admin status if team member is the only admin
			return (
				this.roleUuid === teamRoles.ADMIN &&
				Object.values(store.teamInfo.activeTeam.teamMembers.items).filter(u => u.roleUuid === teamRoles.ADMIN)
					.length <= 1
			)
		},
		disableDelete() {
			return this.disableDeleteOrAdminRevocation
		},
	},
	methods: {
		updateData: function (user) {
			this.id = user.userUuid || user.id
			this.displayName = user.displayName
			this.fullName = user.fullName
			this.roleUuid = user.roleUuid
			this.email = user.email
			this.img = user.img
			this.invitePending = user.invitePending
			this.revertChanges()
		},
		getChanges() {
			let changes = []
			if (this.editProps) {
				this.props.forEach(prop => {
					if (prop === 'newUserList') {
						if (this.editProps[prop].some(newUserRow => newUserRow.email || newUserRow.fullName)) {
							changes.push(prop)
						}
					} else if (this[prop] !== this.editProps[prop]) {
						changes.push(prop)
					}
				})
			}
			return changes
		},
	},
})
