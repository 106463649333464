import Vue from 'vue'
import topicDataService from '@/services/teamTopicDataService'
import TopicCategoryModel from '@/models/TopicCategoryModel'
import TopicModel from '@/models/TopicModel'
import IconSelect from '@/components/common/IconSelect'
import { baseManager, categoryManager, reorder } from './mixins/itemManagerMixins'

export default new (Vue.extend({
	Model: TopicCategoryModel,
	ItemModel: TopicModel,
	dataService: topicDataService,
	itemNameFromServer: 'topics', // Used in the refresh method in categoryManager mixin
	uncategorizedIdKey: 'topicId', // not yet used
	pathOverride: 'team/topicCategories/',
	refreshPathOverride: topicDataService.$options.defaultPath,
	mixins: [baseManager, categoryManager, reorder],
	data() {
		return {
			addInfo: {
				id: 'categories',
				title: 'Categories',
				singularItemTitle: 'Category',
				addTitle: 'What category would you like to add?',
				iconClass: 'ion-link',
				iconSrc: require('../img/icons/link.svg'),
				allowReorder: true,
				props: [
					{
						name: 'id',
						type: 'hidden',
						hiddenInList: true,
					},
					{
						name: 'iconUrl',
						class: 'item-list-icon',
						type: 'img',
						hiddenInForm: true,
						alwaysShowInList: true,
					},
					{
						name: 'title',
						default: '',
						class: 'item-name',
						type: 'input',
						label: 'Name',
						warning: false,
						required: true,
						dataTest: 'title',
					},
					{
						name: 'iconId',
						default: null,
						class: 'item-icon',
						label: 'Icon',
						type: 'custom',
						component: IconSelect,
						hiddenInList: true,
					},
				],
			},
		}
	},
	methods: {
		getAddRequestBody(category) {
			return {
				title: category.editProps.title,
				iconId: category.editProps.iconId,
			}
		},
	},
}))()
