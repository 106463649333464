import Vue from 'vue'
import announcementService from '@/services/teamAnnouncementDataService'
import AnnouncementModel from '@/models/AnnouncementModel'
import AnnouncementAction from '@/components/team/announcements/AnnouncementAction'
import util from '../util'
import { errorMessages } from '../constants'
import { baseManager } from './mixins/itemManagerMixins'

export default new (Vue.extend({
	dataService: announcementService,
	Model: AnnouncementModel,
	mixins: [baseManager],
	data() {
		return {
			addInfo: {
				id: 'announcements',
				title: 'Announcements',
				singularItemTitle: 'Announcement',
				addTitle: 'What announcement would you like to add?',
				description: 'Notify your team members of important events, team news, dates, etc.',
				addButtonText: 'Send',
				addButtonSavingText: 'Sending…',
				editButtonText: 'Send',
				editButtonSavingText: 'Sending…',
				addLabelOverride: '+ Add Announcement',
				iconName: 'announcement',
				generalToolTip: 'Your live announcement will automatically expire after 7 days.',
				sort: (a, b) => {
					if (a.sentDate < b.sentDate) return 1
					if (a.sentDate > b.sentDate) return -1
					return 0
				},
				props: [
					{
						name: 'title',
						placeholder: 'What do you want to notify your team of?',
						default: '',
						class: 'item-name',
						type: 'input',
						label: 'Title',
						required: true,
						warning: false,
					},
					{
						name: 'messageText',
						default: '',
						class: 'item-detail item-grow ellipsis',
						type: 'input',
						label: 'Description',
						alwaysShowInList: true,
						optionalLabel: model => !model.disableEdit,
					},
					{
						name: 'formattedDate',
						type: 'display',
						class: 'status announcement-date',
						hiddenInForm: true,
					},
					{
						name: 'actionEdit',
						type: 'custom',
						component: AnnouncementAction,
						hiddenInList: true,
						// if neither is filled, they don't need to be highlighted
						required: editProps =>
							(editProps.actionEdit.ctaUrl && !editProps.actionEdit.ctaText) ||
							(editProps.actionEdit.ctaText && !editProps.actionEdit.ctaUrl),
						requiredComponentFields: editProps => {
							if (editProps.actionEdit.ctaUrl && !editProps.actionEdit.ctaText) return ['inputCtaText']
							if (editProps.actionEdit.ctaText && !editProps.actionEdit.ctaUrl) return ['inputUrl']
						},
						warning: false,
					},
				],
			},
		}
	},
	methods: {
		getAddRequestBody(announcement) {
			let requestBody = {
				title: util.capitalize(announcement.editProps.title),
				messageText: announcement.editProps.messageText,
			}
			if (announcement.editProps.actionEdit.hasAction) {
				const secureUrl = util.ensureSecureUrlScheme(announcement.editProps.actionEdit.ctaUrl)
				requestBody.ctaUrl = secureUrl
				requestBody.ctaText = announcement.editProps.actionEdit.ctaText
			}
			return requestBody
		},
		add(announcement) {
			// add and update are overridden because they need to append a ?send=true query string if the announcement is not a draft
			const requestBody = this.getAddRequestBody(announcement)
			let newAnnouncement = new this.$options.Model()
			newAnnouncement.updateData(announcement.editProps)
			this.$set(this.data.items, 'placeholder', newAnnouncement)
			return new Promise((resolve, reject) => {
				announcementService
					.add(requestBody, this.getQueryParams(announcement))
					.then(response => {
						if (response.data && response.data.success === false) {
							reject(errorMessages.NOTIFICATION_SEND_FAIL)
						} else {
							resolve(response)
						}
					})
					.catch(error => {
						this.$delete(this.data.items, 'placeholder')
						console.log(error)
						reject(error)
					})
			})
		},
		update(announcement) {
			const requestBody = this.getUpdateRequestBody(announcement)
			return new Promise((resolve, reject) => {
				announcementService
					.update(announcement.id, requestBody, this.getQueryParams(announcement))
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						console.log(error)
						reject(error)
					})
			})
		},
		getQueryParams(announcement) {
			if (!announcement.editProps.draft) return { queryParams: { send: true } }
		},
	},
}))()
