import Vue from 'vue'
import metricService from '@/services/teamMetricDataService'
import MetricModel from '@/models/MetricModel'
import MetricEdit from '@/components/team/metrics/MetricEdit'
import MetricPath from '@/components/team/metrics/MetricPath'
import MetricIntegration from '@/components/team/metrics/MetricIntegration'
import MetricProviderModel from '../models/MetricProviderModel'
import { metricTypes, metricCycles } from '@/constants'
import util from '@/util'
import { baseManager } from './mixins/itemManagerMixins'
import { Collection } from '../models/CollectionModel'

export default new (Vue.extend({
	Model: MetricModel,
	dataService: metricService,
	mixins: [baseManager],
	data() {
		return {
			providerCollection: new Collection(),
			addInfo: {
				id: 'metrics',
				title: 'Metrics',
				singularItemTitle: 'Metric',
				description: 'Showcase important KPIs for your team',
				iconName: 'metric',
				sort: (a, b) => {
					if (a.pinned && !b.pinned) return -1
					if (!a.pinned && !b.pinned) return 1
					return 0
				},
				props: [
					{ name: 'id', type: 'hidden' },
					{
						type: 'custom',
						component: MetricIntegration,
						hiddenInForm: true,
						showInMetricEdit: model => model.type === metricTypes.INTEGRATION,
					},
					{
						name: 'label',
						placeholder: 'What are you reporting on?',
						default: '',
						class: 'item-body',
						type: 'input',
						label: 'Name',
						required: true,
						warning: false,
						hiddenInForm: true,
						showInMetricEdit: true,
						dataTest: 'name',
					},
					{
						name: 'displayValue', // may be 0 if cycle has reset
						placeholder: 'What is the current value?',
						default: '',
						class: 'item-body',
						type: 'input',
						label: 'Value',
						warning: false,
						hiddenInForm: true,
						required: model => model.type === metricTypes.MANUAL,
						hiddenInList: model => model.type !== metricTypes.MANUAL,
						showInMetricEdit: model => model.type === metricTypes.MANUAL,
						dataTest: 'value',
					},
					{
						name: 'cycle',
						type: 'select',
						label: 'Reset',
						class: 'reset',
						options: [
							{ value: metricCycles.NONE, label: 'Never' },
							{ value: metricCycles.DAILY, label: 'Daily' },
							{ value: metricCycles.WEEKLY, label: 'Weekly' },
							{ value: metricCycles.MONTHLY, label: 'Monthly' },
							{ value: metricCycles.YEARLY, label: 'Yearly' },
						],
						warning: false,
						hiddenInForm: true,
						hiddenInList: true,
						showInMetricEdit: model => model.type === metricTypes.MANUAL,
						dataTest: 'cycle',
					},
					{
						name: 'url',
						placeholder: 'http://',
						default: '',
						class: 'url',
						type: 'input',
						label: 'URL',
						warning: false,
						hiddenInForm: true,
						required: model => model.type === metricTypes.URL,
						hiddenInList: model => model.type !== metricTypes.URL,
						showInMetricEdit: model => model.type === metricTypes.URL,
						subText:
							'Enter an API address <a href="https://get.momentumdash.help/hc/en-us/articles/360038089754#h_b3d82248-1a7f-40c1-9304-01b0421dacca" target="_blank">More</a>',
						dataTest: 'url',
					},
					{
						name: 'path',
						default: '',
						class: 'path',
						type: 'custom',
						label: 'Value',
						component: MetricPath,
						hiddenInForm: true,
						required: model => model.type === metricTypes.URL,
						hiddenInList: true,
						showInMetricEdit: model => {
							return model.type === metricTypes.URL
						},
						requiredComponentFields: editProps => {
							if (editProps.url && !editProps.path) return ['path']
						},
					},
					{
						name: 'pinned',
						default: false,
						type: 'toggle',
						label: 'Pinned',
						hiddenInForm: true,
						showInMetricEdit: true,
						dataTest: 'pinned',
					},
					// {
					// 	name: "archived",
					// 	default: false,
					// 	type: "toggle",
					// 	label: "Archive"
					// },
					{
						name: 'metricEdit',
						type: 'custom',
						component: MetricEdit,
					},
				],
			},
		}
	},
	computed: {
		providers() {
			if (!this.providerCollection.loaded) this.refreshMetricProviders()
			return this.providerCollection
		},
	},
	methods: {
		getAddRequestBody(metric) {
			let requestBody = {
				label: util.capitalize(metric.editProps.label),
				pinned: metric.editProps.pinned,
			}
			switch (metric.editProps.type) {
				case metricTypes.MANUAL:
					requestBody.value = util.capitalize(metric.editProps.displayValue)
					requestBody.cycle = metric.editProps.cycle
					break
				case metricTypes.URL:
					requestBody.value = metric.editProps.displayValue
					requestBody.url = metric.editProps.url
					requestBody.path = metric.editProps.path
					break
				case metricTypes.INTEGRATION:
					requestBody.value = ''
					requestBody.providerId = metric.editProps.providerId
					requestBody.providerDataPointId = metric.editProps.providerDataPointId
			}
			return requestBody
		},
		getUpdateRequestBody(metric) {
			let requestBody = {}
			metric.getChanges().forEach(prop => {
				let changedProp = metric.ep[prop]
				if (prop === 'displayValue') requestBody.value = changedProp
				else requestBody[prop] = changedProp
			})
			return requestBody
		},
		refreshMetricProviders() {
			return new Promise((resolve, reject) => {
				this.$options.dataService
					.getProviders()
					.then(response => {
						const available = response.data.available_providers,
							connected = response.data.connected_providers
						let providers
						if (available && connected) {
							providers = available.concat(connected)
						} else if (available) {
							providers = available
						} else if (connected) {
							providers = connected
						}
						providers.forEach(provider => {
							const id = provider.id
							let model = null
							if (this.providers.hasOwnProperty(id)) {
								model = this.providers[id]
							} else {
								model = new MetricProviderModel()
								model.teamUuid = this.teamUuid
								Vue.set(this.providers.items, id, model)
							}
							model.updateData(provider)
						})
						Vue.set(this.providers, 'loaded', true)
					})
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
	},
}))()
