import Vue from 'vue'
import itemModel from '@/models/mixins/itemModelMixin'
import { metricTypes, metricCycles, dateRolloverHour } from '@/constants'
import util from '@/util'

export default Vue.extend({
	mixins: [itemModel],
	data() {
		return {
			id: null,
			label: '',
			value: '',
			type: null,
			pinned: false,
			archived: false,
			cycle: 'none',
			createdServer: null,
			modifiedServer: null,
			modified: null,
			path: '',
			providerDataPointId: '', // please keep as '' and not null in order for default (empty) select option to appear selected
			providerId: null,
			url: '',
			// readOnly: false,
			props: [
				'pinned',
				/*'readOnly',*/ 'label',
				'displayValue',
				'type',
				'archived',
				'cycle',
				'path',
				'providerDataPointId',
				'providerId',
				'url',
			],
		}
	},
	computed: {
		displayValue: {
			get() {
				if (this.cycle && this.cycle !== metricCycles.NONE && this.getStartOfCycle() > this.getModifiedDate()) {
					return 0
				}
				return this.value
			},
			set(value) {
				this.value = value
				this.modified = util.activeLocalDateTimeStringForDate(new Date())
			},
		},
		disableSave() {
			let unconnectedProvider = false
			if (this.editProps.type === metricTypes.INTEGRATION) {
				if (!this.editProps.providerId || !this.editProps.providerDataPointId) unconnectedProvider = true
			}
			return this.editProps.type === null || unconnectedProvider
		},
	},
	methods: {
		updateData: function (metric) {
			this.type = this.getMetricType(metric)
			this.label = metric.label
			this.value = metric.value
			this.pinned = metric.pinned
			this.id = metric.id
			this.archived = metric.archived
			this.cycle = metric.cycle
			this.path = metric.path
			this.createdServer = metric.createdServer
			this.modifiedServer = metric.modifiedServer
			this.providerDataPointId = metric.providerDataPointId
			this.providerId = metric.providerId
			this.url = metric.url
			// this.readOnly = metric.readOnly
			this.revertChanges()
		},
		getChanges() {
			let changes = []
			if (this.editProps) {
				this.props.forEach(prop => {
					if (typeof this.editProps[prop] === 'object' && this.editProps[prop] !== null) {
						// null is an object btw! Thanks Javascript!
						// handles 'dateEdit' property, breaking each key into separate updated properties
						for (let key in this.editProps[prop]) {
							let changed,
								originalProp = this[prop][key],
								updatedProp = this.editProps[prop][key]

							if (originalProp && originalProp.getTime && updatedProp && updatedProp.getTime) {
								changed = originalProp.getTime() !== updatedProp.getTime()
							} else {
								changed = originalProp !== updatedProp
							}
							if (changed) {
								changes.push(key)
								this.editProps[key] = updatedProp
							}
						}
					} else if (this[prop] !== this.editProps[prop]) {
						changes.push(prop)
					}
				})
			}
			return changes
		},
		getMetricType(metric) {
			if (metric.url) return metricTypes.URL
			if (metric.providerId) return metricTypes.INTEGRATION
			else return metricTypes.MANUAL
		},

		// Cycle Helpers
		getStartOfCycle: function () {
			return this[this.cycle + 'Start'] && this[this.cycle + 'Start']()
		},
		dailyStart: function () {
			const dateParts = util.activeDateString().split(/\D/)
			const start = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
			start.setHours(dateRolloverHour)
			return start.getTime()
		},
		weeklyStart: function () {
			let d = new Date(this.dailyStart())
			d.setDate(d.getDate() - ((d.getDay() + 6) % 7))
			return this.getMomoDate(d)
		},
		monthlyStart: function () {
			let d = new Date(this.dailyStart())
			d.setDate(1)
			return this.getMomoDate(d)
		},
		yearlyStart: function () {
			let d = new Date(this.dailyStart())
			d.setDate(1)
			d.setMonth(0)
			return this.getMomoDate(d)
		},
		getMomoDate: function (date) {
			let start = new Date(date.getFullYear(), date.getMonth(), date.getDate())
			start.setHours(dateRolloverHour)
			return start.getTime()
		},
		getModifiedDate: function () {
			if (this.modified) return Date.parse(this.modified)
			if (this.modifiedServer) return Date.parse(this.modifiedServer)
		},
	},
})
