import Vue from 'vue'
import { baseDataService } from './mixins/itemDataServiceMixins'

export default new (Vue.extend({
	defaultPath: '/team/metrics',
	mixins: [baseDataService],
	methods: {
		getProviders() {
			return new Promise((resolve, reject) => {
				this.$http
					.get('team/metrics/providers')
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		connectProvider(providerId) {
			return new Promise((resolve, reject) => {
				this.$http
					.put('settings/metrics/providers', { provider_id: providerId, for_team: true })
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		getProviderDataPoints(providerId) {
			return new Promise((resolve, reject) => {
				this.$http
					.get(`settings/metrics/providers/${providerId}/dataPoints`)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		getPathOptions(url) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('metrics/preview', { url })
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},
}))()
