<template>
	<div ref="actionDiv">
		<button v-if="showAddLinkButton" type="button" class="button button-neutral button-smaller add-extra" @click="toggleAction">+ Link</button>
		<div v-else-if="showCtaFields" class="option-wrapper form-row">
			<div class="option-form cta-wrapper">
				<div class="cta-input-group">
					<div class="cta-input cta-text">
						<label>Link Text</label>
						<div class="option-form">
							<input
								ref="text"
								v-model="inputCtaText"
								v-focus
								:disabled="disableInput"
								:class="{ 'error': highlightRequiredComponentFields.indexOf('inputCtaText') !== -1 }"
								type="text"
								name="input-cta-text"
								@change="updateAction"
								@keydown.enter.stop.prevent="updateAction"
							/>
						</div>
					</div>
					<div class="cta-input cta-url">
						<label>URL</label>
						<div class="option-form">
							<input
								ref="url"
								v-model="inputUrl"
								:disabled="disableInput"
								:class="{ 'error': highlightRequiredComponentFields.indexOf('inputUrl') !== -1 }"
								type="text"
								name="input-url"
								@change="updateAction"
								@keydown.enter.stop.prevent="updateAction"
							/>
						</div>
					</div>
					<button v-if="!disableInput" class="button button-neutral button-smaller button-clear" type="button" @click="toggleAction">Clear</button>
					<button v-if="!disableInput" class="button button-neutral button-smaller x-clear" type="button" @click="toggleAction"><img class="icon icon-delete" src="@/img/icons/close-dark.svg"></button>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import eventBus from '@/eventBus'
import smoothReflow from 'vue-smooth-reflow'

export default {
	name: 'AnnouncementAction',
	mixins: [smoothReflow],
	props: {
		value: { type: Object, default: null }, // { ctaUrl, hasAction, ctaText, archived, draft }
		disableInput: { type: Boolean, default: false },
		highlightRequiredComponentFields: { type: Array, default: () => [] },
	},
	data() {
		return {
			customCta: false,
			inputUrl: '',
			inputCtaText: '',
			archived: false,
			draft: false,
		}
	},
	computed: {
		showAddLinkButton() {
			return this.disableInput ? false : !this.customCta
		},
		showCtaFields() {
			return this.customCta ? true : !this.disableInput
		},
	},
	watch: {
		value: {
			handler(newVal) {
				if (newVal) {
					if (newVal.hasAction) this.init(newVal)
					else this.init({ hasAction: false })
				}
			},
			deep: true,
			immediate: true,
		},
	},
	created() {
		this.archived = this.value.archived
		this.draft = this.value.draft
	},
	mounted() {
		eventBus.$on('modalClosed', this.onModalClosed)
	},
	destroyed() {
		eventBus.$off('modalClosed', this.onModalClosed)
	},
	methods: {
		init(newVal) {
			this.customCta = newVal.hasAction
			if (this.customCta) {
				this.inputUrl = newVal.ctaUrl
				this.inputCtaText = newVal.ctaText
			}
		},
		toggleAction() {
			this.$smoothReflow({
				el: this.$refs.actionDiv,
			})
			this.customCta = !this.customCta
			if (!this.customCta) {
				this.inputCtaText = ''
				this.inputUrl = ''
				this.updateAction()
			}
		},
		updateAction() {
			this.$emit('input', {
				ctaUrl: this.inputUrl,
				hasAction: this.customCta,
				ctaText: this.inputCtaText,
			})
		},
		onModalClosed() {
			if (this.value) this.customCta = this.value.ctaUrl && this.value.ctaText
		},
		focus() {
			if (this.inputUrl && !this.inputCtaText) this.$refs.text && this.$refs.text.focus()
			else if (this.inputCtaText && !this.inputUrl) this.$refs.url && this.$refs.url.focus()
		},
	},
}
</script>

<style scoped>
	.cta-input-group {  --button-margin-top: 23px; display: flex; align-items: center; }
		.cta-wrapper { padding-top: 0; position: relative; }
		.cta-input { margin-right: var(--form-element-margin-small); }
			.cta-text { flex: 0 0 30%; }
			.cta-url { flex: 0 0 50%; }
		.button-clear { margin-top: var(--button-margin-top); margin-right: unset; }
		.x-clear { width: 30px; }

	@media handheld and (max-width: 959px), screen and (max-device-width: 959px), screen and (max-width: 959px) {
		.button-clear { display: none; }
		.x-clear { margin-top: var(--button-margin-top); display: block; }
	}

	@media handheld and (max-width: 740px), screen and (max-device-width: 740px), screen and (max-width: 740px) {
		.cta-input-group { flex-direction: column; align-items: unset; }
			.cta-input:not(:last-child) { margin-bottom: var(--form-element-margin); }

		.button-clear { margin-top: unset; display: block; }
		.x-clear { display: none; }
	}
</style>
