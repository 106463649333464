import Vue from 'vue'
import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	mixins: [itemModel],
	data: function () {
		return {
			id: null,
			body: '',
			source: '',
			date: null,
			props: ['body', 'source'],
		}
	},
	methods: {
		updateData(quote) {
			this.id = quote.quoteUuid
			this.body = quote.body
			this.source = quote.source
		},
	},
})
