import Vue from 'vue'

export default Vue.extend({
	data: function () {
		return {
			integrationId: null,
			providerTitle: '',
			smallIconUrl: '',
			editing: false,
			status: null,
		}
	},

	computed: {},

	methods: {
		startEditing: function () {
			Vue.set(this, 'editing', true)
			Vue.set(this, 'status', null)
		},
		updateData: function (integration) {
			this.providerTitle = integration.providerTitle
			this.smallIconUrl = integration.smallIconUrl
			this.integrationId = integration.id
		},
	},
})
