import Vue from 'vue'
import quoteService from '@/services/teamQuoteDataService'
import QuoteModel from '@/models/QuoteModel'
import util from '@/util'
import { baseManager } from './mixins/itemManagerMixins'

export default new (Vue.extend({
	Model: QuoteModel,
	dataService: quoteService,
	serverIdProperty: 'quoteUuid',
	mixins: [baseManager],
	data: function () {
		return {
			addInfo: {
				id: 'quotes',
				title: 'Quotes',
				singularItemTitle: 'Quote',
				description: 'Daily reminders, lessons, company messaging',
				addTitle: 'What quote would you like to add?',
				iconName: 'quote',
				props: [
					{
						name: 'body',
						default: '',
						class: 'quote-body has-quotes',
						type: 'input',
						label: 'Quote',
						required: true,
						warning: false,
						dataTest: 'body',
						validate: {
							maxLength: 250,
						},
					},
					{
						name: 'source',
						default: '',
						class: 'quote-source has-endash',
						type: 'input',
						label: 'Source',
						dataTest: 'source',
						optionalLabel: true,
						required: false,
						warning: false,
						validate: {
							maxLength: 100,
						},
					},
				],
			},
		}
	},

	methods: {
		getAddRequestBody(quote) {
			return {
				body: util.ensurePeriodEnd(util.stripQuotationMarks(quote.editProps.body)),
				source: quote.editProps.source ? quote.editProps.source.trim() : '',
			}
		},
		getUpdateRequestBody(quote) {
			let requestBody = {}
			quote.getChanges().forEach(prop => {
				let changedProp = quote.ep[prop]
				if (prop === 'source' && changedProp === '') changedProp = ''
				requestBody[prop] = changedProp
			})
			return requestBody
		},
	},
}))()
