import { errorMessages } from '@/constants'
import eventBus from '@/eventBus'
import { Collection } from '@/models/CollectionModel'
import Vue from 'vue'

export const baseManager = {
	data() {
		return {
			collection: new Collection(),
		}
	},
	computed: {
		data() {
			/*
				The difference between data and collection is that accessing data will trigger the refresh request if the collection has not yet been loaded.
				data should also be overridden if data processing is needed, e.g. removing sublinks and adding them to their parent links.
			*/
			if (!this.collection.loaded) this.refresh()
			return this.collection
		},
	},
	created() {
		eventBus.$on('resetCollections', this.resetCollection)
	},
	methods: {
		// CRUD METHODS
		refresh() {
			return new Promise((resolve, reject) => {
				this.$options.dataService
					.get({ path: this.$options.refreshPathOverride || this.$options.pathOverride })
					.then(items => {
						items.forEach(item => {
							const id = item[this.$options.serverIdProperty || 'id']
							// TODO: remove serverIdProperty when ids are consistent
							let model
							if (this.collection.items.hasOwnProperty(id)) {
								model = this.collection.items[id]
							} else {
								model = new this.$options.Model()
								this.$set(this.collection.items, id, model)
							}
							model.updateData(item)
						})
						if (this.collection.items.hasOwnProperty('placeholder')) {
							delete this.collection.items.placeholder
						}
						this.$set(this.collection, 'loaded', true)
						resolve(items)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		add(item) {
			this.addPlaceholderItem(item)
			const requestBody = this.getAddRequestBody && this.getAddRequestBody(item)
			if (!requestBody) {
				console.error('getAddRequestBody method missing from manager')
				return
			}
			return new Promise((resolve, reject) => {
				this.$options.dataService
					.add(requestBody, { path: this.$options.pathOverride })
					.then(response => {
						this.onAddSuccess && this.onAddSuccess(item, response)
						resolve(response)
					})
					.catch(error => {
						this.onAddFail && this.onAddFail(item, error)
						this.$delete(this.data.items, 'placeholder')
						reject(error)
					})
			})
		},
		update(item, uncategorized) {
			const requestBody = this.getUpdateRequestBody && this.getUpdateRequestBody(item, uncategorized)
			return new Promise((resolve, reject) => {
				this.$options.dataService
					.update(item.id, requestBody, { path: this.$options.pathOverride })
					.then(response => {
						this.onUpdateSuccess && this.onUpdateSuccess(item, response)
						resolve(response)
					})
					.catch(error => {
						this.onUpdateFail && this.onUpdateFail(item, error)
						console.log(error)
						reject(error)
					})
			})
		},
		delete(item) {
			return new Promise((resolve, reject) => {
				this.$options.dataService
					.delete(item.id, { path: this.$options.pathOverride })
					.then(response => {
						this.$delete(this.data.items, item.id)
						this.onDeleteSuccess && this.onDeleteSuccess(item, response)
						resolve(response)
					})
					.catch(error => {
						this.onDeleteFail && this.onDeleteFail(item, error)
						console.log(error)
						reject(error)
					})
			})
		},
		getUpdateRequestBody(item) {
			let requestBody = {}
			item.getChanges().forEach(prop => (requestBody[prop] = item.ep[prop]))
			return requestBody
		},
		addPlaceholderItem(item) {
			// Optimistically adds a placeholder item so that the modal can accommodate the new height when closing. Placeholder will be deleted on item refresh call
			let placeholder = new this.$options.Model()
			placeholder.updateData(item.ep)
			this.$set(this.data.items, 'placeholder', item)
		},
		resetCollection() {
			this.collection = new Collection()
		},
	},
}

export const categoryManager = {
	// Override baseManager by including this after baseManager in the mixins array
	computed: {
		categoryOptions() {
			// Used for <select> elements
			return Object.values(this.data.items)
				.sort((a, b) => a.order - b.order)
				.map(category => ({ label: category.title, value: category.id }))
		},
	},
	methods: {
		refresh() {
			this.$options.dataService
				.get({ path: this.$options.refreshPathOverride || this.$options.pathOverride })
				.then(data => {
					data.categories.forEach(category => {
						const id = category.id
						let model
						if (this.collection.hasOwnProperty(id)) {
							model = this.collection[id]
						} else {
							model = new this.$options.Model()
							model.teamUuid = this.teamUuid
							this.$set(this.collection.items, id, model)
						}
						model.updateData(category)
					})

					data[this.$options.itemNameFromServer].forEach(item => {
						const id = item.id
						let model,
							category = this.collection.items[item.categoryId]
						if (category.items.hasOwnProperty(id)) {
							model = category.items[id]
						} else {
							model = new this.$options.ItemModel()
							model.teamUuid = this.teamUuid
							Vue.set(category.items, id, model)
						}
						model.updateData(item)
					})

					let uncategorized = this.collection.items.uncategorized
					if (!uncategorized) {
						uncategorized = new this.$options.Model()
						uncategorized.updateData({
							title: 'Uncategorized',
							id: 'uncategorized',
							order: 100000,
						})
						this.collection.items.uncategorized = uncategorized
					}

					data.uncategorized &&
						data.uncategorized.forEach(item => {
							let model
							/*
						TODO: update this comment for topics once uncategorized topics are implemented

						Uncategorized apps do not have the same type of id as categorized apps, they have an appId instead.
						The difference is that categorized ids refer to the link between the app and its category, whereas
						the appId is an id for the app itself. So we are setting the id of the uncategorized app models with
						the appIds and then when they are categorized we will receive the new proper id from the server and
						update the id. Updating an uncategorized app using its appId will require the additional property:
						{ uncategorized: true }.
					*/
							item.id = item[this.$options.uncategorizedIdKey]
							if (!uncategorized.items.hasOwnProperty(item.id)) {
								model = new this.$options.ItemModel()
								this.$set(uncategorized.items, item.id, model)
							} else {
								model = uncategorized.items[item.id]
							}
							model.updateData(item)
						})

					if (this.collection.items.hasOwnProperty('placeholder')) delete this.collection.items.placeholder
					this.$set(this.collection, 'loaded', true)
				})
		},
		onDeleteSuccess() {
			this.refresh()
		},
	},
}

export const categorizedItemManager = {
	// Override baseManager by including this after baseManager in the mixins array
	data() {
		return {
			activeCategoryId: null,
		}
	},
	computed: {
		data() {
			return this.activeCategoryId ? this.$options.categoryManager.collection.items[this.activeCategoryId] : null
		},
		categoryOptions() {
			return this.$options.categoryManager.categoryOptions
		},
	},
	methods: {
		refresh() {
			this.$options.categoryManager.refresh()
		},
		setActiveCategoryId(id) {
			this.activeCategoryId = id
		},
		clearActiveCategoryId() {
			this.activeCategoryId = null
		},
	},
}

export const reorder = {
	/*
	item is a placeholder for link, countdowns, etc.

	** Manager **

	import reorderItems from @/managers/mixins/reorderItemsMixin
	import {itemDataService} from @/services/itemDataService

	export default {
		dataService: itemDataService,
		pathOverride: '/items/categories' (only needed if you wish to override the defaultPath in the dataService
		mixins: [reorderItems]
	}


	** Model **

	data() {
		...
		dragging: false,
		order: null,
	},
	methods: {
		updateData(item) {
		 	...
			this.order = item.order
		}
	}


	** Data Service **
		add the dataService reorder
 	*/

	methods: {
		redistributeOrderProperties() {
			Object.values(this.data.items)
				.sort((a, b) => a.order - b.order)
				.forEach((item, index) => {
					this.$set(item, 'order', index * 10)
				})
		},
		reorder(target, moveTarget, before, adjacentItem, switchPinnedStateTo = null) {
			let newOrder,
				redistributeOrder = false
			if (moveTarget && !moveTarget.separator) {
				if (adjacentItem && adjacentItem.order === moveTarget.order) this.redistributeOrderProperties()

				if (before) {
					// Moving to the beginning
					newOrder = moveTarget.order - 10
				} else if (typeof adjacentItem !== 'undefined' && !adjacentItem.separator) {
					// Moving somewhere in the middle
					newOrder = Math.round((adjacentItem.order - moveTarget.order) / 2 + moveTarget.order)
					if (Math.abs(moveTarget.order - adjacentItem.order) <= 2) redistributeOrder = true
					// ^ If moveTarget.order and adjacentItem.order get too close, reassign the orders
				} else {
					// Moving to the end
					newOrder = moveTarget.order + 10
				}
				if (target.order === newOrder && switchPinnedStateTo === null) return // Didn't move
				const oldOrder = target.order // Save old order in case of failure
				this.$set(target, 'order', newOrder) // Save new order to model
				return new Promise((resolve, reject) => {
					let requestBody = {
						move_id: target.id,
						move_target_id: moveTarget && moveTarget.id,
						before,
					}
					if (switchPinnedStateTo !== null) requestBody.pinned = switchPinnedStateTo
					this.$options.dataService
						.reorder(requestBody, { path: this.$options.pathOverride }) // to override the default path, add pathOverride to the manager
						.then(response => {
							if (redistributeOrder) this.redistributeOrderProperties()
							resolve(response)
						})
						.catch(error => {
							this.$set(target, 'order', oldOrder) // Reset order if operation fails
							if (switchPinnedStateTo !== null) this.$set(target, 'pinned', !switchPinnedStateTo)
							eventBus.$emit('flashMessage', {
								message: errorMessages.SERVER_ERROR_GENERAL,
								icon: 'error',
							})
							reject(error)
						})
				})
			} else if (switchPinnedStateTo !== null) {
				return new Promise((resolve, reject) => {
					this.$options.dataService
						.update(target.id, { pinned: switchPinnedStateTo })
						.then(response => {
							resolve(response)
						})
						.catch(error => {
							if (switchPinnedStateTo !== null) this.$set(target, 'pinned', !switchPinnedStateTo)
							reject(error)
						})
				})
			}
		},
	},
}
