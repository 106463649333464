import Vue from 'vue'
import store from '@/store'
export default new (Vue.extend({
	methods: {
		getSubscriptions() {
			return new Promise((resolve, reject) => {
				this.$http
					.get((store.userInfo.isAdmin ? 'team/' : '') + 'account/subscriptions')
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		subscribe(stripeToken, plan) {
			return new Promise((resolve, reject) => {
				this.$http
					.post((store.userInfo.isAdmin ? 'team/' : '') + 'account/subscriptions', {
						stripe_token: stripeToken,
						plan_id: plan,
					})
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		resubscribe(stripeToken, name, recaptchaToken) {
			let requestBody = { resubscribe: true, recaptcha: recaptchaToken }
			if (stripeToken) requestBody.stripe_token = stripeToken
			if (name) requestBody.name = name
			return new Promise((resolve, reject) => {
				this.$http
					.post((store.userInfo.isAdmin ? 'team/' : '') + 'account/subscriptions', requestBody)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		subscribeWithEmail(
			stripeToken,
			planId,
			action,
			email,
			name,
			recaptchaResult,
			extensionVersion,
			couponId,
			mailingLists
		) {
			const data = {
				stripe_token: stripeToken,
				plan_id: planId,
				action: action,
				email: email,
				name: name,
				recaptcha: recaptchaResult,
				extension_version: extensionVersion,
				mailing_lists: mailingLists,
			}
			// Add coupon_code
			if (couponId) data.coupon_code = couponId
			return this.$http
				.post((store.userInfo.isAdmin ? 'team/' : '') + 'account/subscriptions', data)
				.then(response => response.data)
		},
		subscribeFromTrial(subscriptionId, requestBody) {
			return this.$http.post(`/team/account/subscriptions/${subscriptionId}/setup`, requestBody)
		},
		confirmSubscription(mailingLists) {
			const data = {
				mailing_lists: mailingLists,
			}
			// This will refresh the cache for a user's feature and resubscribe to the mailing lists
			return this.$http
				.post((store.userInfo.isAdmin ? 'team/' : '') + 'account/subscriptions/confirm', data)
				.then(response => response.data)
		},
		switchPlanTo(subscriptionId, planId) {
			return this.$http
				.get(
					(store.userInfo.isAdmin ? 'team/' : '') +
						'account/subscriptions/' +
						subscriptionId +
						'/preview_plan_change?plan_id=' +
						encodeURIComponent(planId)
				)
				.then(response => response.data)
		},
		cancel(info) {
			return new Promise((resolve, reject) => {
				this.$http
					.post((store.userInfo.isAdmin ? 'team/' : '') + 'account/subscriptions/cancel', info)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		resume(subscription) {
			return new Promise((resolve, reject) => {
				this.$http
					.patch((store.userInfo.isAdmin ? 'team/' : '') + subscription.resumeUrl + '', {
						subscription_id: subscription.id,
						action: 'resume',
					})
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		renewSubscription(planId) {
			return new Promise((resolve, reject) => {
				this.$http
					.post((store.userInfo.isAdmin ? 'team/' : '') + 'account/subscriptions', {
						plan_id: planId,
					})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						let message = null
						if (!error.response.success && error.response.message) {
							message =
								'The message from your bank or the payment provider was: "' +
								error.response.message +
								'"'
						}
						reject(message)
					})
			})
		},
		confirmSwitch(subscription) {
			return new Promise((resolve, reject) => {
				this.$http
					.patch((store.userInfo.isAdmin ? 'team/' : '') + subscription.changePlanUrl + '', {
						plan_id: subscription.plan.otherId,
						action: 'change_plan',
					})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						let message = null
						if (error && error.response) {
							message = error.response.message
						}
						reject(message)
					})
			})
		},
		getGifts() {
			return new Promise((resolve, reject) => {
				this.$http
					.get('account/gifts')
					.then(response => {
						resolve(response.data.coupons)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		retryLastChargeOnPrimaryCard(subscriptionId) {
			return new Promise((resolve, reject) => {
				this.$http
					.post(`${store.userInfo.isAdmin ? 'team/' : ''}account/subscriptions/${subscriptionId}/retry`)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		getPlans() {
			return this.$http.get(`account/plans`).then(response => response.data)
		},
		getCoupon(couponId) {
			return this.$http.get('coupons/' + couponId).then(response => response.data)
		},
	},
}))()
