import Vue from 'vue'
// import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	// mixins: [itemModel],
	data: function () {
		return {
			id: 1,
			title: '',
			beta: false,
			largeIconUrl: '',
			status: '',
			statusId: null,
			umpId: null,
			smallIconUrl: '',
			shortDescription: '',
			dataPoints: [],
			// props: ['id', 'active', 'beta', 'largeIconUrl', 'smallIconUrl', 'title', 'shortDescription'],
		}
	},
	computed: {
		connected() {
			return this.status === 'Connected'
		},
	},
	methods: {
		updateData(provider) {
			this.beta = provider.beta
			this.id = provider.id
			this.umpId = provider.ump_id
			this.status = provider.status
			this.statusId = provider.status_id
			this.umpId = provider.ump_id
			this.largeIconUrl = provider.large_icon_url
			this.smallIconUrl = provider.small_icon_url
			this.title = provider.provider_title
			this.shortDescription = provider.short_description
		},
	},
})
