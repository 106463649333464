import Vue from 'vue'
import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	mixins: [itemModel],
	simpleDateOptions: { month: 'long', day: 'numeric' },
	fullDateOptions: {
		hour: 'numeric',
		minute: '2-digit',
		weekday: 'long',
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	},
	data: function () {
		return {
			id: null,
			description: '',
			token: '',
			created: null,
			createdBy: null,
			createdByName: null,
			props: ['description', 'token', 'id'],
		}
	},
	computed: {
		createdDateObject() {
			return new Date(this.created)
		},
		simpleDate() {
			return this.createdDateObject.toLocaleDateString('en-US', this.$options.simpleDateOptions)
		},
		fullDate() {
			return this.createdDateObject.toLocaleDateString('en-US', this.$options.fullDateOptions)
		},
	},
	methods: {
		updateData(apiKey) {
			this.description = apiKey.description
			this.token = apiKey.token
			this.id = apiKey.id
			this.created = apiKey.created
			this.createdBy = apiKey.createdBy
			this.createdByName = apiKey.createdByName
			this.revertChanges()
		},
	},
})
