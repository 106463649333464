import Vue from 'vue'
import store from '@/store'
import ResizeSensor from '../public/ResizeSensor'

const map = {}

export default function () {
	Vue.directive('focus', {
		inserted: function (el, options) {
			if (options.value === false) return
			el.focus()
		},
	})
	Vue.directive('click-outside', {
		bind: function (el, binding) {
			let downTarget, scrollbarClicked
			el.dataset.justBoundClickOutsideHandler = true
			setTimeout(() => {
				el.dataset.justBoundClickOutsideHandler = false
			}, 100)
			const inScrollbar = e => e && e.clientX > window.innerWidth - store.interfaceState.scrollbarWidth
			const mousedownHandler = e => {
				scrollbarClicked = false
				if (inScrollbar(e)) {
					scrollbarClicked = true
					return
				}
				downTarget = e.target
			}
			const mouseupHandler = e => {
				if (scrollbarClicked) return
				if (
					binding.modifiers.bubble ||
					(!el.contains(downTarget) &&
						!el.contains(e.target) &&
						el !== downTarget &&
						el !== e.target &&
						el.dataset.justBoundClickOutsideHandler !== 'true')
				) {
					binding.value(e)
				}
			}
			el.dataset.clickOutsideMouseupHandlerId = Math.random().toString(36).substring(7)
			el.dataset.clickOutsideMousedownHandlerId = Math.random().toString(36).substring(7)
			map[el.dataset.clickOutsideMouseupHandlerId] = mouseupHandler
			map[el.dataset.clickOutsideMousedownHandlerId] = mousedownHandler
			document.addEventListener('mouseup', mouseupHandler)
			document.addEventListener('mousedown', mousedownHandler)
		},
		unbind: function (el) {
			document.removeEventListener('mouseup', map[el.dataset.clickOutsideMouseupHandlerId])
			document.removeEventListener('mousedown', map[el.dataset.clickOutsideMousedownHandlerId])
			delete map[el.dataset.clickOutsideMouseupHandlerId]
			delete map[el.dataset.clickOutsideMousedownHandlerId]
			delete el.dataset.clickOutsideMouseupHandlerId
			delete el.dataset.clickOutsideMousedownHandlerId
			delete el.dataset.justBoundClickOutsideHandler
		},
	}),
		Vue.directive('resize-sensor', {
			bind(el, binding) {
				el.dataset.resizeSensorId = Math.random().toString(36).substring(7)
				map[el.dataset.resizeSensorId] = new ResizeSensor(el, binding.value)
			},
			unbind(el) {
				map[el.dataset.resizeSensorId] && map[el.dataset.resizeSensorId].detach()
				delete map[el.dataset.resizeSensorId]
			},
		})
}
