import Vue from 'vue'
import store from '@/store'
import uploadManager from '@/managers/uploadManager'
import teamDataService from '@/services/teamDataService'

export default new (Vue.extend({
	methods: {
		resetTeamData() {
			store.teamInfo.activeTeam && store.teamInfo.activeTeam.resetTeamData()
		},
		refreshTeams() {
			return store.teamInfo.refreshTeams()
		},
		updateTeam(teamUuid, updateInfo) {
			return new Promise((resolve, reject) => {
				teamDataService
					.editTeam(teamUuid, updateInfo)
					.then(response => {
						this.refreshTeams()
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		submitLogo: function (file) {
			uploadManager.initialize()
			if (file.type.startsWith('image/')) {
				let photo = {
					fileprogress: 0,
					file: file,
					original_filename: file.name,
				}
				let submission = {
					filename: photo.file.name,
					type: photo.file.type,
					size: photo.file.size,
				}
				this.$http
					.post('team/logo', submission)
					.then(response => {
						if (response && response.data.success) {
							let data = response.data
							photo.file_upload_uri = data.file_upload_uri
							photo.file_uuid = data.file_uuid
							uploadManager.uploadFile(photo)
							store.teamInfo.activeTeam.teamLogoFileUuid = photo.file_uuid
							store.teamInfo.activeTeam.teamLogoUrl = data.file_download_uri
						}
					})
					.catch(err => {
						console.log('error: ' + err)
						photo.upload_failed = true
						this.inProgress = false
					})
				return photo
			}
		},
		getBasicTeamInfo(shortName) {
			return new Promise((resolve, reject) => {
				teamDataService
					.getBasicTeamInfo(shortName)
					.then(response => {
						Vue.set(
							store,
							'basicTeamInfo',
							Object.assign({}, store.basicTeamInfo, { loaded: true }, response)
						)
						resolve(store.basicTeamInfo)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},
}))()
