import Vue from 'vue'
import { navVisibilityStates } from './constants'
// Be careful when importing anything here, store is imported in most files so it is easy to cause a circular import issue

export default {
	time: Date.now(),
	interfaceState: {
		mobileViewActive: false,
		bodyClass: '',
		sectionClass: '',
		mobileMenuIsOpen: false,
		userMenuIsOpen: false,
		userMenuPersonalHeight: 80,
		navVisibility: navVisibilityStates.HIDDEN,
		scrollbarWidth: 0,
		blockBanner: false, // used for temporary blocks on route/page enter
		showBanner: false, // decided in Banner component,
		overlayActive: false,
	},
	userInfo: {},
	teamInfo: {},
	basicTeamInfo: { uuid: '', name: '', logo: '' }, // used in community auth pages
	authInfo: {},
	planInfo: { loaded: false },
	subscriptionInfo: {
		loaded: false,
		error: null,
		renewError: null,
	},
	giftInfo: {
		coupons: [],
		loaded: false,
		error: null,
	},
	teamActive: false,
	authState: {
		states: {
			LOGIN: 1,
			REGISTER: 2,
		},
		state: 1,
	},
	browser: {
		isChrome: null,
		isFirefox: null,
		isEdge: null,
		isSafari: null,
	},
	country: null,
	language: null,
	$set(path, subPath, value) {
		Vue.set(path, subPath, value)
	},
}
