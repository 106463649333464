<template>
	<div :style="{ '--modal-width': modalWidth + 'px', right: `${overlayScrollbarAllowance}px` }" class="modal-overlay">
		<span class="close" @click="close"><img src="../../img/icons/close.svg"></span>
		<div v-click-outside="close" :class="modalClass" class="modal-content box">
			<slot @close="close"></slot>
		</div>
	</div>
</template>



<script type="text/babel">
import eventBus from '@/eventBus'

export default {
	name: 'FullModal',
	store: ['interfaceState'],
	props: {
		modalClass: { type: String, default: '' },
		modalWidth: { type: Number, default: 500 },
	},
	data() {
		return {
			overlayScrollbarAllowance: 0,
		}
	},
	created() {
		this.interfaceState.overlayActive = true
	},
	mounted() {
		window.addEventListener('keydown', this.escape)
		eventBus.$on('scrollbarChanged:body', this.handleScrollBarChange)
	},
	destroyed() {
		window.removeEventListener('keydown', this.escape)
		eventBus.$off('scrollbarChanged:body', this.handleScrollBarChange)
	},
	methods: {
		close() {
			this.interfaceState.overlayActive = false
			this.$emit('close')
		},
		escape(e) {
			if (e && e.keyCode === 27) {
				this.$emit('close')
			}
		},
		handleScrollBarChange(scrollbarWidth) {
			this.overlayScrollbarAllowance = scrollbarWidth
		},
	},
}
</script>

<style scoped>
	.modal-overlay .modal-content { width: var(--modal-width); }
</style>
