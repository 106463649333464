import Vue from 'vue'
import teamFocusService from '@/services/teamFocusDataService'
import FocusModel from '@/models/FocusModel'
import { baseManager } from './mixins/itemManagerMixins'

export default new (Vue.extend({
	Model: FocusModel,
	dataService: teamFocusService,
	refreshPathOverride: '/team/focuses',
	mixins: [baseManager],
	data() {
		return {
			addInfo: {
				id: 'focus',
				title: 'Team Goal',
				singularItemTitle: 'Team Goal',
				addTitle: `What is your team’s goal this week?`,
				description: 'Set a goal for your team to appear just below the daily focus',
				iconName: 'teamGoal',
				props: [
					{
						name: 'label',
						label: 'Label',
						class: 'item-name',
						type: 'input',
						alwaysShowInList: true,
						optionalLabel: true,
						dataTest: 'label',
					},
					{
						name: 'title',
						label: 'Goal',
						default: '',
						class: 'item-detail',
						type: 'input',
						required: true,
						warning: false,
						dataTest: 'title',
					},
					{
						name: 'active',
						default: false,
						type: 'toggle',
						hiddenInList: true,
						label: 'Active',
						tip: 'Make the active team goal.',
						editingOnly: true,
						dataTest: 'active',
					},
				],
			},
		}
	},
	methods: {
		getAddRequestBody(focus) {
			return {
				label: focus.editProps.label,
				title: focus.editProps.title,
			}
		},
	},
}))()
