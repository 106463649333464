<template>
	<div class="statuses">
		<span class="status">Invited</span>
		<span class="resend action last" title="Resend Invite" @click.stop="resend">{{ buttonText }}</span>
	</div>
</template>



<script>
import { copy } from '@/constants'
import teamMemberManager from '@/managers/teamMemberManager'

export default {
	name: 'ManageMemberInvite',
	props: {
		item: { type: Object, required: true },
	},
	data() {
		return {
			sending: false,
		}
	},
	computed: {
		buttonText() {
			return this.sending ? copy.SENDING : copy.RESEND
		},
	},
	methods: {
		resend() {
			this.sending = true
			teamMemberManager
				.resendInvite(this.item)
				.then(() => (this.sending = false))
				.catch(() => (this.sending = false))
		},
	},
}
</script>



<style scoped>
	.last { margin-right: 0; }
</style>
