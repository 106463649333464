<template>
	<!--It seems we're not doing the color matching for now so related code is commented out-->
	<!--<aside v-if="navIsVisible" :style="backgroundStyle" class="u--bg sidebar">-->

	<aside
		v-if="navIsVisible"
		:class="{'user-menu-open': interfaceState.userMenuIsOpen }"
		:style="{ '--user-menu-personal-height': interfaceState.userMenuPersonalHeight + 'px' }"
		class="sidebar"
		data-test="nav"
	>
		<!-- <div class="background"><img id='side-bg' src='../img/bg/2.jpg'></div> -->

		<header>
			<h1 class="brand">
				<router-link v-if="navContentIsVisible" :to="userInfo.isTeamMember ? '/' : '/profile'" @click.prevent="navClicked('dash')"></router-link>
				<img src="../../img/logo-white.svg" alt="Momentum">
				<span>{{ momentumType }}</span>
			</h1>

			<div v-if="goToDashVisible" class="go-to-dash-wrapper">
				<div class="go-to-dash main-nav-item nav-item-wrapper" @click="launchMomentum">
					<span class="go-to-dash-icon-wrapper nav-icon-wrapper">
						<base-svg class="go-to-dash-icon" icon-name="dashboard"></base-svg>
					</span>

					<span class="go-to-dash-label">{{ launchExtensionLabel }}</span>
				</div>
			</div>
			<transition name="fade" mode="out-in" duration="100">
				<span v-if="navContentIsVisible && !overlayActive && !anonymousUser" class="u--mobile-only">
					<span v-if="!mobileNavOpen" key="open" class="mobile-menu-button mobile-menu-open" @click.prevent.stop="openMobileMenu">
						<img src="../../img/icons/menu.svg">
						<span class="mobile-menu-open-label">Menu</span>
					</span>
					<span v-else-if="mobileNavOpen" key="close" class="mobile-menu-button mobile-menu-close" @click.prevent.stop="closeMobileMenu">
						<img src="../../img/icons/close.svg">
					</span>
				</span>
			</transition>
		</header>
		<div class="main-nav-wrapper">
			<transition name="fade">
				<nav v-if="navContentIsVisible" class="main-nav" data-test="main-nav">
					<!-- Main nav -->
					<router-link
						v-for="(navItem, index) in filteredNavItems"
						:key="index"
						:to="navItem.route"
						class="main-nav-item"
						data-test="nav-item"
						@click.native="linkClicked()"
					>
						<span class="nav-icon-wrapper">
							<base-svg :icon-name="navItem.iconName" class="nav-icon"></base-svg>
						</span>{{ navItem.label }}
					</router-link>
					<span v-if="enableTestFlashMessage" class="main-nav-item" @click="testFlashMessage">Test Flash Message</span>
				</nav>
			</transition>
		</div>
		<user-menu v-if="navContentIsVisible && !anonymousUser"></user-menu>
		<div class="sidebar-background u--bg"></div>
	</aside>
</template>



<script>
import BaseSvg from '@/components/svgs/BaseSvg'
import UserMenu from '@/components/user/UserMenu'
import store from '@/store'
import eventBus from '@/eventBus'
import { navVisibilityStates } from '@/constants'
import extensionManager from '@/managers/extensionManager'
import util from '@/util'

export default {
	name: 'NavMenu',
	components: {
		UserMenu,
		BaseSvg,
	},
	data() {
		return {
			enableTestFlashMessage: false,
			launchingExtension: false,
		}
	},
	store: ['userInfo', 'authInfo', 'interfaceState', 'teamInfo', 'browser'],
	computed: {
		filteredNavItems() {
			return this.navItems.filter(navItem => this.showNavItem(navItem))
		},
		momentumType() {
			return this.userInfo.isTeamMember ? this.teamInfo.activeTeam.teamType : 'Account'
		},
		goToDashVisible() {
			return (
				this.navContentIsVisible &&
				this.currentMomoSupportingBrowser &&
				!this.anonymousUser &&
				// only show on mobile if it's a safari iOS or iPadOs device
				(!this.browser.isMobile || this.browser.isSafariIos)
			)
		},
		navItems() {
			return [
				{
					route: '/team/home',
					name: 'home',
					label: 'Home',
					iconName: 'home',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/team/settings',
					name: 'setup',
					label: 'Team Settings',
					iconName: 'setting',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/team/members',
					name: 'teamMembers',
					label: 'Team Members',
					iconName: 'members',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/team/announcements',
					name: 'announcements',
					label: 'Announcements',
					iconName: 'announcement',
					visibility: {
						admin: true,
					},
					requiredFeature: 'teamAnnouncement',
				},
				{
					route: '/team/metrics',
					name: 'metrics',
					label: 'Metrics',
					iconName: 'metrics',
					requiredFeature: 'team_metric',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/team/focus',
					name: 'focus',
					label: 'Goal',
					iconName: 'teamGoal',
					requiredFeature: 'team_focus',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/team/links',
					name: 'links',
					label: 'Links',
					iconName: 'links',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/team/countdowns',
					name: 'countdowns',
					label: 'Countdowns',
					iconName: 'countdown',
					requiredFeature: 'team_countdown',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/team/clocks',
					name: 'clocks',
					label: 'Clocks',
					iconName: 'clock',
					requiredFeature: 'team_clock',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/team/quotes',
					name: 'quotes',
					label: 'Quotes',
					iconName: 'quote',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/team/apps',
					name: 'apps',
					label: 'Apps',
					iconName: 'apps',
					requiredFeature: 'app_launcher',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/team/topics',
					name: 'topics',
					label: 'Topics',
					iconName: 'topics',
					requiredFeature: 'topics',
					visibility: {
						admin: true,
					},
				},
				{
					route: '/profile',
					name: 'profile',
					label: this.$t('profile.PROFILE'),
					iconName: 'profile',
					visibility: {
						free: true,
						plus: true,
					},
				},
				{
					route: '/upgrade',
					name: 'upgrade',
					label: this.$t('upgrade.UPGRADE'),
					iconName: 'upgrade',
					visibility: {
						free: true,
					},
				},
				{
					route: '/subscription',
					name: 'subscription',
					label: this.$t('subscription.SUBSCRIPTION'),
					iconName: 'subscription',
					visibility: {
						plus: true,
					},
				},
				{
					route: '/gifts',
					name: 'gifts',
					label: this.$t('gifts.GIFTS'),
					iconName: 'gifts',
					visibility: {
						plus: true,
						free: true,
					},
				},
			]
		},
		backgroundStyle() {
			return 'background-image:url(' + store.userInfo.background + ')'
		},
		mobileNavOpen() {
			return this.interfaceState.mobileMenuIsOpen
		},
		navIsVisible() {
			return (
				this.interfaceState.navVisibility === navVisibilityStates.VISIBLE ||
				this.interfaceState.navVisibility === navVisibilityStates.EMPTY
			)
		},
		navContentIsVisible() {
			return this.interfaceState.navVisibility === navVisibilityStates.VISIBLE
		},
		communityUser() {
			return this.userInfo.isCommunityMember
		},
		freeUser() {
			return (
				!this.userInfo.isTeamMember &&
				!this.userInfo.isAdmin &&
				!this.userInfo.activeSubscription &&
				this.authInfo.loggedIn
			)
		},
		plusUser() {
			return !this.userInfo.isTeamMember && !this.userInfo.isAdmin && this.userInfo.activeSubscription
		},
		anonymousUser() {
			return !this.authInfo.loggedIn
		},
		launchExtensionLabel() {
			return this.launchingExtension ? this.$t('copy.LOADING') : this.$t('copy.GO_TO_DASHBOARD')
		},
		currentMomoSupportingBrowser() {
			return util.getCurrentSupportedBrowserInfo()
		},
		overlayActive() {
			return this.interfaceState.overlayActive
		},
	},
	watch: {
		'interfaceState.navVisibility': {
			handler(newVal) {
				if (!this.html) this.html = document.getElementsByTagName('html')[0]
				if (newVal > 0) {
					this.html.classList.remove('no-sidebar')
				} else {
					this.html.classList.add('no-sidebar')
				}
			},
			immediate: true,
		},
		$route: {
			handler(route) {
				if (!route) return
				let navFromMeta = route.meta && route.meta.nav
				let navFromProp = route.meta && route.meta.prop && route.meta.prop.nav
				let navVisibility = false
				if (typeof navFromMeta === 'undefined' && typeof navFromProp !== 'undefined') {
					navVisibility = navFromProp
				} else if (typeof navFromMeta !== 'undefined' && typeof navFromProp === 'undefined') {
					navVisibility = navFromMeta
				} else {
					navVisibility = navFromMeta || navFromProp
				}

				if (!navVisibility && navVisibility !== 0) navVisibility = navVisibilityStates.VISIBLE
				this.$set(this.interfaceState, 'navVisibility', navVisibility)
			},
		},
	},
	methods: {
		openMobileMenu() {
			this.$set(store.interfaceState, 'mobileMenuIsOpen', true)
			this.$set(this.interfaceState, 'userMenuIsOpen', false)
			document.body.classList.add('no-scroll')
			this.$nextTick(() => {
				eventBus.$emit('mobileMenuOpened')
			})
		},
		closeMobileMenu() {
			this.$set(store.interfaceState, 'mobileMenuIsOpen', false)
			document.body.classList.remove('no-scroll')
		},
		linkClicked() {
			this.$set(this.interfaceState, 'userMenuIsOpen', false)
			this.closeMobileMenu()
		},
		navClicked(name) {
			this.navObject.selectedNav = name
		},
		showNavItem(navItem) {
			const vis = navItem.visibility
			return (
				((this.userInfo.isAdmin && vis.admin) ||
					(vis.free && this.freeUser) ||
					(vis.community && this.communityUser) ||
					(vis.plus && this.plusUser)) &&
				(!navItem.requiredFeature || this.teamInfo.activeTeam.hasFeature(navItem.requiredFeature))
			)
		},
		launchMomentum() {
			if (this.launchingExtension) return
			const browser = this.currentMomoSupportingBrowser
			if (!extensionManager.checkIfExtensionIsInstalled() && browser) {
				if (browser.setupLink) this.$router.push(browser.setupLink)
				else window.open(browser.momoInstallLink, '_blank')
			} else {
				this.launchingExtension = true
				extensionManager
					.launchExtensionAsCurrentUser()
					.catch(() => {
						this.$router.push('/launch-extension-error')
					})
					.finally(() => (this.launchingExtension = false))
			}
		},
	},
}
</script>



<style scoped>

.sidebar { height: 100%; width: var(--sidebar-width); position: fixed; top: 0; bottom: 0; left: 0; z-index: 3; display: flex; flex-direction: column; /* box-shadow: inset 117px 0 207px -56px rgba(0,0,0,0.2); */ }
	.sidebar:before { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: -2; background: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0)); content: ''; }

	.sidebar-background { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: -3; background-size: cover; background-repeat: no-repeat; }

	.main-nav-wrapper { min-height: 0; margin-bottom: var(--user-menu-personal-height); flex: 1 1 auto; display: flex; flex-direction: column; justify-content: center; transition: margin 0.3s ease; }
		/* ^ Do not remove min-height: 0; it is important for flex shrink ^ */
		.main-nav { display: flex; flex-direction: column; overflow-y: auto; }

			.main-nav-item { width: 100%; margin: auto; padding: 9px var(--sidebar-padding); position: relative; display: flex; align-items: center; color: white; cursor: pointer !important; font-size: 1.0625rem; -moz-osx-font-smoothing: grayscale; font-smoothing: antialiased; -webkit-font-smoothing: antialiased; text-align: left; text-decoration: none; text-rendering: optimizeLegibility; transition: all var(--transition-medium) ease; }
				.main-nav-item:hover { background: linear-gradient(to right, hsla(var(--color-hue), 20%, 80%, 0.25), hsla(var(--color-hue), 20%, 80%, 0.08)); text-shadow: 0 0 20px rgba(255,255,255,0.6); }
					.main-nav-item:hover:active { background: linear-gradient(to right, hsla(var(--color-hue), 20%, 80%, 0.4), hsla(var(--color-hue), 20%, 80%, 0.15)); text-shadow: 0 0 20px rgba(255,255,255,0.6); }

				.main-nav-item.router-link-active { background: linear-gradient(to right, hsla(var(--color-hue), 20%, 80%, 0.4), hsla(var(--color-hue), 20%, 80%, 0.1)) !important; letter-spacing: -0.3px; font-weight: 600; text-shadow: 0 0 20px rgba(255,255,255,0.5) !important; }
				.main-nav-item.off { display: none; }

				.nav-icon-wrapper { width: 20px; margin-right: 0.75rem; display: inline-flex; align-items: center; opacity: 0.75; }
					.nav-icon { height: 18px; fill: white; }
						.nav-icon.icon-subscription { height: 17px; }


	/* Go to dashboard */

	.go-to-dash-wrapper { position: relative; z-index: 1; }

		.go-to-dash { --padding: 8px; /*margin: -20px calc(var(--sidebar-padding) - var(--padding)) 0;*/ margin: 0; padding: var(--padding) var(--sidebar-padding); display: flex; /*border-radius: var(--border-radius);*/ color: white; cursor: pointer; font-size: 0.875rem; transition: 0.1s var(--curve); }
		/* Temp */ /*.go-to-dash { position: absolute; top: calc(var(--banner-height) + 35px); right: 107px; z-index: 10; }*/

			.go-to-dash-icon-wrapper { margin-right: 11px; justify-content: center; }
				.go-to-dash-icon { --size: 13px; height: var(--size); width: var(--size); margin-bottom: -2px; fill: white; }

			.go-to-dash-label { flex-grow: 0; opacity: 0.8; font-size: 11px; font-weight: 600; text-align: left; text-transform: uppercase; }
				.go-to-dash:hover:active .go-to-dash-label { opacity: 0.95; }

			.go-to-dash-icon-external-wrapper { margin-left: 7px; display: flex; align-items: center; opacity: 0; transition-duration: 0.1s var(--curve); }
				.go-to-dash:hover .go-to-dash-icon-external-wrapper { opacity: 0.6; }
				.go-to-dash:hover:active .go-to-dash-icon-external-wrapper { opacity: 0.95; }

				.go-to-dash-icon-external { --size: 11px; height: var(--size); width: var(--size); margin-top: -1px; fill: white; }



@media handheld and (max-width: 740px), screen and (max-device-width: 740px), screen and (max-width: 740px)  {

	.sidebar { height: var(--header-height); width: 100%; position: relative; z-index: 1000; display: flex; flex-direction: column; align-items: stretch; justify-content: center; overflow: hidden; transition: height 0.3s ease; }
		.main-nav-active .sidebar { height: 100%; top: 0; right: 0; left: 0; z-index: 100000; }
			.main-nav-active.has-banner .sidebar { height: calc(100% - var(--banner-height)); }
		.has-banner .sidebar { margin-top: var(--banner-height); }

		.sidebar:after { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: -1; opacity: 0; background: rgba(0,0,0,0.3); transition: opacity 0.3s ease; content: ''; }
			.user-menu-open.sidebar:after { opacity: 1; }

		.sidebar-background { background-position: top; }

		.sidebar > header { display: flex; justify-content: space-between; align-items: center; }
		.brand { height: var(--header-height); padding: 12px; padding-left: var(--body-padding); flex: 0 0 auto; box-sizing: border-box; }
			.brand span { display: none; margin-left: 7px; font-size: 10px; }

			.main-nav-active .sidebar .logo { position: absolute; top: 0; left: 0; }

		.mobile-menu-button { padding: var(--body-padding); }
		.mobile-menu-open { height: var(--header-height); display: inline-flex; align-items: center; justify-content: center; background: rgba(255,255,255,0); border-radius: 3px; color: white; cursor: pointer; font-weight: 500; letter-spacing: 2px; text-shadow: 0 0 30px hsla(var(--color-hue), 25%, 25%, 1) !important; text-transform: uppercase; }
			.mobile-menu-open:active { background: rgba(255,255,255,0.4) !important; color: white; transition-duration: 0s; }
			.main-nav-active .mobile-menu-open { display: none; }

			.mobile-menu-open img { margin: 1px 7px 0 0; width: 18px; vertical-align: -12%; }
			.mobile-menu-open-label { margin-right: -3px; padding-bottom: 0; font-size: 11px; }


		.mobile-menu-close { height: var(--header-height); width: var(--header-height); display: flex;  flex-direction: column; align-items: center; justify-content: center; color: white; font-size: 24px; }
			.mobile-menu-close img { width: 18px; position: absolute; display: flex; align-items: center; justify-content: center; cursor: pointer; transition: opacity 0.1s ease; }
			.main-nav-active .mobile-menu-close { }


		.main-nav-wrapper { margin-bottom: 0; }
			.has-banner .main-nav-wrapper { height: calc(100% - var(--banner-height)); }
			.main-nav-active .main-nav-wrapper { margin-bottom: var(--user-menu-personal-height); };

			.main-nav { opacity: 0; pointer-events: none; transition: opacity 0.3s ease, transform 0.3s ease; }
				.main-nav-active .main-nav { opacity: 1; pointer-events: all; }
				.user-menu-open .main-nav { opacity: 0; pointer-events: none; }

				.main-nav-item { width: 100%; border-radius: 3px; font-size: 24px; }
					.main-nav-item.router-link-exact-active { background: rgba(255,255,255,0.2) !important; }

}

</style>
