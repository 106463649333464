<template>
	<smooth-height :nested="true" :hide-overflow="true">
		<transition name="fade">
			<div v-if="showPathSelect">
				<label>Value</label>
				<div class="path-select-wrapper">
					<select
						:value="value"
						:disabled="loading || noResults"
						:class="{ error: highlightError }"
						data-test="path-select"
						class="path-select"
						@input="onInput"
					>
						<option v-if="!noResults" disabled value="">Select an option</option>
						<option v-for="(option, index) in pathOptions" :key="index" :value="option.path" :data-value="option.value"> {{ option.label }}</option>
					</select>
					<div v-if="loading || noResults" class="select-overlay-wrapper">
						<div v-if="loading" class="select-overlay">Loading...</div>
						<div v-else-if="noResults" class="select-overlay">No Results. Please check that the URL is valid.</div>
					</div>
				</div>
			</div>
		</transition>
	</smooth-height>
</template>



<script>
import SmoothHeight from '@/components/common/SmoothHeight'
import teamMetricDataService from '@/services/teamMetricDataService'

export default {
	name: 'MetricPath',
	components: { SmoothHeight },
	timeout: null,
	props: {
		value: { type: String, default: '' },
		manager: { type: Object, required: true },
		editProps: { type: Object, required: true },
		selectedItem: { type: Object, required: true },
		highlightRequiredComponentFields: { type: Array, default: () => [] },
	},
	data() {
		return {
			pathOptions: [],
			loading: false,
			noResults: false,
		}
	},
	computed: {
		showPathSelect() {
			const url = this.editProps.url
			return url && (url.includes('.') || (location.href.includes('localhost') && url.includes('localhost')))
		},
		highlightError() {
			return this.highlightRequiredComponentFields.indexOf('path') !== -1
		},
	},
	watch: {
		'editProps.url'(newVal) {
			this.debouncedGetUrlPaths(newVal)
		},
	},
	mounted() {
		if (this.editProps.url) this.debouncedGetUrlPaths(this.editProps.url, true)
	},
	methods: {
		debouncedGetUrlPaths(url, immediate) {
			this.loading = true
			this.noResults = false
			const getValues = () => {
				teamMetricDataService
					.getPathOptions(url)
					.then(response => {
						const paths = response.data && response.data.values
						if (!paths.length) {
							this.noResults = true
							return
						}
						this.$set(
							this,
							'pathOptions',
							paths.map(path => {
								return {
									label: `${path.name}: ${path.value}`,
									path: path.path,
									value: path.value,
								} // :3
							})
						)
					})
					.catch(() => {
						this.noResults = true
					})
					.finally(() => (this.loading = false))
			}
			if (immediate) {
				getValues()
			} else if (this.showPathSelect) {
				clearTimeout(this.$options.timeout)
				this.$options.timeout = setTimeout(getValues, 1000)
			}
		},
		onInput(e) {
			const select = e.target,
				selectedValue = select.options[select.selectedIndex].attributes['data-value'].value
			this.$set(this.editProps, 'displayValue', selectedValue)
			this.$emit('input', select.value)
		},
	},
}
</script>



<style scoped>
	.path-select-wrapper { width: 100%; margin-bottom: var(--form-element-margin); position: relative; display: inline-block; }
		.path-select { width: 100%; margin: 0; }
		.select-overlay-wrapper { padding-left: 20px; position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: inline-flex; align-items: center; background-color: var(--input-bg-color); box-shadow: inset 0 0 0 1px var(--input-border-color); border-radius: var(--border-radius); }
			.select-overlay {  }

</style>
