<template>
	<div class="content section-loading">
		<i class="loading-icon"></i>
	</div>
</template>



<script type="text/babel">
import { copy } from '@/constants'
import Auth from '@/managers/authManager'

export default {
	data() {
		return {
			credentials: { email: '', password: '' },
			mainLoginLabel: copy.LOG_IN,
			inProgressButtonLabel: copy.LOGGING_IN,
			buttonTestAttribute: 'login-submit',
		}
	},
	store: ['authInfo'],
	computed: {
		loginLabel() {
			return this.authInfo.inProgress ? copy.LOGGING_IN : this.mainLoginLabel
		},
	},
	created() {
		if (localStorage.getItem('loginParams')) Auth.processPendingLogin()
		else this.$router.push('/team-login')
	},
}
</script>
