<template>
	<div
		id="app"
		:class="{
			'main-nav-active': interfaceState.mobileMenuIsOpen,
			'mobile-view-active': interfaceState.mobileViewActive,
			'logging-in': !loggedIn,
			'has-banner': showBanner
		}"
		class="app"
	>
		<div class="flash-message-wrapper">
			<transition name="slide-down-fade">
				<div v-if="showFlashMessage" class="flash-message" data-test="flash-message">
					<span v-if="activeFlashMessage.icon" class="flash-message-icon">
						<img v-if="activeFlashMessage.icon === 'error'" src="@/img/icons/warning-flash-message.svg" class="icon">
						<img v-else-if="activeFlashMessage.icon === 'success'" src="@/img/icons/checked-circle-flash-message.svg" class="icon">
					</span>
					<span class="text" data-test="message">{{ activeFlashMessage.message }}</span>
				</div>
			</transition>
		</div>

		<banner :show-banner="showBanner" :banner-scrollbar-allowance="bannerScrollbarAllowance" />

		<transition name="fade" @enter="navLoaded = true">
			<nav-menu />
		</transition>

		<transition name="fadeslide">
			<full-modal v-if="showFeedback" @close="hideFeedbackModal">
				<feedback @close="hideFeedbackModal" />
			</full-modal>
		</transition>

		<transition name="fadeslide">
			<expired-modal v-if="showBilling" @close="hideBillingModal" />
		</transition>

		<section key="section" :class="activeBodyClass" class="body">
			<span class="scrollbar-watcher-wrapper"> <!-- Do not make this a div-->
				<scrollbar-watcher :is-on-body="true" />
			</span>

			<!--			<language-menu v-if="authInfo.loggedIn && !userInfo.isTeamMember"></language-menu>-->
			<transition :duration="150" name="fade" mode="out-in" @before-enter="beforeRouteEnter">
				<router-view>
					<loading-routes slot="loader" />
					<reconnect slot="reconnect" />
				</router-view>
			</transition>
		</section>

	</div>
</template>



<script>
import navMenu from '@/components/common/NavMenu'
import LoadingRoutes from '@/components/loaders/LoadingRoutes'
import Reconnect from '@/components/loaders/Reconnect'
import Feedback from '@/components/team/Feedback'
import FullModal from '@/components/common/FullModal'
import ScrollbarWatcher from '@/components/common/ScrollbarWatcher'
import { mobileWindowWidthThreshold } from '@/constants'
import eventBus from '@/eventBus'
import Banner from '@/components/common/Banner'
// import LanguageMenu from '@/components/common/LanguageMenu'
import ExpiredModal from './components/subscription/ExpiredModal'
import { navVisibilityStates } from './constants'
import store from './store'

export default {
	name: 'App',
	$body: document.querySelector('body'),
	components: {
		ExpiredModal,
		navMenu,
		LoadingRoutes,
		Reconnect,
		Feedback,
		FullModal,
		ScrollbarWatcher,
		Banner,
		// LanguageMenu
	},
	defaultFlashMessageDurationMs: 5000,
	interFlashMessageDelayMs: 1000, // Time between flash message fade out and the next one fading in if multiple are queued
	data() {
		return {
			messages: [],
			showFlashMessage: false,
			activeBodyClass: '',
			showFeedback: false,
			showBilling: false,
			enableBodyClass: false,
			navLoaded: false,
			bannerScrollbarAllowance: 0,
			language: null,
		}
	},
	store: ['userInfo', 'authInfo', 'interfaceState', 'teamActive', 'teamInfo'],
	computed: {
		loggedIn() {
			return this.authInfo.loggedIn
		},
		activeFlashMessage() {
			return this.messages.length && this.messages[0]
		},
		showBanner() {
			return (
				!this.interfaceState.blockBanner &&
				this.interfaceState.showBanner &&
				!this.$route.meta.isOnboarding &&
				!this.$route.meta.isErrorPage &&
				(this.interfaceState.navVisibility === navVisibilityStates.HIDDEN || this.navLoaded)
			)
		},
	},
	watch: {
		teamActive(newVal) {
			if (newVal) {
				this.$options.$body.classList.add('has-team')
			} else {
				this.$options.$body.classList.remove('has-team')
			}
		},
		'authInfo.loggedIn'(newVal) {
			if (!newVal) {
				this.$set(this, 'language', this.$i18n.fallbackLocale)
				this.$i18n.locale = this.$i18n.fallbackLocale
			}
		},
		$route(to, from) {
			if (from.meta.isOnboarding && !to.meta.isOnboarding) {
				this.$set(this.interfaceState, 'blockBanner', true)
			}
		},
	},
	created() {
		eventBus.$on('flashMessage', this.handleFlashMessage)
		eventBus.$on('showFeedback', this.showFeedbackModal)
		eventBus.$on('showExpiredModal', this.showExpiredModal)
		eventBus.$on('scrollbarChanged:body', this.setBannerScrollbarAllowance)
		window.addEventListener('resize', this.onResize)
		if (this.teamActive) this.$options.$body.classList.add('has-team')
		localStorage.setItem('teamSaleActive', true) // switch this to false once team sale is taken down
		this.onResize()
	},
	mounted() {
		this.startReactiveClock()
		this.beforeRouteEnter() // Ensure body class is added when page is requested directly or refreshed
		try {
			if (navigator && navigator.serviceWorker) {
				navigator.serviceWorker
					.register('/image-cache-worker.js', { scope: '/' })
					.then(navigator.serviceWorker.ready)
					.catch(function (error) {
						console.log('error when registering service worker', error, arguments)
					})
			}
		} catch (e) {}
	},
	destroyed() {
		if (this.$i18n.locale === this.$i18n.fallbackLocale) {
			window.localStorage.setItem('language', this.$i18n.fallbackLocale)
		}
	},
	methods: {
		startReactiveClock() {
			const step = () => {
				let drift = Date.now() - expected
				expected += 1000
				this.$set(store, 'time', Date.now())
				setTimeout(step, Math.max(0, 1000 - drift))
			}
			let expected = Date.now()
			step()
		},
		handleFlashMessage(options = {}) {
			if (options.message) {
				let messagesQueueEmpty = !this.messages.length
				this.messages.push({
					message: options.message,
					duration: options.duration || this.$options.defaultFlashMessageDurationMs,
					icon: options.icon,
				})
				if (messagesQueueEmpty) this.initFlashMessage()
			} else {
				console.error(`Flash message: no message provided! Options:`, options)
			}
		},
		initFlashMessage() {
			this.showFlashMessage = true
			setTimeout(() => {
				this.nextFlashMessage()
			}, this.messages[0].duration)
		},
		nextFlashMessage() {
			this.showFlashMessage = false
			setTimeout(() => {
				this.messages.shift()
				if (this.messages.length) {
					this.initFlashMessage()
				}
			}, this.$options.interFlashMessageDelayMs)
		},
		beforeRouteEnter() {
			// This function is triggered the moment that neither route is visible

			// Prevents css glitches when switching routes
			this.activeBodyClass = this.interfaceState.bodyClass
			this.enableBodyClass = this.loggedIn // Prevents janky logout

			this.$set(this.interfaceState, 'blockBanner', false) // Prevent janky transition from onboarding finished route to home route
			// hide/show nav between route transitions
			let navVisibility = this.$route.meta && this.$route.meta.nav
			if (!navVisibility && navVisibility !== 0) navVisibility = navVisibilityStates.VISIBLE
			this.$set(this.interfaceState, 'navVisibility', navVisibility)
		},
		showFeedbackModal() {
			this.showFeedback = true
		},
		hideFeedbackModal() {
			this.showFeedback = false
		},
		showExpiredModal() {
			this.showBilling = true
		},
		hideBillingModal() {
			this.showBilling = false
		},
		onResize() {
			let currentState = this.interfaceState.mobileViewActive,
				mobileViewActive = window.innerWidth <= mobileWindowWidthThreshold

			if (currentState !== mobileViewActive) this.$set(this.interfaceState, 'mobileViewActive', mobileViewActive)
		},
		setBannerScrollbarAllowance(scrollbarWidth) {
			this.bannerScrollbarAllowance = scrollbarWidth
		},
	},
}
</script>



<style src="./css/style.css"></style>
<style>
	.app { --banner-height: 2.5rem; }

	.has-banner:not(.mobile-view-active) .body { padding-top: calc(var(--body-padding-top) + var(--banner-height)); }

</style>
