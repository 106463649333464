import Vue from 'vue'
import store from '@/store'
import teamWebhookService from '@/services/teamWebhookDataService'
import WebhookModel from '@/models/WebhookModel'
import eventBus from '@/eventBus'

export default new (Vue.extend({
	Model: WebhookModel,
	data() {
		return {
			webhookTypes: [],
			addInfo: {
				id: 'webhook',
				title: 'Webhooks',
				singularItemTitle: 'Webhook',
				addTitle: 'Create a new Webhook',
				props: [
					{
						name: 'type',
						label: 'Event Type',
						default: '',
						class: 'item-name',
						type: 'select',
						options: () => this.webhookTypes,
						required: true,
						warning: true,
						hiddenInList: true,
					},
					{
						name: 'typeName',
						label: 'Type',
						default: '',
						class: 'item-name',
						hiddenInForm: true,
					},
					{
						name: 'description',
						label: 'Description',
						default: '',
						class: 'item-detail multiple-details',
						type: 'input',
					},
					{
						name: 'url',
						label: 'URL',
						default: '',
						class: 'item-detail multiple-details',
						type: 'input',
						required: true,
						warning: true,
					},
				],
			},
		}
	},
	computed: {
		data() {
			return store.teamInfo.activeTeam.teamWebhooks
		},
	},
	methods: {
		add(webhook) {
			this.$set(this.data.items, 'placeholder', webhook)
			return new Promise((resolve, reject) => {
				teamWebhookService
					.addWebhook(webhook)
					.then(response => {
						eventBus.$emit('webhookSecretGenerated', response.data.secret)
						store.teamInfo.activeTeam.refreshWebhooks()
						resolve(response)
					})
					.catch(error => {
						this.$delete(this.data.items, 'placeholder')
						reject(error)
					})
			})
		},
		delete(webhook) {
			return new Promise((resolve, reject) => {
				teamWebhookService
					.deleteWebhook(webhook.id)
					.then(response => {
						this.$delete(this.data.items, webhook.id)
						resolve(response)
					})
					.catch(error => {
						console.log(error)
						reject(error)
					})
			})
		},
		update(webhook) {
			return new Promise((resolve, reject) => {
				teamWebhookService
					.updateWebhook(webhook)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						console.log(error)
						reject(error)
					})
			})
		},
	},
}))()
