<template>
	<div ref="sublinks-form">
		<div v-if="hasSublinks">
			<label>URLs</label>
			<ul :class="{ 'input-focused': inputHasFocus, 'error': highlightRequiredComponentFields.includes('sublinks') }" data-test="sublink-list" class="sublink-list">
				<li
					v-for="(link, index) in sublinks"
					v-if="!link.hasOwnProperty('deleted') || !link.deleted"
					:key="index"
					:class="{ 'input-focused': focusedInputIndex === index, dragging: link.dragging }"
					draggable="true"
					@dragstart="dragstart($event, index, link)"
					@dragenter.prevent="dragenter(index)"
					@dragover.prevent
					@drop="drop"
					@dragend="dragend(link)"
				>
					<input
						ref="sublinkInputs"
						v-model.trim="link.url"
						draggable="true"
						data-test="url-input"
						@focus="inputFocused(index)"
						@blur="inputBlurred"
						@dragstart.prevent.stop
					>
					<span v-if="sublinks.length > 1" class="drag sublink-action">
						<svg class="icon drag-icon" data-test="icon-drag" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 17.5"><path d="M1.25 16.25h19.5"></path><path d="M20.75 17.5H1.25a1.25 1.25 0 0 1 0-2.5h19.5a1.25 1.25 0 0 1 0 2.5z"></path><path d="M1.25 11.25h19.5"></path><path d="M20.75 12.5H1.25a1.25 1.25 0 0 1 0-2.5h19.5a1.25 1.25 0 0 1 0 2.5z"></path><path d="M1.25 6.25h19.5"></path><path d="M20.75 7.5H1.25a1.25 1.25 0 0 1 0-2.5h19.5a1.25 1.25 0 0 1 0 2.5z"></path><path d="M1.25 1.25h19.5"></path><path d="M20.75 2.5H1.25a1.25 1.25 0 0 1 0-2.5h19.5a1.25 1.25 0 0 1 0 2.5z"></path></svg>
					</span>
					<span v-if="sublinks.length > 1" class="delete sublink-action" draggable="true" @click="deleteLink(link.id)" @dragstart.prevent.stop>
						<img class="icon icon-delete" src="@/img/icons/close-dark.svg" data-test="icon-delete">
					</span>
				</li>
			</ul>
		</div>
		<tooltip :beside-button="true">
			<button slot="input" type="button" data-test="add-tab-button" class="button button-neutral button-smaller add-link-row-button" @click="addLinkRow">+ Add Tab</button>
			<div slot="tip-text">Add more websites to open in separate tabs.</div>
		</tooltip>
	</div>
</template>



<script>
import Tooltip from '../../common/Tooltip'
import { linkTypes } from '../../../constants'

export default {
	name: 'Sublinks',
	components: { Tooltip },
	props: {
		value: {
			type: Object,
			default: () => {
				return {}
			},
		},
		editProps: { type: Object, required: true },
		selectedItem: { type: Object, required: true },
		highlightRequiredComponentFields: { type: Array, default: () => [] },
	},
	data() {
		return {
			inputHasFocus: false,
			focusedInputIndex: null,
			dragging: false,
			dragstartIndex: null,
			dragstartModel: null,
		}
	},
	computed: {
		sublinks() {
			let sublinks = []
			if (!this.value || !this.linksOrder) return sublinks
			this.linksOrder.forEach(linkId => {
				if (this.value.items[linkId]) sublinks.push(this.value.items[linkId])
			})
			return sublinks
		},
		hasSublinks() {
			return this.value && this.sublinks && this.sublinks.filter(sublink => !sublink.deleted).length
		},
		linksOrder() {
			return this.editProps && this.editProps.linksOrder
		},
	},
	created() {
		// We are dropping support for type 0 [LINK] type links.
		// All new and edited links will be type 1 & 2 (GROUP & SUBLINK).
		// Therefore we will immediately add a SUBLINK when creating a new link.
		// We have logic to ignore this addition as a "change".

		// ALL type 0 links are converted to type 1 & 2 upon editing. This will show the change confirmation even if no
		// changes were made, but this will only happen once per link and not at all after the migration.
		if (this.selectedItem.type === linkTypes.DEPRECATED) {
			this.selectedItem.switchToGroupType()
		} else if (this.selectedItem.type === linkTypes.GROUP && !this.sublinks.length) {
			this.selectedItem.addEmptySublink()
		}
	},
	methods: {
		addLinkRow() {
			this.selectedItem.addEmptySublink()
			this.$nextTick(() => {
				const lastInput = this.$refs.sublinkInputs[this.$refs.sublinkInputs.length - 1]
				lastInput && lastInput.focus()
			})
		},
		deleteLink(id) {
			this.selectedItem.deleteSublink(id)
		},
		inputFocused(index) {
			this.inputHasFocus = true
			this.focusedInputIndex = index
		},
		inputBlurred() {
			this.inputHasFocus = false
			this.focusedInputIndex = null
		},
		dragstart(e, index, model) {
			e.dataTransfer.setData('text/html', e.target.outerHTML) // necessary for firefox drag n drop to function
			this.dragging = true
			this.dragstartIndex = index
			this.dragstartModel = model
			this.$set(model, 'dragging', true)
		},
		dragenter(index) {
			if (index === this.dragstartIndex) return
			this.linksOrder.splice(index, 0, this.linksOrder.splice(this.dragstartIndex, 1)[0])
			// ^ Moves the dragged item to its new location in the list
			this.dragstartIndex = index
		},
		drop() {
			this.$set(this.dragstartModel, 'dragging', false)
		},
		dragend() {
			this.$set(this.dragstartModel, 'dragging', false)
			this.dragging = false
			this.dragstartIndex = null
			this.dragstartModel = null
		},
	},
}
</script>



<style scoped>

	.sublink-list { margin: 0 0 var(--form-element-margin-small); padding: 1px; box-shadow: var(--input-border); border-radius: var(--input-border-radius); }
		.sublink-list.error { padding: 2px; box-shadow: inset 0 0 0 2px var(--color-form-warning); }

		.sublink-list.input-focused { box-shadow: var(--input-border-focussed); }
			.sublink-list.input-focused.error { box-shadow: inset 0 0 0 2px var(--color-form-warning-focussed); }

		.sublink-list li { position: relative; display: flex; justify-content: space-between; align-items: center; background-color: var(--input-bg-color); border-bottom: 1px solid var(--input-border-color); }
			.sublink-list li:hover { background-color: hsl(var(--color-hue), 2%, 98%); }
			.sublink-list.input-focused li.input-focused { background-color: var(--input-bg-color-focus); }
			.sublink-list li:first-child { border-top-left-radius: calc(var(--border-radius)); border-top-right-radius: calc(var(--border-radius)); }
			.sublink-list li:last-child { border-bottom: none; border-bottom-left-radius: calc(var(--border-radius)); border-bottom-right-radius: calc(var(--border-radius)); }
			.sublink-list li.dragging { opacity: 0; }

			.sublink-action:nth-last-of-type(2) { padding-right: calc(var(--form-element-padding) / 2) }
			.sublink-action:nth-of-type(2) { padding-left: calc(var(--form-element-padding) / 2) }

			.sublink-list input { margin: 0; padding: calc(var(--form-element-padding) - 1px) var(--form-element-padding); background-color: transparent; padding-right: 0; box-shadow: none !important; border-radius: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

			.drag { padding: var(--form-element-padding); cursor: grab; opacity: 0; transition: opacity 0.1s ease; }
				li:hover .drag { opacity: 0.35; }
				.drag:hover { opacity: 0.7; }
				.drag:active { cursor: grabbing; }
				.drag-icon { height: 12px; margin-top: 1px; fill: #000; }

			.delete { padding: calc(var(--form-element-padding) - 1px) var(--form-element-padding); padding-right: 15px; cursor: pointer; }
				.icon-delete { height: 10px; margin-bottom: 1px; opacity: 0; transition: opacity 0.1s ease; }
					li:hover .icon-delete { opacity: 0.35; }
					.delete:hover .icon-delete { opacity: 0.7; }

	.button { margin: 0; }

</style>
