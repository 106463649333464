import Vue from 'vue'
import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	mixins: [itemModel],
	data() {
		return {
			id: null,
			timezone: { timeZoneName: '', timeZoneId: '', offset: 0 }, // exclusively used for editing
			pinned: false,
			name: '',
			props: ['pinned', 'timezone', 'name'],
			currentOffset: '',
		}
	},
	computed: {
		time() {
			let newTime = this.offsetDate(this.timezone.offset),
				hours = newTime.getUTCHours(),
				displayHour = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours
			return displayHour + ':' + twoDigit(newTime.getUTCMinutes()) + (hours >= 12 ? 'p' : 'a')

			function twoDigit(number) {
				let numAsInteger = parseInt(number)
				let twodigit = numAsInteger >= 10 ? numAsInteger : '0' + numAsInteger.toString()
				return twodigit.toString()
			}
		},
		offset() {
			return this.timezone.offset
		},
	},
	methods: {
		updateData(clock) {
			this.name = clock.name
			this.timezone.timeZoneId = clock.timeZoneId
			this.timezone.timeZoneName = clock.timeZoneName
			this.timezone.offset = parseInt(clock.currentOffset)
			this.id = clock.id
			this.pinned = clock.pinned
			this.revertChanges()
		},
		offsetDate(offsetSeconds) {
			let d = new Date()
			if (offsetSeconds) d.setUTCSeconds(d.getUTCSeconds() + offsetSeconds)
			return d
		},
	},
})
