import Vue from 'vue'
import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	mixins: [itemModel],
	data: function () {
		return {
			id: null,
			title: '',
			url: '',
			date: null,
			categoryId: null,
			order: null,
			dragging: false,
			props: ['title', 'url', 'categoryId', 'order', 'id'],
		}
	},
})
