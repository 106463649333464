<template>
	<div ref="iconSelect">
		<label>{{ iconLabelAction }}<span v-if="iconLabelAction === 'Icon'" class="optional">Optional</span></label>
		<div v-if="!open">
			<div :class="{ 'icon-selected': selectedIcon }" class="selected-icon">
				<div class="input icon-wrapper" data-test="choose-icon-button" @click="openIconList">
					<span class="label-wrapper">
						<label data-test="action-label">{{ selectLabelAction }}<br>Icon</label>
					</span>
					<img :src="selectedIcon && selectedIcon.url" class="icon">
				</div>
			</div>
		</div>
		<div v-else class="icon-selection">
			<div class="top-row">
				<span :class="{'focused': searchFocused}" class="search">
					<svg class="icon-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250.31 250.31"><path d="M244.19,214.6l-54.38-54.37c-.29-.29-.63-.49-.93-.76a103,103,0,1,0-29.42,29.41c.27.3.47.64.76.93l54.38,54.38a20.92,20.92,0,0,0,29.59-29.59ZM102.91,170.15a67.24,67.24,0,1,1,67.24-67.24A67.24,67.24,0,0,1,102.91,170.15Z" /></svg>
					<input
						ref="search"
						v-model.trim="searchInput"
						placeholder="Search"
						data-test="icon-search-input"
						@keydown.esc.stop="onSearchEsc"
						@blur="searchFocused = false"
						@focus="searchFocused = true"
					>
				</span>
				<!--				<span class="button button-upload button-neutral button-smaller">-->
				<!--					Upload icon-->
				<!--				</span>-->
				<span class="cancel" @click="closeIconList">
					<img class="icon icon-cancel" src="@/img/icons/close-dark.svg">
				</span>
			</div>
			<div class="icon-list-wrapper">
				<ul id="icon-list" ref="icon-list" data-test="icon-list" class="icon-list">
					<li
						v-for="(icon, index) in filteredIcons"
						:key="index"
						:class="{ 'highlighted-icon': selectedIcon === icon }"
						:title="icon.label"
						data-test="icon"
						class="icon"
						@click="selectIcon(icon.id)"
					>
						<img :src="icon.url">
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>



<script>
import scrollManager from '@/managers/scrollManager'
import store from '@/store'
import eventBus from '@/eventBus'
import smoothReflow from 'vue-smooth-reflow'

export default {
	name: 'IconSelect',
	mixins: [smoothReflow],
	props: {
		value: { type: String, default: null },
	},
	data() {
		return {
			open: false,
			searchInput: '',
			searchFocused: false,
		}
	},
	computed: {
		searchCharacters() {
			return this.searchInput.toUpperCase().split('')
		},
		searchCharacterCounts() {
			let counts = {}
			this.searchCharacters.map(char => {
				if (char === ' ') return
				if (counts.hasOwnProperty(char)) {
					counts[char]++
				} else {
					counts[char] = 1
				}
			})
			return counts
		},
		searchKeywords() {
			return this.searchInput.toUpperCase().split(' ')
		},
		icons() {
			return store.teamInfo.activeTeam.teamIcons
		},
		iconArray() {
			return Object.values(this.icons.items)
		},
		filteredIcons() {
			// Fuzzy search
			const includesStrings = (stringToSearch, strings) =>
				strings.every(string => stringToSearch.includes(string))
			// ^ Given a list of strings, check that the stringToSearch includes all the strings
			const includesStringCharacterCounts = (stringToSearch, charCountsObj) =>
				Object.keys(charCountsObj).every(key => stringToSearch.split(key).length - 1 >= charCountsObj[key])
			// ^ similar to includes Strings, but checks that the character counts match.
			const rankString = string =>
				includesStrings(string, this.searchKeywords) ? (string.startsWith(this.searchKeywords[0]) ? 2 : 1) : 0
			// ^ Show items that have full keyword matches before ones that just contain all of the characters. If the item starts with the keyword, rank it even higher
			const moveSelectedIconToFront = array => {
				if (!this.selectedIcon) {
					return array
				} else {
					let selectedIconIndex = array.indexOf(this.selectedIcon)
					if (selectedIconIndex === -1) {
						return array
					} else {
						array.unshift(array.splice(selectedIconIndex, 1)[0])
						return array
					}
				}
			}

			if (!this.searchInput) {
				return moveSelectedIconToFront(this.iconArray)
			}
			if (!this.iconArray.length) return []
			return moveSelectedIconToFront(
				this.iconArray
					.filter(icon => includesStringCharacterCounts(icon.uppercaseLabel, this.searchCharacterCounts))
					.sort((a, b) => rankString(b.uppercaseLabel) - rankString(a.uppercaseLabel))
			)
		},
		selectedIcon() {
			return this.value ? this.icons.items[this.value] : null
		},
		selectLabelAction() {
			return this.selectedIcon ? 'Change' : 'Select'
		},
		iconLabelAction() {
			return this.open ? 'Choose an icon' : 'Icon'
		},
	},
	created() {
		eventBus.$on('modalClosed', this.onModalClosed)
	},
	mounted() {
		this.$smoothReflow()
	},
	destroyed() {
		eventBus.$off('modalClosed', this.onModalClosed)
	},
	methods: {
		openIconList() {
			this.open = true
			this.$nextTick(() => {
				scrollManager.addElement('iconList', this.$refs['icon-list'])
				this.$refs.search && this.$refs.search.focus()
			})
		},
		closeIconList() {
			this.open = false
			this.clearSearch()
			scrollManager.removeElement('iconList')
		},
		selectIcon(iconId) {
			this.$emit('input', iconId)
			this.closeIconList()
		},
		onSearchEsc() {
			if (this.searchInput) {
				this.clearSearch()
			} else {
				this.closeIconList()
			}
		},
		onModalClosed() {
			this.closeIconList()
		},
		clearSearch() {
			this.searchInput = ''
		},
	},
}
</script>



<style scoped>

	.selected-icon { --transition: opacity .15s var(--curve); }

		.selected-icon .icon-wrapper { width: auto; position: relative; display: inline-block; cursor: pointer; }
			.selected-icon .icon-wrapper:hover { background: var(--input-bg-color-hover); }

			.selected-icon .label-wrapper { margin: 1px; position: absolute; top: 0; right: 0; left: 0; bottom: 0; z-index: 1; display: flex; justify-content: center; align-items: center; border-radius: var(--input-border-radius); transition: var(--transition); }
				.selected-icon label { margin: 0; opacity: 0.7; cursor: pointer; font-size: 0.8125rem; line-height: 1.15; text-align: center; }

				.selected-icon.icon-selected .label-wrapper { opacity: 0; transition: var(--transition); }
					.selected-icon .icon-wrapper:hover .label-wrapper { opacity: 1; }

				.selected-icon .icon { --size: 40px; height: var(--size); width: var(--size); opacity: 0.85; transition: var(--transition); vertical-align: top; visibility: hidden; }
					.selected-icon.icon-selected .icon { visibility: visible; }

					.selected-icon .icon-wrapper:hover .icon { opacity: 0.05; }

	.icon-selection { --icon-img-gutter: 16px; --icon-list-padding: 13px; width: auto; margin-bottom: var(--form-element-margin); padding: 0 1px 1px 0; position: relative; background: var(--input-bg-color); border-radius: var(--input-border-radius); box-shadow: inset 0 0 0 1px var(--input-border-color); }

		.top-row { padding: calc(var(--icon-list-padding) + 5px) calc(var(--icon-list-padding) + 17px) 3px calc(var(--icon-list-padding) + 1px); display: flex; align-items: center; }
			.top-row .button-upload { margin-left: var(--form-element-padding); margin-right: var(--icon-img-gutter); }

			.top-row .search { margin: 0; position: relative; flex-grow: 1; display: inline-block; }
				.search .icon-search { height: 15px; position: absolute; top: 50%; left: 1px; transform: translateY(-50%); opacity: 0.6; }

				.search input { margin: 0; padding: 8px 25px 8px 33px; box-shadow: none; background-color: transparent; border-radius: 50px; font-size: 0.9375rem; }
					.search input::placeholder { opacity: 0.7; }

				.focused { }

			.top-row .cancel { padding: 10px 0; display: flex; align-items: center; cursor: pointer; }
				.top-row .cancel .icon-cancel { height: 13px; opacity: 0.6; transition: opacity 0.3s ease; }
				.top-row .cancel:hover .icon-cancel { opacity: 0.8; }

	.icon-list-wrapper { padding-bottom: 12px; position: relative; border-radius: 2px; overflow: hidden; }
		.icon-list-wrapper:before, .icon-list-wrapper:after { height: 30px; position: absolute; left: var(--icon-list-padding); right: var(--icon-list-padding); content: ''; z-index: 2;}
		.icon-list-wrapper:before { top: 0; background: linear-gradient(var(--input-bg-color), transparent); }
		.icon-list-wrapper:after { bottom: 0; background: linear-gradient(transparent, var(--input-bg-color));}

		.icon-list { --list-icon-size: 64px; width: 100%; min-height: var(--list-icon-size); max-height: 500px; margin: 0; padding: var(--icon-list-padding); position: relative; display: grid; grid-template-columns: repeat(auto-fill, var(--list-icon-size)); grid-gap: 6px; justify-content: space-between; overflow: auto; }

		.icon-list:empty:before { height: var(--list-icon-size); content: ''; text-align: center; }
			.icon-list:empty:after { content: 'No Icons Found'; position: absolute; top: 50%; right: 0; left: 0; transform: translateY(-50%); opacity: 0.4; text-align: center; }

				.highlighted-icon { background-color: white; }

				.icon-list .icon { opacity: 0.9; border-radius: var(--input-border-radius); cursor: pointer; }
					.icon-list .icon:hover { background-color: white; }
					.icon-list .icon:active { box-shadow: inset 0 0 0 1px var(--input-border-color-focus), 0 0 0 1px var(--input-border-color-focus); }

					.icon-list .icon img { width: var(--list-icon-size); padding: var(--icon-img-gutter); display: block; }
</style>
