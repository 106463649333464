<template>
	<div id="g-recaptcha" :data-sitekey="sitekey" class="g-recaptcha"></div>
</template>



<script>
export default {
	props: {
		endSubmitProcess: { type: Function, default() {} },
	},
	data() {
		return {
			sitekey: '6LdxIMcUAAAAAA2uyjdHwhXYv0y26U1LspkkjrMY',
			widgetId: 0,
			result: null,
		}
	},
	mounted() {
		if (!window.$captchaScript) {
			window.onRecaptchaLoadCallback = this.checkAndInitializeRecaptcha
			let $captchaScript = (window.$captchaScript = document.createElement('script'))
			$captchaScript.type = 'text/javascript'
			$captchaScript.async = true
			$captchaScript.defer = true
			$captchaScript.src =
				'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoadCallback&render=explicit'
			document.body.appendChild($captchaScript)
		} else {
			this.checkAndInitializeRecaptcha()
		}
	},
	methods: {
		execute() {
			window.grecaptcha.execute(this.widgetId)
			this.handleRecaptchaClosed()
		},
		reset() {
			window.grecaptcha.reset(this.widgetId)
		},
		checkAndInitializeRecaptcha() {
			if (!this.widgetId) {
				this.widgetId = window.grecaptcha.render('g-recaptcha', {
					sitekey: this.sitekey,
					size: 'invisible',
					// the callback executed when the user solves the recaptcha
					callback: response => {
						// emit an event called verify with the response as payload
						this.result = response
						this.$emit('verify', response)
						// reset the recaptcha widget so it can be executed again
						this.reset()
					},
				})
			}
		},
		handleRecaptchaClosed() {
			const iframe = document.querySelector("iframe[title='recaptcha challenge']")
			const container = iframe && iframe.parentNode.parentNode
			// lint disabled for next line cause the call won't work without specify the mutations argument
			// eslint-disable-next-line
			let observer = new MutationObserver(mutations => {
				if (container && container.style.visibility === 'hidden') {
					this.$emit('end-submit-process')
				}
			})
			if (container && observer) observer.observe(container, { attributes: true, attributeFilter: ['style'] })
		},
	},
}
</script>

<style>
	.slide-fade-enter-active .g-recaptcha, .slide-fade-leave-active .g-recaptcha { display: none; }

	.grecaptcha-badge { visibility: hidden; }
</style>
