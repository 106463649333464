<template>
	<div ref="addmember" class="add-members" data-test="add-members">
		<header>
			<label>Email</label>
			<label>Full Name <span class="optional">Optional</span></label>
			<label>Admin</label>
		</header>
		<div
			v-for="(userInfo, index) in userInfoArray"
			:key="index"
			class="user-row"
		>
			<input
				v-model.trim="userInfoArray[index].email"
				:class="{'error': userInfo.error}"
				data-test="email"
				class="add-email"
				inline-modal-focus
				@focus="onFocus"
				@keyup="handleKeyup"
			>
			<input
				v-model.trim="userInfoArray[index].fullName"
				data-test="name"
				class="name"
				@focus="onFocus"
				@keyup="handleKeyup"
			>
			<toggle v-model="userInfo.admin" class="admin-toggle" data-test="admin-toggle"></toggle>
		</div>
		<div><button class="button button-smaller button-neutral u--no-margin-top add-rows-button" type="button" data-test="add-rows" @click.prevent="addMoreRows">+ Add</button></div>
	</div>
</template>



<script type="text/babel">
import smoothReflow from 'vue-smooth-reflow'
import { addTeamMembersUtil } from '@/util'
import Toggle from '@/components/common/Toggle'

export default {
	components: {
		Toggle,
	},
	mixins: [smoothReflow],
	props: {
		value: { type: Array, default: () => [] },
		inOnboarding: { type: Boolean, default: false },
	},
	data() {
		return {
			userInfoArray: addTeamMembersUtil.getUserCluster(),
		}
	},
	watch: {
		value: {
			handler(newVal) {
				if (newVal && newVal.length) this.userInfoArray = newVal
			},
			deep: true,
		},
	},
	store: ['teamInfo'],
	mounted() {
		this.$nextTick(() => {
			this.$smoothReflow({
				el: this.$refs.addmembers,
			})
		})
	},
	methods: {
		onFocus(e) {
			this.$nextTick(() => {
				if (e && e.target && e.target.value.length) {
					e.target.setSelectionRange(0, e.target.value.length)
				}
			})
		},
		addMoreRows() {
			this.userInfoArray = this.userInfoArray.concat(addTeamMembersUtil.getUserCluster())
			this.$emit('input', this.userInfoArray)
		},
		handleKeyup() {
			this.$emit('input', this.userInfoArray)
		},
	},
}
</script>



<style scoped>
	.add-members {}
		.add-members header label { margin: 0; }
		.user-row, .add-members header { margin-bottom: 17px; display: grid; grid-template-columns: 6fr 6fr 1fr; grid-column-gap: 1rem; }
			.user-row input { margin-bottom: 0; position: relative; }
			.user-row .admin-toggle { place-self: center; }

		.add-rows-button { margin-bottom: var(--form-element-margin-small); }

</style>
