import Vue from 'vue'
import appDataService from '@/services/teamAppDataService'
import AppCategoryModel from '@/models/AppCategoryModel'
import { baseManager, categoryManager, reorder } from './mixins/itemManagerMixins'
import AppModel from '../models/AppModel'

export default new (Vue.extend({
	Model: AppCategoryModel,
	ItemModel: AppModel,
	dataService: appDataService,
	itemNameFromServer: 'apps', // Used in the refresh method in categoryManager mixin
	uncategorizedIdKey: 'appId',
	pathOverride: '/team/appCategories',
	refreshPathOverride: appDataService.$options.defaultPath,
	mixins: [baseManager, categoryManager, reorder], // Order matters, category must override base
	data: function () {
		return {
			addInfo: {
				id: 'categories',
				title: 'Categories',
				singularItemTitle: 'Category',
				addTitle: 'What category would you like to add?',
				iconClass: 'ion-link',
				iconSrc: require('../img/icons/link.svg'),
				allowReorder: true,
				props: [
					{
						name: 'id',
						type: 'hidden',
						hiddenInList: true,
					},
					{
						name: 'title',
						default: '',
						class: 'item-name',
						type: 'input',
						label: 'Name',
						warning: false,
						required: true,
						dataTest: 'title',
					},
				],
			},
		}
	},
	methods: {
		getAddRequestBody(category) {
			return { title: category.editProps.title }
		},
	},
}))()
