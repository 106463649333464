<template>
	<transition name="slide-down-fade" appear>
		<div v-if="showBanner && activeBanner" :style="{ right: `${bannerScrollbarAllowance}px` }" :class="activeBanner.class" class="banner" data-test="banner">
			<span class="banner-text">
				<strong v-if="activeBanner.title" class="banner-title">{{ activeBanner.title }}</strong>
				<span v-if="activeBanner.bannerText" :class="{ 'banner-monospace': isMonospace }" class="banner-description">{{ activeBanner.bannerText }}</span>
			</span>
			<a v-if="showLink && useExternalLink" :href="activeBanner.externalCtaPath" class="banner-link link" target="_blank" data-test="link">{{ activeBanner.linkLabel }}</a>
			<span v-else-if="showLink && activeBanner.ctaHandler" class="banner-link link" data-test="link" @click="activeBanner.ctaHandler">{{ activeBanner.linkLabel }}</span>
			<router-link v-else-if="showLink" :to="activeBanner.ctaRouterPath" class="banner-link" data-test="link">
				<span class="link">{{ activeBanner.linkLabel }}</span>
			</router-link>
		</div>
	</transition>
</template>


<script>
import banners from '../../models/banners'

export default {
	name: 'Banner',
	props: {
		bannerScrollbarAllowance: { type: Number, required: true },
		showBanner: { type: Boolean, default: false },
	},
	store: ['interfaceState'],
	computed: {
		isMonospace() {
			return this.activeBanner.isMonospace
		},
		activeBanner() {
			return Object.values(banners).find(banner => {
				if (banner.blackListPaths) return !banner.blackListPaths.includes(this.$route.path) && banner.showBanner
				return banner.showBanner
			})
		},
		showButtonUnlessOnPage() {
			if (this.activeBanner.useExternalCtaPath) return true
			return !this.$route.path.includes(this.activeBanner.ctaRouterPath)
		},
		showLink() {
			// whether to show the call-to-action button
			return !this.activeBanner.hideLink && this.showButtonUnlessOnPage
		},
		useExternalLink() {
			return this.activeBanner.externalCtaPath && this.activeBanner.useExternalCtaPath
		},
	},
	watch: {
		activeBanner: {
			handler(newVal) {
				this.$set(this.interfaceState, 'showBanner', !!newVal)
			},
			immediate: true,
		},
	},
}
</script>


<style scoped>

.banner {
	--v-padding: 10px;
	--h-padding: 0px;
	height: var(--banner-height); position: fixed; top: 0; right: 0; left: var(--sidebar-width); padding: var(--v-padding) var(--h-padding); display: flex; align-items: center; justify-content: center; background: hsl(var(--color-hue), 28%, 78%); font-size: 1rem; z-index: 9000;
}
	.banner-text { display: flex; align-content: center; align-items: center; }
		.banner-title { margin-right: 0.75rem; font-weight: 700; white-space: nowrap; }
		.banner-description { padding: 0px 21px; opacity: 0.8;  font-size: 0.8125rem; font-weight: 700; }
			.banner-monospace { font-family: ui-monospace, Menlo, Monaco, "Courier New", monospace; text-transform: uppercase; }
		.banner-link { margin-left: 4px; padding: 8px; color: var(--body-text); cursor: pointer; font-weight: 600; }
			.banner-link:hover { opacity: 1; transition-duration: 0s; }
			.banner-link:hover:active { opacity: 1; transform: translateY(1px); }
			.link { margin-left: 0px; padding: 7px 17px; background: white; border: 0; border-radius: 50px; color: hsl(var(--sale-hue), 55%, 33%); font-size: 0.8125rem; font-weight: 700; outline: none; text-align: center; text-decoration: none; transition: 0s ease; white-space: nowrap; }

.banner.expired { background-color: #ffa565; }
.banner.plus-sale { --sale-hue: var(--color-hue); --gradient-factor: 15; background: linear-gradient(141deg, hsla(calc(var(--sale-hue) - var(--gradient-factor)), 55%, 39%, 1), hsla(calc(var(--sale-hue) + var(--gradient-factor)), 55%, 39%, 1)); color: white; }
	.banner.plus-sale .banner-link { color: #fff; }


@media handheld and (max-width: 740px), screen and (max-device-width: 740px), screen and (max-width: 740px) {
	.banner { --v-padding: 9px; --h-padding: 15px; justify-content: space-between; }
		.banner-text { margin-top: -1px; flex-direction: column; align-items: flex-start; }
			.banner-title { margin-bottom: 4px; font-size: 0.9375rem; }
			.banner-description { padding: 0; font-size: 0.75rem; }
		.banner-link { display: flex; margin-left: 10px; --h-padding: 14px; padding-left: var(--h-padding); padding-right: var(--h-padding); }
}

</style>
