import Vue from 'vue'
import { errorMessages } from '@/constants'
import store from '@/store'

const PaymentDataService = Vue.extend({
	data: function () {
		return {}
	},

	computed: {},

	methods: {
		getCards: function () {
			return new Promise((resolve, reject) => {
				this.$http
					.get((store.userInfo.isAdmin ? 'team/' : '') + 'account/paymentmethods')
					.then(response => {
						resolve(response.data.cards)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		addCard: function (tokenRequestResult, recaptchaToken, chargeImmediately) {
			return new Promise((resolve, reject) => {
				let data = { stripe_token: tokenRequestResult.token.id, recaptcha: recaptchaToken }
				if (chargeImmediately) {
					data.pay_last_invoice = true
					data.is_default = true
				}
				this.$http
					.post((store.userInfo.isAdmin ? 'team/' : '') + 'account/paymentmethods', data)
					.then(response => {
						resolve(response.data)
					})
					.catch(() => {
						let error = errorMessages.BILLING_SUBSCRIPTION
						reject(error)
					})
			})
		},
		setCardAsDefault: function (cardId) {
			return new Promise((resolve, reject) => {
				this.$http
					.post((store.userInfo.isAdmin ? 'team/' : '') + 'account/paymentmethods/default', {
						card_id: cardId,
						source_type: 'card',
					})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						let message = null
						if (!error.response.success && error.response.message) {
							message =
								'The message from your bank or the payment provider was: "' +
								error.response.message +
								'"'
						}
						reject(message)
					})
			})
		},
		removeCard: function (cardId) {
			return new Promise((resolve, reject) => {
				this.$http
					.patch((store.userInfo.isAdmin ? 'team/' : '') + 'account/paymentmethods', {
						card_id: cardId,
						source_type: 'card',
						action: 'remove',
					})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						let message = null
						if (error && error.response) {
							message = error.response.message
						}
						reject(message)
					})
			})
		},
	},
})

export default new PaymentDataService()
