<template>
	<div class="modal login-modal login">
		<auth-form
			id="register-form"
			form-test-attribute="register-form-message"
		>
			<input v-model.trim="info.name" v-focus type="text" data-test="register-name" placeholder="Name">
			<input v-model.trim="info.email" type="text" data-test="register-email" placeholder="Email">
			<div class="password-input-wrapper">
				<input
					v-model="info.password"
					class="password-input"
					type="password"
					data-test="register-password"
					placeholder="Password"
					@keyup="detectCapslock($event)"
				>
				<base-svg v-if="capslockOn" :icon-name="'capslock'"></base-svg>
			</div>
			<button :disabled="registerLabel !== mainRegisterLabel" class="button button-primary" data-test="register-submit" @click="register()"><span class="button-label">{{ registerLabel }}</span></button>
		</auth-form>

		<div class="below">
			<router-link to="login" class="login-link"><svg class="icon icon-back" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 492 492"><path d="M198.608 246.104L382.664 62.04c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12C361.476 2.792 354.712 0 347.504 0s-13.964 2.792-19.028 7.864L109.328 227.008c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06L198.608 246.104z" /></svg> Back to Login</router-link>
		</div>
	</div>
</template>



<script type="text/babel">
import Auth from '@/managers/authManager'
import AuthForm from '@/components/common/AuthForm'
import BaseSvg from '@/components/svgs/BaseSvg'
import capslockState from '@/mixins/capslockMixin'

export default {
	components: {
		AuthForm,
		BaseSvg,
	},
	mixins: [capslockState],
	data() {
		return {
			info: { name: '', email: '', password: '', version: 'Account Site' },
			mainRegisterLabel: 'Sign Up',
		}
	},
	store: ['authInfo', 'basicTeamInfo'],
	computed: {
		registerLabel() {
			if (this.authInfo.inProgress) return 'Signing up…'
			return this.mainRegisterLabel
		},
		error() {
			return this.authInfo.errorMessage
		},
	},
	created() {
		this.authInfo.resetState()
	},
	methods: {
		login() {
			this.$router.push({ path: 'login', query: { email: this.info.email } })
		},
		recover() {
			this.$router.push({ path: 'recover', query: { email: this.info.email } })
		},
		register() {
			if (this.basicTeamInfo.uuid) this.info.communityUuid = this.basicTeamInfo.uuid
			Auth.register(this.info)
		},
	},
}
</script>
