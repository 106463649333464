<template>
	<svg
		:aria-labelledby="iconName"
		:viewBox="viewBox"
		:class="classList"
		xmlns="http://www.w3.org/2000/svg"
		role="presentation"
		width="18"
		height="18"
	>
		<component :is="componentName" />
	</svg>
</template>

<script>
export default {
	name: 'BaseSvg',
	baseIconPath: 'svg/',
	iconPrefix: 'icon-',
	defaultViewBox: '0 0 32 32',
	customViewBoxes: {
		add: '0 0 30 30',
		alert: '0 0 26 26',
		countdown: '0 0 32 31',
		dashboard: '0 0 512 512',
		home: '0 0 34 34',
		links: '0 -2 31 27',
		members: '0 0 26 26',
		metrics: '0 0 36 33',
		announcement: '0 0 26 24',
		pin: '0 0 481.715 481.715',
		topics: '0 0 31 32',
		trash: '0 0 22 24',
		quote: '0 0 20 18',
		caretUp: '0 0 282.56 166',
		caretDown: '0 0 282.56 166',
		profile: '-2 0 47 51',
		upgrade: '0 0 41 41',
		gifts: '0 0 35 33',
		subscription: '0 0 40 40',
		choosePlan: '0 0 512 512',
		emailAccount: '0 0 512 512',
		payment: '0 0 512 376',
		visa: '0 0 780 500',
		mastercard: '0 0 780 500',
		maestro: '0 0 780 500',
		americanExpress: '0 0 64 64',
		discover: '0 0 780 500',
		capslock: '0 0 1024 1024',
	},
	components: {
		IconAdd: () => import('svg/icon-add'),
		IconAlert: () => import('svg/icon-alert'),
		IconApps: () => import('svg/icon-apps'),
		IconClock: () => import('svg/icon-clock'),
		IconCountdown: () => import('svg/icon-countdown'),
		IconDashboard: () => import('svg/icon-dashboard'),
		IconHome: () => import('svg/icon-home'),
		IconLinks: () => import('svg/icon-links'),
		IconMembers: () => import('svg/icon-members'),
		IconMetrics: () => import('svg/icon-metrics'),
		IconPin: () => import('svg/icon-pin'),
		IconSetting: () => import('svg/icon-setting'),
		IconTeamGoal: () => import('svg/icon-team-goal'),
		IconTopics: () => import('svg/icon-topics'),
		IconTrash: () => import('svg/icon-trash'),
		IconQuote: () => import('svg/icon-quote'),
		IconChoosePlan: () => import('svg/icon-choosePlan'),
		IconEmailAccount: () => import('svg/icon-emailAccount'),
		IconPayment: () => import('svg/icon-payment'),
		IconCaretUp: () => import('svg/icon-caret-up'),
		IconCaretDown: () => import('svg/icon-caret-down'),
		IconProfile: () => import('svg/icon-profile'),
		IconUpgrade: () => import('svg/icon-upgrade'),
		IconGifts: () => import('svg/icon-gifts'),
		IconSubscription: () => import('svg/icon-subscription'),
		IconAnnouncement: () => import('svg/icon-announcement'),
		IconVisa: () => import('svg/icon-visa'),
		IconMastercard: () => import('svg/icon-mastercard'),
		IconMaestro: () => import('svg/icon-maestro'),
		IconAmericanExpress: () => import('svg/icon-americanExpress'),
		IconDiscover: () => import('svg/icon-discover'),
		IconCapslock: () => import('svg/icon-capslock'),
	},
	props: {
		iconName: { type: String, required: true }, // home, setting, countdown, topic, etc.
	},
	computed: {
		classList() {
			return `icon ${this.$options.iconPrefix}${this.iconName}`
		},
		viewBox() {
			return this.$options.customViewBoxes[this.iconName] || this.$options.defaultViewBox
		},
		componentName() {
			return 'Icon' + this.iconName.charAt(0).toUpperCase() + this.iconName.slice(1)
		},
	},
}
</script>
