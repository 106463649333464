import Vue from 'vue'

const AuthDataService = Vue.extend({
	data: function () {
		return {}
	},
	methods: {
		login(credentials) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('/user/authenticate', credentials)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		checkForThirdParty(email) {
			return new Promise((resolve, reject) => {
				this.$http
					.get('/user-search?email=' + encodeURIComponent(email))
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		loginSSO(info) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('login/sso', info)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		register(info) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('user/register', info)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		redeemOnetimeToken(info) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('login/onetime/redeem', info)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		createPassword(info) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('user/create-password', info)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		setPassword(info) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('user/forgot', info)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},

		setPasswordForNewPlusUser(info) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('user/password', info)
					.then(response => {
						resolve(response.data)
					})
					.catch(reject)
			})
		},

		sendEmailLink(info) {
			return new Promise((resolve, reject) => {
				this.$http
					.post('login/onetime/send', info)
					.then(response => {
						resolve(response.data)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
	},
})

export default new AuthDataService()
