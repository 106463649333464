import Vue from 'vue'
import { bannerEnum, teamExpiredCases, personalExpiredCases } from '../constants'
import store from '../store'
import util from '../util'
import eventBus from '../eventBus'

export default {
	[bannerEnum.TEAM_TRIAL]: new (Vue.extend({
		name: 'TeamTrialBanner',
		unreactive() {
			return {
				class: 'team-trial',
				ctaRouterPath: '/team/upgrade',
			}
		},
		computed: {
			showBanner() {
				return (
					store.authInfo.loggedIn &&
					store.teamInfo.activeTeam.trialActive &&
					store.userInfo.isAdmin &&
					!store.teamInfo.activeTeam.subscriptionSummary.hasPaymentMethod
				)
			},
			linkLabel() {
				return store.interfaceState.mobileViewActive ? `Upgrade` : `Upgrade now`
			},
			bannerText() {
				let daysRemaining = store.teamInfo.activeTeam.trialDaysRemaining,
					message
				if (daysRemaining < 1) {
					message =
						`Your trial ends ` +
						(store.interfaceState.mobileViewActive ? `today.` : `tonight at midnight Pacific time.`)
				} else {
					message =
						(store.interfaceState.mobileViewActive ? `` : `You have `) +
						`${util.pluralize(daysRemaining, '{#} day{:s}')} remaining in your trial.`
				}
				return message
			},
		},
	}))(),

	[bannerEnum.EXPIRED_TEAM]: new (Vue.extend({
		name: 'ExpiredTeam',
		unreactive() {
			return {
				class: 'expired',
			}
		},
		computed: {
			showBanner() {
				return store.userInfo.isAdmin && store.teamInfo.activeTeam.disableFeatures
			},
			ctaRouterPath() {
				if (store.teamInfo.activeTeam.trialExpired) return '/team/upgrade'
			},
			ctaHandler() {
				const team = store.teamInfo.activeTeam
				if (team.pastDue || team.subscriptionExpired) return () => eventBus.$emit('showExpiredModal')
			},
			linkLabel() {
				const team = store.teamInfo.activeTeam
				if (team.pastDue) return teamExpiredCases.PAST_DUE.buttonText
				if (team.trialExpired && !team.pastDue && team.subscriptionSummary.hasPaymentMethod) {
					return teamExpiredCases.SUBSCRIPTION_EXPIRED.buttonText
				}
				if (team.trialExpired && !team.pastDue) return teamExpiredCases.TRIAL_EXPIRED.shortButtonText
				if (team.subscriptionExpired && !team.pastDue) {
					return teamExpiredCases.SUBSCRIPTION_EXPIRED.shortButtonText
				}
			},
			bannerText() {
				const team = store.teamInfo.activeTeam
				if (team.pastDue) return teamExpiredCases.PAST_DUE.title
				if (team.trialExpired && !team.pastDue) return teamExpiredCases.TRIAL_EXPIRED.title
				if (team.subscriptionExpired && !team.pastDue) return teamExpiredCases.SUBSCRIPTION_EXPIRED.title
			},
		},
	}))(),

	[bannerEnum.EXPIRING_TEAM]: new (Vue.extend({
		name: 'ExpiringTeam',
		unreactive() {
			return {
				ctaRouterPath: '/team/settings/billing',
				daysRemainingBeforeBannerShown: 7,
			}
		},
		computed: {
			showBanner() {
				const team = store.teamInfo.activeTeam
				return (
					store.userInfo.isAdmin &&
					team.subscriptionActive &&
					team.subscriptionSummary.subscriptionEnd &&
					team.subscriptionDaysRemaining <= this.daysRemainingBeforeBannerShown
				)
			},
			linkLabel() {
				return store.interfaceState.mobileViewActive ? `Renew` : `Renew now`
			},
			bannerText() {
				let daysRemaining = store.teamInfo.activeTeam.subscriptionDaysRemaining,
					message
				if (daysRemaining < 1) {
					message =
						`Your subscription expires ` +
						(store.interfaceState.mobileViewActive ? `today.` : `tonight at midnight Pacific time.`)
				} else {
					message =
						(store.interfaceState.mobileViewActive
							? `Your subscription expires in `
							: `Your Momentum Team subscription will expire in `) +
						`${util.pluralize(daysRemaining, '{#} day{:s}')}.`
				}
				return message
			},
		},
	}))(),

	[bannerEnum.ACTION_REQUIRED]: new (Vue.extend({
		name: 'ActionRequired',
		unreactive() {
			return {
				class: 'expired',
			}
		},
		computed: {
			sub() {
				return store.userInfo.subscriptionSummary
			},
			showBanner() {
				return this.sub.intent?.status === 'requires_action'
			},
			ctaRouterPath() {
				return '/subscription'
			},
			linkLabel() {
				return personalExpiredCases.ACTION_REQUIRED.buttonText
			},
			bannerText() {
				return personalExpiredCases.ACTION_REQUIRED.title
			},
		},
	}))(),

	[bannerEnum.EXPIRING_PLUS]: new (Vue.extend({
		name: 'ExpiringPlus',
		unreactive() {
			return {
				ctaRouterPath: '/subscription',
				daysRemainingBeforeBannerShown: 7,
			}
		},
		computed: {
			showBanner() {
				const sub = store.userInfo.subscriptionSummary
				return (
					!store.userInfo.isAdmin &&
					!store.userInfo.isTeamMember &&
					sub.active &&
					sub.subscriptionEnd &&
					store.userInfo.subscriptionDaysRemaining <= this.daysRemainingBeforeBannerShown
				)
			},
			linkLabel() {
				return store.interfaceState.mobileViewActive ? `Renew` : `Renew now`
			},
			bannerText() {
				let daysRemaining = store.userInfo.subscriptionDaysRemaining,
					message
				if (daysRemaining < 1) {
					message =
						`Your subscription expires ` +
						(store.interfaceState.mobileViewActive ? `today.` : `tonight at midnight Pacific time.`)
				} else {
					message =
						(store.interfaceState.mobileViewActive
							? `Your subscription expires in `
							: `Your Momentum Plus subscription will expire in `) +
						`${util.pluralize(daysRemaining, '{#} day{:s}')}.`
				}
				return message
			},
		},
	}))(),

	[bannerEnum.EXPIRED_PLUS]: new (Vue.extend({
		name: 'ExpiredPlus',
		unreactive() {
			return {
				class: 'expired',
			}
		},
		computed: {
			sub() {
				return store.userInfo.subscriptionSummary
			},
			subscriptionExpired() {
				return !this.sub.active && this.sub.subscriptionEnd
			},
			trialExpired() {
				return (
					!this.sub.active &&
					!this.sub.subscriptionEnd &&
					!this.sub.pastDue &&
					!this.sub.trialing &&
					this.sub.trialEnd &&
					new Date(this.sub.trialEnd) < new Date()
				)
			},
			showBanner() {
				if (
					!store.userInfo.isAdmin &&
					!store.userInfo.isTeamMember &&
					(this.sub.pastDue || this.subscriptionExpired || this.trialExpired)
				) {
					return true
				}
			},
			ctaHandler() {
				if (this.sub.pastDue) return () => eventBus.$emit('showExpiredModal')
			},
			ctaRouterPath() {
				if (this.subscriptionExpired || this.trialExpired) return '/upgrade'
			},
			linkLabel() {
				if (this.sub.pastDue) return personalExpiredCases.PAST_DUE.buttonText
				if (this.subscriptionExpired && !this.sub.pastDue) {
					return personalExpiredCases.SUBSCRIPTION_EXPIRED.shortButtonText
				}
				if (this.trialExpired) {
					return personalExpiredCases.TRIAL_EXPIRED.shortButtonText
				}
			},
			bannerText() {
				if (this.sub.pastDue) return personalExpiredCases.PAST_DUE.title
				if (this.subscriptionExpired && !this.sub.pastDue) {
					return personalExpiredCases.SUBSCRIPTION_EXPIRED.title
				}
				if (this.trialExpired) {
					return personalExpiredCases.TRIAL_EXPIRED.title
				}
			},
		},
	}))(),

	[bannerEnum.PLUS_SALE]: new (Vue.extend({
		name: 'PlusSaleBanner',
		unreactive() {
			return {
				externalCtaPath: 'https://www.momentumdash.com/gift',
				blackListPaths: ['/thanks'],
				isMonospace: true,
			}
		},
		computed: {
			sale() {
				if (store.planInfo.personal && store.planInfo.personal.sales) {
					return Object.values(store.planInfo.personal.sales).find(sale => sale && sale.title)
				}
			},
			ctaRouterPath() {
				return this.sale.ctaUrl ? this.sale.ctaUrl : '/upgrade'
			},
			showBanner() {
				return (
					this.sale &&
					!store.userInfo.isTeamMember &&
					!store.userInfo.isAdmin &&
					!store.userInfo.activeSubscription
				)
			},
			title() {
				return this.sale && `${this.sale.title} – ${this.sale.percentOff}% Off`
			},
			linkLabel() {
				if (store.interfaceState.mobileViewActive) {
					return store.userInfo.activeSubscription ? 'Gift' : 'Go!'
				} else {
					return store.userInfo.activeSubscription ? 'Gift Momentum' : 'Get the deal'
				}
			},
			saleTimeLeft() {
				if (!this.sale.endDate) return 0
				let saleEnd = new Date(this.sale.endDate)
				return saleEnd - store.time
			},
			bannerText() {
				if (!(this.sale && this.sale.endDate)) return ''
				if (this.saleTimeLeft <= 0) return 'Sale has ended'
				const seconds = Math.floor(this.saleTimeLeft / 1000)
				const secRem = seconds % 60
				const secStr = util.twoDigit(secRem)
				const minFromSec = Math.floor(seconds / 60)
				const minRem = minFromSec % 60
				const minStr = util.twoDigit(minRem)
				const hrFromMin = Math.floor(minFromSec / 60)
				const hrRem = hrFromMin % 24
				const hrStr = util.twoDigit(hrRem)
				const dayFromHr = Math.floor(hrFromMin / 24)
				return 'Ends in ' + dayFromHr + 'd ' + hrStr + 'h ' + minStr + 'm ' + secStr + 's'
			},
			useExternalCtaPath() {
				return store.userInfo.activeSubscription
			},
			class() {
				return this.sale.class ? `plus-sale ${this.sale.class}` : 'plus-sale'
			},
		},
	}))(),

	[bannerEnum.PLUS_TRIAL]: new (Vue.extend({
		name: 'PlusTrialBanner',
		unreactive() {
			return {
				class: 'plus-trial',
				hideLink: true,
			}
		},
		computed: {
			userSubscriptionSummary() {
				return store.userInfo.subscriptionSummary
			},
			mobileViewActive() {
				return store.interfaceState.mobileViewActive
			},
			showBanner() {
				return (
					store.authInfo.loggedIn &&
					!store.userInfo.isAdmin &&
					!store.userInfo.isTeamMember &&
					this.userSubscriptionSummary.trialActive
				)
			},
			daysRemaining() {
				const trialEndDate = this.userSubscriptionSummary.trialEnd
				if (trialEndDate) {
					return util.daysRemaining(trialEndDate)
				}
				return -1
			},
			bannerText() {
				if (this.daysRemaining < 1) {
					return `Your trial ends ` + (this.mobileViewActive ? `today.` : `tonight at midnight Pacific time.`)
				} else {
					return (
						(this.mobileViewActive ? `` : `You have `) +
						`${util.pluralize(this.daysRemaining, '{#} day{:s}')} remaining in your ${
							this.mobileViewActive ? '' : 'Momentum'
						} Plus trial.`
					)
				}
			},
		},
	}))(),
}
