<template>
	<form class="content payment-methods tooltip-container">
		<payment-form :on-token="addCard" :recaptcha="true" @set-error="setError" @clear-error="clearError" @end-submit-process="endSubmitProcess"></payment-form>

		<div class="button-row">
			<button :disabled="submitting" type="button" class="submit button button-add-card" data-test="add-card" @click="submitNewCard($event)"><span class="button-label">{{ addButtonMessage }}</span></button>
			<button v-if="showCancel" type="button" class="button button-cancel" @click="goBack($event)">{{ $t('copy.CANCEL') }}</button>
		</div>

		<transition key="error" name="fade">
			<form-message v-if="error" :message="errorMessage || externalErrorMessage" :error="error"></form-message>
		</transition>

		<upgrade-flair />
	</form>
</template>



<script>
import PaymentForm from '@/components/subscription/PaymentForm'
import UpgradeFlair from '@/components/subscription/UpgradeFlair'
import paymentManager from '../../managers/paymentManager'
import FormMessage from '@/components/common/FormMessage'
import store from '../../store'
import eventBus from '@/eventBus'
import { errorMessages } from '@/constants'
import util from '@/util'
import subscriptionManager from '../../managers/subscriptionManager'
import currentUserManager from '../../managers/currentUserManager'
import Analytics from '../../Analytics'

export default {
	name: 'PaymentMethods',
	components: {
		PaymentForm,
		FormMessage,
		UpgradeFlair,
	},
	props: {
		addButtonText: { type: String, default: '' },
		refreshSubscriptionAfterAdd: { type: Boolean, default: false },
		showCancel: { type: Boolean, default: true },
		chargeCardImmediately: { type: Boolean, default: false },
		externalErrorMessage: { type: String, default: '' },
	},
	data() {
		return {
			showAddCard: false,
			stripePublishableKey: null,
			submitting: false,
			submittingRetry: false,
			errorMessage: '',
		}
	},
	store: ['paymentInfo', 'teamInfo', 'subscriptionInfo', 'userInfo'],
	computed: {
		loaded() {
			return this.paymentInfo.loaded
		},
		error() {
			return this.paymentInfo.error || !!this.errorMessage || !!this.externalErrorMessage
		},
		creditCards() {
			return this.paymentInfo.creditCards
		},
		addButtonMessage() {
			return this.submitting
				? this.$t('copy.LOADING')
				: this.addButtonText === ''
					? this.$t('subscription.ADD_NEW_CARD')
					: 'Add new Card'
		},
	},
	created() {
		if (!this.paymentInfo.loaded) this.getUserPaymentMethods()
		eventBus.$on('modalClosed', this.clearError)
	},
	methods: {
		submitNewCard() {
			this.submitting = true
			eventBus.$emit('submit-card-info')
		},
		getUserPaymentMethods() {
			this.endSubmitProcess()
			paymentManager.getCards()
		},
		addCard(result, _, recaptchaToken) {
			if (!result || result.error) {
				this.endSubmitProcess()
				this.setError(result.error.message)
			} else {
				this.$emit('submitting')
				paymentManager
					.addCard(result, recaptchaToken, this.chargeCardImmediately)
					.then(() => {
						this.getUserPaymentMethods()
						this.$parent && this.$parent.close && this.$parent.close()
						this.showAddCard = false
						if (this.refreshSubscriptionAfterAdd) {
							subscriptionManager.getSubscriptions()
							if (this.userInfo.isAdmin) store.teamInfo.refreshTeams()
							else currentUserManager.getProfile()
						}
						this.$emit('success')
						Analytics.capture('card add', { feature: 'subscription', is_paid_event: true })
						eventBus.$emit('flashMessage', {
							message: `${util.getCardLabel(result.token.card)} has been added to your account.`,
							icon: 'success',
						})
					})
					.catch(() => {
						this.setError(errorMessages.PAYMENT_ADD_CARD_ERROR)
					})
					.finally(this.endSubmitProcess)
			}
		},
		goBack(e) {
			this.$parent && this.$parent.close && this.$parent.close(e)
		},
		setError(errorMessage) {
			this.errorMessage = errorMessage
		},
		clearError() {
			this.errorMessage = ''
		},
		endSubmitProcess() {
			this.$emit('end-submit')
			this.submitting = false
			this.submittingRetry = false
		},
	},
}
</script>



<style scoped>
	form { display: block;}
</style>
