import Vue from 'vue'
import itemModel from '@/models/mixins/itemModelMixin'

export default Vue.extend({
	mixins: [itemModel],
	data: function () {
		return {
			id: null,
			description: '',
			type: null,
			typeName: '',
			url: '',
			props: ['description', 'id', 'type', 'url', 'typeName'],
		}
	},
	methods: {
		updateData(webhook) {
			this.typeName = webhook.type.label
			this.description = webhook.description
			this.id = webhook.id
			this.type = webhook.webhookTypeId
			this.url = webhook.url
			this.revertChanges()
		},
	},
})
